import TillUnmanagedTextBox from "./TillUnmanagedTextBox";
import { CSSProperties, FocusEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";

type TillCardholderNameTextBoxPropTypes = {
  value: string;
  onChange: (name: string) => void;
  style?: CSSProperties | undefined;
};

const TillCardholderNameTextBox = ({ value, onChange, style }: TillCardholderNameTextBoxPropTypes) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const handleInput = (e: FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.currentTarget.value.length === 0) {
      setErrorMessage(t("Cardholder-Name-Required"));
    } else {
      setErrorMessage("");
    }
    onChange(e.currentTarget.value.toUpperCase());
  };

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.currentTarget.value.length === 0) {
      setErrorMessage(t("Cardholder-Name-Required"));
    } else {
      setErrorMessage("");
    }
  };

  return (
    <TillUnmanagedTextBox
      label={t("Cardholder-Name")}
      autoComplete={"cc-name"}
      placeholder={t("Citizen")}
      value={value}
      onInput={handleInput}
      errorText={errorMessage}
      style={style}
      onBlur={handleBlur}
    />
  );
};

export default TillCardholderNameTextBox;
