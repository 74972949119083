/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VendToCycleMap } from './VendToCycleMap';
import {
    VendToCycleMapFromJSON,
    VendToCycleMapFromJSONTyped,
    VendToCycleMapToJSON,
} from './VendToCycleMap';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';
import type { CycleInfo } from './CycleInfo';
import {
    CycleInfoFromJSON,
    CycleInfoFromJSONTyped,
    CycleInfoToJSON,
} from './CycleInfo';
import type { MachineOptionGroup } from './MachineOptionGroup';
import {
    MachineOptionGroupFromJSON,
    MachineOptionGroupFromJSONTyped,
    MachineOptionGroupToJSON,
} from './MachineOptionGroup';
import type { MachineReadiness } from './MachineReadiness';
import {
    MachineReadinessFromJSON,
    MachineReadinessFromJSONTyped,
    MachineReadinessToJSON,
} from './MachineReadiness';

/**
 * 
 * @export
 * @interface Machine
 */
export interface Machine {
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Machine
     */
    defaultVendAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Machine
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Machine
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Machine
     */
    incrementByAmount: number;
    /**
     * 
     * @type {MachineType}
     * @memberof Machine
     */
    type: MachineType;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    zone: string;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    zoneId: string;
    /**
     * 
     * @type {boolean}
     * @memberof Machine
     */
    bypassPayment: boolean;
    /**
     * 
     * @type {Array<VendToCycleMap>}
     * @memberof Machine
     */
    vendToCycleMaps: Array<VendToCycleMap>;
    /**
     * 
     * @type {Array<MachineOptionGroup>}
     * @memberof Machine
     */
    machineOptionGroups: Array<MachineOptionGroup>;
    /**
     * 
     * @type {CycleInfo}
     * @memberof Machine
     */
    cycleInfo?: CycleInfo;
    /**
     * 
     * @type {MachineReadiness}
     * @memberof Machine
     */
    machineReadiness: MachineReadiness;
}

/**
 * Check if a given object implements the Machine interface.
 */
export function instanceOfMachine(value: object): value is Machine {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('defaultVendAmount' in value) || value['defaultVendAmount'] === undefined) return false;
    if (!('maxAmount' in value) || value['maxAmount'] === undefined) return false;
    if (!('minAmount' in value) || value['minAmount'] === undefined) return false;
    if (!('incrementByAmount' in value) || value['incrementByAmount'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('zone' in value) || value['zone'] === undefined) return false;
    if (!('zoneId' in value) || value['zoneId'] === undefined) return false;
    if (!('bypassPayment' in value) || value['bypassPayment'] === undefined) return false;
    if (!('vendToCycleMaps' in value) || value['vendToCycleMaps'] === undefined) return false;
    if (!('machineOptionGroups' in value) || value['machineOptionGroups'] === undefined) return false;
    if (!('machineReadiness' in value) || value['machineReadiness'] === undefined) return false;
    return true;
}

export function MachineFromJSON(json: any): Machine {
    return MachineFromJSONTyped(json, false);
}

export function MachineFromJSONTyped(json: any, ignoreDiscriminator: boolean): Machine {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'defaultVendAmount': json['defaultVendAmount'],
        'maxAmount': json['maxAmount'],
        'minAmount': json['minAmount'],
        'incrementByAmount': json['incrementByAmount'],
        'type': MachineTypeFromJSON(json['type']),
        'zone': json['zone'],
        'zoneId': json['zoneId'],
        'bypassPayment': json['bypassPayment'],
        'vendToCycleMaps': ((json['vendToCycleMaps'] as Array<any>).map(VendToCycleMapFromJSON)),
        'machineOptionGroups': ((json['machineOptionGroups'] as Array<any>).map(MachineOptionGroupFromJSON)),
        'cycleInfo': json['cycleInfo'] == null ? undefined : CycleInfoFromJSON(json['cycleInfo']),
        'machineReadiness': MachineReadinessFromJSON(json['machineReadiness']),
    };
}

export function MachineToJSON(value?: Machine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'defaultVendAmount': value['defaultVendAmount'],
        'maxAmount': value['maxAmount'],
        'minAmount': value['minAmount'],
        'incrementByAmount': value['incrementByAmount'],
        'type': MachineTypeToJSON(value['type']),
        'zone': value['zone'],
        'zoneId': value['zoneId'],
        'bypassPayment': value['bypassPayment'],
        'vendToCycleMaps': ((value['vendToCycleMaps'] as Array<any>).map(VendToCycleMapToJSON)),
        'machineOptionGroups': ((value['machineOptionGroups'] as Array<any>).map(MachineOptionGroupToJSON)),
        'cycleInfo': CycleInfoToJSON(value['cycleInfo']),
        'machineReadiness': MachineReadinessToJSON(value['machineReadiness']),
    };
}

