/** Wraps locale information */
export class Locale {
  languageTag;
  currencyCode;
  countryCode;

  /**
   *
   * @param {string} languageTag the language code for this locale (e.g., en-AU)
   * @param {string} currencyCode the currency code in this locale (e.g., AUD)
   * @param {string} countryCode the three-letter country code for this locale (e.g., AUS)
   */
  constructor(languageTag: string, currencyCode: string, countryCode: string) {
    this.languageTag = languageTag;
    this.currencyCode = currencyCode;
    this.countryCode = countryCode;
  }

  /**
   * Use this locale to format a number to a currency string.
   * @param {number} amount - the amount to format to currency
   * @returns {string} a formatted string that can be used to display a currency amount
   */
  formatCurrency(amount: number) {
    const formatter = new Intl.NumberFormat(this.languageTag, {
      style: "currency",
      currency: this.currencyCode

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(amount);
  }

  /**
   * Returns the currency amount as major units. For example, 1.23 will be returned as 123 (cents).
   *
   * @param {number} amount the amount, in major units (e.g., dollars and cents)
   * @returns {number} the amount in minor units (e.g., cents)
   */
  currencyMajorToMinorUnits = (amount: number) => {
    // Icelandic kronor has no minor units. https://en.wikipedia.org/wiki/IETF_language_tag is
    if (this.languageTag === "is-IS") {
      return amount;
    }
    return Math.round(amount * 100); // assumes 100 minor units per major unit
  };

  /**
   * Format an ISO8601 date to the device's locale-dependent representation.
   *
   * @param {?string} iso8601 the date time, as a string in ISO8601 format
   * @returns {?string} the locale-dependant time
   */
  formatDate(iso8601?: string) {
    if (!iso8601) {
      return null;
    }

    const formatter = new Intl.DateTimeFormat([...navigator.languages], {
      dateStyle: "medium",
      timeStyle: "short"
    }); // the locale of the phone is used. Not the store.

    const date = new Date(iso8601);

    return formatter.format(date);
  }

  /**
   * Format an ISO8601 date to the device's locale-dependent representation.
   *
   * @param {?Date} dateObj the date time
   * @returns {?string} the locale-dependant time
   */
  formatDateObject(dateObj?: Date) {
    if (!dateObj) {
      return null;
    }

    const formatter = new Intl.DateTimeFormat([...navigator.languages], {
      dateStyle: "medium",
      timeStyle: "short"
    }); // the locale of the phone is used. Not the store.

    return formatter.format(dateObj);
  }
}