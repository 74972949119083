import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../i18n";

const ThreeDSPostbackPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <Typography variant={"body1"} color={"white"}>
        {t("Finalise-Order")}
      </Typography>
    </Box>
  );
};

export default ThreeDSPostbackPlaceholder;
