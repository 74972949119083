/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentTokenType } from './PaymentTokenType';
import {
    PaymentTokenTypeFromJSON,
    PaymentTokenTypeFromJSONTyped,
    PaymentTokenTypeToJSON,
} from './PaymentTokenType';
import type { MachineCreditRequest } from './MachineCreditRequest';
import {
    MachineCreditRequestFromJSON,
    MachineCreditRequestFromJSONTyped,
    MachineCreditRequestToJSON,
} from './MachineCreditRequest';

/**
 * 
 * @export
 * @interface OrderRequestModel
 */
export interface OrderRequestModel {
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    siteId: string;
    /**
     * 
     * @type {PaymentTokenType}
     * @memberof OrderRequestModel
     */
    paymentTokenType: PaymentTokenType;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    paymentToken?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    promoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    cardLastFour?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    digitalWalletType?: string;
    /**
     * 
     * @type {Array<MachineCreditRequest>}
     * @memberof OrderRequestModel
     */
    machineCredits: Array<MachineCreditRequest>;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    userProfileId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    referrer?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequestModel
     */
    cardholderName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRequestModel
     */
    rememberCardDetails: boolean;
}

/**
 * Check if a given object implements the OrderRequestModel interface.
 */
export function instanceOfOrderRequestModel(value: object): value is OrderRequestModel {
    if (!('siteId' in value) || value['siteId'] === undefined) return false;
    if (!('paymentTokenType' in value) || value['paymentTokenType'] === undefined) return false;
    if (!('machineCredits' in value) || value['machineCredits'] === undefined) return false;
    if (!('rememberCardDetails' in value) || value['rememberCardDetails'] === undefined) return false;
    return true;
}

export function OrderRequestModelFromJSON(json: any): OrderRequestModel {
    return OrderRequestModelFromJSONTyped(json, false);
}

export function OrderRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRequestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'siteId': json['siteId'],
        'paymentTokenType': PaymentTokenTypeFromJSON(json['paymentTokenType']),
        'paymentToken': json['paymentToken'] == null ? undefined : json['paymentToken'],
        'promoCode': json['promoCode'] == null ? undefined : json['promoCode'],
        'cardLastFour': json['cardLastFour'] == null ? undefined : json['cardLastFour'],
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'digitalWalletType': json['digitalWalletType'] == null ? undefined : json['digitalWalletType'],
        'machineCredits': ((json['machineCredits'] as Array<any>).map(MachineCreditRequestFromJSON)),
        'userProfileId': json['userProfileId'] == null ? undefined : json['userProfileId'],
        'referrer': json['referrer'] == null ? undefined : json['referrer'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'zipCode': json['zipCode'] == null ? undefined : json['zipCode'],
        'cardholderName': json['cardholderName'] == null ? undefined : json['cardholderName'],
        'rememberCardDetails': json['rememberCardDetails'],
    };
}

export function OrderRequestModelToJSON(value?: OrderRequestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orderId': value['orderId'],
        'siteId': value['siteId'],
        'paymentTokenType': PaymentTokenTypeToJSON(value['paymentTokenType']),
        'paymentToken': value['paymentToken'],
        'promoCode': value['promoCode'],
        'cardLastFour': value['cardLastFour'],
        'emailAddress': value['emailAddress'],
        'digitalWalletType': value['digitalWalletType'],
        'machineCredits': ((value['machineCredits'] as Array<any>).map(MachineCreditRequestToJSON)),
        'userProfileId': value['userProfileId'],
        'referrer': value['referrer'],
        'phoneNumber': value['phoneNumber'],
        'zipCode': value['zipCode'],
        'cardholderName': value['cardholderName'],
        'rememberCardDetails': value['rememberCardDetails'],
    };
}

