/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FeedbackType = {
    Positive: 'Positive',
    Negative: 'Negative',
    None: 'None'
} as const;
export type FeedbackType = typeof FeedbackType[keyof typeof FeedbackType];


export function instanceOfFeedbackType(value: any): boolean {
    for (const key in FeedbackType) {
        if (Object.prototype.hasOwnProperty.call(FeedbackType, key)) {
            if ((FeedbackType as Record<string, FeedbackType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FeedbackTypeFromJSON(json: any): FeedbackType {
    return FeedbackTypeFromJSONTyped(json, false);
}

export function FeedbackTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackType {
    return json as FeedbackType;
}

export function FeedbackTypeToJSON(value?: FeedbackType | null): any {
    return value as any;
}

