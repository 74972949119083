import { Cart, MachineCredit } from "../models";
import { Machine } from "../api/gen";

export class CartUrlParamsSerialiser {
  static toUrlParams(cart: Cart): URLSearchParams {
    const params = new URLSearchParams();

    if (cart.promoCode) {
      params.append("promoCode", cart.promoCode);
    }

    cart.machineCredits.forEach((mc) => {
      let encoded = mc.machine.id + "," + mc.amount;
      if (mc.selectedOptions.length > 0) {
        encoded += "," + mc.selectedOptions.map((o) => o.id).join(";");
      }
      params.append("machineCredit", encoded);
    });

    return params;
  }

  static fromUrlParams(params: string, candidateMachines: Array<Machine>): Cart {
    const cart: Cart = { machineCredits: [] };

    if (params) {
      // convert all params to lower case to avoid case sensitivity problems
      const structuredParams = new URLSearchParams(params.toLowerCase());

      cart.promoCode = structuredParams.get("promocode") || undefined;
      cart.machineCredits = structuredParams
        .getAll("machinecredit")
        .map((mcs) => {
          const [machineId, vendAmount, optionsString] = mcs.split(",").map(decodeURI);
          const machine = candidateMachines.find((m) => m.id === machineId)!;
          const options = optionsString?.split(";") || [];
          return {
            machine: machine,
            amount: Math.min(machine?.maxAmount, Math.max(parseFloat(vendAmount), machine?.minAmount)) || machine?.defaultVendAmount,
            selectedOptions: machine?.machineOptionGroups.flatMap(mog => mog.machineOptions).filter((availableOption) => options.some((o) => o === availableOption.id?.toLowerCase()))
          } as MachineCredit;
        })
        .filter((mc) => !!mc.machine);
    }

    return cart;
  }
}
