import { QuoteResponseModel } from "../../api/gen";
import { createReducer } from "@reduxjs/toolkit";
import { deleteQuote, requestQuote } from "../actions/quoteActions";

const initialState = {
  isLoading: false,
  isError: false,
} as QuoteResponseModelWrapper;

// all Redux operations are immutable. The "Immer" library is included so although it looks like we're mutating state, we are not.
const quoteReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(requestQuote.pending, (state, action) => ({
      isLoading: true,
      isError: false,
    }))
    .addCase(requestQuote.fulfilled, (state, action) => ({
      isLoading: false,
      isError: false,
      quote: action.payload,
    }))
    .addCase(requestQuote.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    })
    .addCase(deleteQuote, (state, action) => initialState);
});

export type QuoteResponseModelWrapper = {
  isLoading: boolean;
  isError: boolean;
  quote?: QuoteResponseModel;
};

export default quoteReducer;
