import { OrderResultModel } from "../../api/gen";
import { createReducer } from "@reduxjs/toolkit";
import { deleteOrder, loadOrder, setOrder } from "../actions/orderActions";

const initialState = {
  isLoading: false,
  isError: false,
  order: null,
} as OrderResponseModelWrapper;

const orderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadOrder.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.order = null;
    })
    .addCase(loadOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.order = action.payload;
    })
    .addCase(loadOrder.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.order = null;
    })
    .addCase(deleteOrder, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.order = null;
    })
    .addCase(setOrder, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.order = action.payload;
    });
});

export default orderReducer;

export type OrderResponseModelWrapper = {
  isLoading: boolean;
  isError: boolean;
  order?: OrderResultModel | null;
};
