import { CardOnFileRequest, UserProfilesApi } from "./gen";
import { defaultConfiguration } from "./apiUtils";

const USER_PROFILES_API = new UserProfilesApi(defaultConfiguration);

export const getUserProfile = async (id: string) => {
  console.info("Loading user profile " + id);

  return USER_PROFILES_API.userProfilesGetUserProfile({
    id: id,
  });
};

export const patchProfile = async (id: string, cardOnFile?: CardOnFileRequest, email?: string) => {
  console.info(`Updating email to ${email} and adding card to user profile ${id}`, cardOnFile);

  return USER_PROFILES_API.userProfilesPatchProfile({
    id: id,
    request: {
      emailAddress: email,
      cardOnFile: cardOnFile,
    },
  });
};

export const deleteCardFromProfile = async (id: string, cardId: string) => {
  console.info("Deleting card from user profile");

  return USER_PROFILES_API.userProfilesDeleteCardFromProfile({
    id: id,
    cardId: cardId,
  });
};

export const newUserProfile = async (cardOnFile: CardOnFileRequest | null, emailAddress: string | null, id?: string | null) => {
  console.info("Adding new user profile " + id, cardOnFile);

  return USER_PROFILES_API.userProfilesCreateUserProfile({
    createUserProfileRequest: {
      emailAddress: emailAddress || undefined,
      id: id || undefined,
      cardOnFile: cardOnFile || undefined,
    },
  });
};
