import { Box, Paper, Typography } from "@mui/material";
// @ts-ignore there is no Typescript type definition file for this. So what, just ignore it.
import Css from "json-to-css";
import React, { useEffect, useRef, useState } from "react";
import PayButton from "../PayButton";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { useLocale, usePaymentMethods } from "../../../../hooks";
import { PaymentTokenType } from "../../../../api/gen";
import { ControlMountedCallback } from "../common/controlMountedCallback";
import { useTranslation } from "react-i18next";
import "../../../../i18n";

const IFRAME_PROD_URL = "https://fts.cardconnect.com/itoke/ajax-tokenizer.html";
const IFRAME_TEST_URL = "https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html";

const iframeParams = (width: number) => {
  const margin = 0;
  const padding = 8;
  const borderWidth = 1;
  const creditCardFieldWidth = width - margin - padding * 2 - borderWidth * 2;

  const css = {
    "select,input": {
      "font-size": "16px",
      color: "rgba(0,0,0,0,87)",
      "font-family": "Roboto, Helvetica, Arial, sans-serif",
      "line-height": "23px",
      "-webkit-font-smoothing": "antialiased",
      border: "1px solid #ccc",
      "border-radius": "4px",
      margin: 0,
      height: "23px",
      width: "6em",
      padding: `${padding}px`,
    },
    select: {
      height: "41px",
    },
    label: {
      display: "block",
      "font-size": "0.875rem",
      color: "rgba(0,0,0,0,87)",
      "font-weight": "400",
      "line-height": "24px",
      "padding-left": "2px",
      "padding-top": "10px",
      "font-family": "Roboto,Helvetica,Arial,sans-serif",
    },
    "input+label": {
      display: "inline",
    },
    "::placeholder": {
      color: "gray",
      "font-weight": 400,
    },
    body: {
      margin: 0,
      width: `${width}px`,
    },
    br: {
      display: "none",
    },
    "#ccnumfield": {
      width: `${creditCardFieldWidth}px`,
    },
    ".error": {
      border: "1px solid red",
    },
  };

  const opts = {
    autofocus: "true",
    cardnumbernumericonly: "true",
    cardinputmaxlength: "19",
    enhancedresponse: "true",
    inactivityto: "500",
    selectinputdelay: "100",
    tokenizewheninactive: "true",
    usecvv: "true",
    invalidcvvevent: "true",
    useexpiry: "true",
    invalidexpiryevent: "true",
    placeholdercvv: "CVV",
    invalidinputevent: "true",
    placeholder: "Card",
    expirylabel: "Expiry",
    formatinput: "true",
    orientation: "vertical",
    css: Css.of(css),
  };

  return new URLSearchParams(opts).toString();
};

const CardConnectCardEntry = ({ onTokenReceived, onElementMounted, amount }: CardConnectCardEntryPropTypes) => {
  const [token, setToken] = useState<CCToken | undefined>(undefined);
  const [width, setWidth] = useState(0);
  const [zipCode, setZipCode] = useState("");
  const [zipCodeValid, setZipCodeValid] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [tokenisationError, setTokenisationError] = useState("");

  const ref = useRef<HTMLDivElement>(null);
  const { isProduction } = usePaymentMethods();
  const locale = useLocale();
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth - 32 : 0); // 32px to account for 16px of padding either side of the container
    setIframeUrl((isProduction ? IFRAME_PROD_URL : IFRAME_TEST_URL) + "?" + iframeParams(width));
  });

  const handleIframeOnload = () => {
    onElementMounted && onElementMounted(true);

    window.addEventListener(
      "message",
      (event) => {
        if (!event.origin.endsWith("cardconnect.com")) {
          return;
        }
        const data = event.data && typeof event.data === "string" && JSON.parse(event.data);
        if (data?.errorCode === "0") {
          setTokenisationError("");
          const expMonth = data.expiry && data.expiry.substring(4);
          const expYear = data.expiry && data.expiry.substring(2, 4);

          const nonce = "N:" + data.token + ":" + expMonth + expYear;
          console.info(`Token successfully received, will use '${nonce}'`);
          setToken({ nonce, token: data.token });
        } else if (data.validationError) {
          setTokenisationError(data.validationError);
        } else {
          console.info(`Token could not be created for provided card. Error ${data.errorCode} '${data.errorMessage}' in data '${event.data}'`);
        }
      },
      false
    );
  };

  const handleZipCodeInput = (e: any) => {
    const value = e.target.value;
    setZipCode(value);
    const isZipValid = value.length === 5 && !isNaN(value);
    setZipCodeValid(isZipValid);
  };

  function handleOnPayClicked() {
    onTokenReceived({ token: token?.nonce + ":" + zipCode, tokenMeta: { tokenType: PaymentTokenType.NonceUsaV2, cardLast4: token?.token[-4] } });
  }

  const payButtonEnabled = !!token && zipCodeValid;

  return (
    <Paper sx={{ p: 2 }} ref={ref}>
      <h4>Card details</h4>
      {iframeUrl && (
        <>
          <iframe
            title="CardPointeTokenizer"
            id="tokenFrame"
            name="tokenFrame"
            src={iframeUrl}
            frameBorder={0}
            scrolling="no"
            width={`${width}px`}
            height="228px"
            onLoad={handleIframeOnload}
          >
            <input type="hidden" name="token" id="mytoken" />
          </iframe>

          <label
            style={{
              display: "block",
              fontSize: "0.875rem",
              color: "rgba(0,0,0,0,87)",
              fontWeight: "400",
              lineHeight: "24px",
              paddingLeft: "2px",
              paddingTop: "10px",
              fontFamily: "Roboto,Helvetica,Arial,sans-serif",
            }}
          >
            Zip Code
          </label>
          <input
            type={"text"}
            inputMode={"numeric"}
            pattern={"\\d{5}"}
            placeholder={t("Zip-Code")}
            required={true}
            accept={"number"}
            onInput={handleZipCodeInput}
            value={zipCode}
            style={{
              fontSize: "16px",
              color: "rgba(0,0,0,0,87)",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              lineHeight: "23px",
              border: zipCodeValid ? "1px solid #ccc" : "1px solid red",
              borderRadius: "4px",
              margin: 0,
              height: "23px",
              width: "6em",
              padding: "8px",
            }}
            size={5}
            minLength={5}
            maxLength={5}
          />
        </>
      )}

      {/*<FormControlLabel control={<Checkbox size={"small"} />} label={"This is my device and I'd like to save my card for next time"} />*/}

      {(tokenisationError || !zipCodeValid) && (
        <Typography color={"error"} variant={"body2"} sx={{ pt: 2 }}>
          {tokenisationError || t("Zip-Code-Invalid-2")}
        </Typography>
      )}

      <Box py={2}>
        <PayButton onClick={handleOnPayClicked} amount={locale.formatCurrency(amount)} busy={false} disabled={!payButtonEnabled} />
      </Box>
    </Paper>
  );
};

type CardConnectCardEntryPropTypes = {
  amount: number;
  width: number;
  onTokenReceived: TokenReceivedCallback;
  onElementMounted: ControlMountedCallback;
};

interface CCToken {
  nonce: string;
  token: string;
}

export default CardConnectCardEntry;
