import { Box, Typography } from "@mui/material";
import React from "react";
import ItemsWithPricePanel from "../../common/itemised/ItemsWithPricePanel";
import { ItemWithPrice } from "../../common/itemised/itemWithPrice";
import { useLocale, useSiteConfig } from "../../../hooks";
import { useAppSelector } from "../../../redux/hooks";
import { MachineCredit } from "../../../models";
import Link from "@mui/material/Link";
import { deleteQuote } from "../../../redux/actions/quoteActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const createDescription = (machineCredit: MachineCredit) => {
  if (machineCredit.selectedOptions.length > 0) {
    return `${machineCredit.machine.type} (with ${machineCredit.selectedOptions.map((o) => o.name).join(", ")})`;
  }
  if (machineCredit.machine.vendToCycleMaps.length > 0) {
    const vcm = machineCredit.machine.vendToCycleMaps.find((vcm) => vcm.vendAmount === machineCredit.amount);
    if (vcm) {
      return `${machineCredit.machine.type} (${machineCredit.machine.vendToCycleMaps.find((vcm) => vcm.vendAmount === machineCredit.amount)?.cycleName})`;
    }
  }

  return machineCredit.machine.type;
};

const OrderSummaryPart = () => {
  const locale = useLocale();
  const { siteConfig } = useSiteConfig();
  const cart = useAppSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChangeMachine = (mc: MachineCredit) => {
    dispatch(deleteQuote());
    navigate(`/${siteConfig?.siteId ?? ""}/zones/${mc.machine.zoneId}/machines?t=${mc.machine.type}&m=${mc.machine.id}`);
  };

  const cartContents = cart.machineCredits.map(
    (mc) =>
      ({
        id: mc.machine.id,
        name: mc.machine.name,
        amount: locale.formatCurrency(mc.amount),
        caption: createDescription(mc),
        action: (
          <Link href={"#"} variant={"caption"} onClick={() => handleChangeMachine(mc)}>
            {t("Change")}
          </Link>
        ),
      } as ItemWithPrice)
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5" component="div">
        {t("Order-Summary")}
      </Typography>
      <ItemsWithPricePanel items={cartContents} />
    </Box>
  );
};

export default OrderSummaryPart;
