import { FormControl, FormHelperText, Typography } from "@mui/material";
import React from "react";

type TillManagedTextBoxPropTypes = {
  label: string;
  divPlaceholderId: string;
  errorText?: string | null;
  helperImage?: JSX.Element;
};

const TillManagedTextBox = ({ label, divPlaceholderId, errorText, helperImage }: TillManagedTextBoxPropTypes) => {
  return (
    <FormControl margin={"normal"}>
      <label>
        <Typography variant={"body2"}>{label}</Typography>
      </label>
      <div>
        {helperImage && <div style={{ zIndex: 10, position: "absolute", right: "16px", top: "24px", display: "flex", height: "32px" }}>{helperImage}</div>}
        <div id={divPlaceholderId} style={{ height: "40px" }} />
      </div>
      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default TillManagedTextBox;
