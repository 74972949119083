import React from "react";
import { Box, Typography } from "@mui/material";
import logo_brand from "../assets/tangerpay-brand.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";
import "../../src/i18n";

const SystemError = () => {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} flexDirection={"column"} p={6} className={"error-background"} height={"100vh"}>
      <Box mt={8} width={"100%"} textAlign={"center"}>
        <img src={logo_brand} alt="Tangerpay" height="52px" />
      </Box>
      <Typography variant={"h4"} color={"white"} fontFamily={"Quicksand"} sx={{ my: 4, textAlign: "center" }}>
        {t("System-Error")} <ErrorOutlineIcon fontSize={"large"} />
      </Typography>
      <Typography variant={"body1"} color={"white"} sx={{ my: 4 }}>
        {t("System-Error-Message-1")}
      </Typography>
      <Typography variant={"body2"} color={"white"} sx={{ my: 1 }}>
        {t("System-Error-Message-2")}
      </Typography>
    </Box>
  );
};
export default SystemError;
