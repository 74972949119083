import { Box, Button, Typography } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendTelemetry } from "../api/telemetryApi";
import { useTranslation } from "react-i18next";
import "../i18n";

const MachineNotFoundPage = () => {
  const navigate = useNavigate();
  const { siteId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.search) {
      sendTelemetry(`Customer navigated to site ${siteId} but a machine credit doesn't exist in sent query params ${window.location.search}`).catch(() =>
        console.error("Error sending telemetry")
      );
    }
  }, [siteId]);

  const handleStartAgainClicked = () => {
    navigate(`/${siteId}`);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} p={6} height={"100vh"} bgcolor={"white"}>
      <Typography variant={"h4"} fontFamily={"Quicksand"} sx={{ my: 4, textAlign: "center" }}>
        {t("Machine-Inactive")} <SearchOffIcon fontSize={"large"} />
      </Typography>
      <Typography variant={"body1"} sx={{ my: 4 }}>
        {t("Machine-Inactive-Message")}
      </Typography>
      <Typography variant={"body2"} sx={{ my: 2 }}>
        {t("Contact")} <a href={"mailto:service@tangerpay.com"}>service@tangerpay.com</a> {t("Not-Found-Message-2")}
      </Typography>
      <Button onClick={handleStartAgainClicked} fullWidth variant={"contained"}>
        {t("Start-Again")}
      </Button>
    </Box>
  );
};

export default MachineNotFoundPage;
