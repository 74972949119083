// The session context is just bits of state passed in from elsewhere that are not related to, or tangentally related to the order that will be processed.
// These will usually come in as query params and need to be stored away for later. E.g., Kiosk Transition sends in a phone number for SMS reminders before this
// app is ready for it. Some links will pass an "indicator" ('r' param) to tell us where a transaction came from (e.g., Kiosk, QR code, NFC, SMS etc).
//
// The state in this is set up at load time but is not expected to change at all throughout the process.

import { SessionContext } from "../../models";
import { createReducer } from "@reduxjs/toolkit";
import { setSessionContext } from "../actions/sessionContextActions";

const initialState = {} as SessionContext;

const sessionContextReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSessionContext, (state, action) => {
    state.phoneNumber = action.payload.phoneNumber;
    state.referrer = action.payload.referrer;
  });
});

export default sessionContextReducer;
