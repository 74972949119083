import { Cart } from "../../models";
import { MachineOptionsMode } from "../../api/gen";
import { createReducer } from "@reduxjs/toolkit";
import {
  addMachineToCart,
  addOptionToMachine,
  addPromoCode,
  deleteMachineCredits,
  deleteMachineFromCart,
  deleteOptionFromMachine,
  deletePromoCode,
  replaceCart,
  updateMachinePrice
} from "../actions/cartActions";

const initialState = { promoCode: "", machineCredits: [] } as Cart;

// Redux state is always immutable
// Remember that the Redux Tools includes Immer, which allows the appearance of mutating state when in fact state is immutable.
export const cartReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addMachineToCart, (state, action) => {
      state.machineCredits.push(action.payload);
    })
    .addCase(updateMachinePrice, (state, action) => {
      const machineCreditToUpdate = state.machineCredits.find((mc) => mc.machine.id === action.payload.machine.id)!; // could never be null
      machineCreditToUpdate.amount = action.payload.amount;

      state.machineCredits = state.machineCredits.filter((mc) => mc.machine.id !== action.payload.machine.id);
      state.machineCredits.push(machineCreditToUpdate);
    })
    .addCase(deleteMachineFromCart, (state, action) => {
      state.machineCredits = state.machineCredits.filter((mc) => mc.machine.id !== action.payload);
    })
    .addCase(addOptionToMachine, (state, action) => {
      // Find the machine credit that needs updating and take a copy
      const mcIndex = state.machineCredits.findIndex((mc) => mc.machine.id === action.payload.machine.id)!;

      // If multiple selections are not allowed within the group that this option belongs to, then delete any other option that is also within the group
      const optionsToRemove = state.machineCredits[mcIndex].machine.machineOptionGroups
        .filter(mog => mog.machineOptionsMode === MachineOptionsMode.One)
        .filter(mog => mog.machineOptions.some(mo => mo.id === action.payload.option.id))
        .flatMap(mog => mog.machineOptions)
        .map(o => o.id);
      state.machineCredits[mcIndex].selectedOptions = state.machineCredits[mcIndex].selectedOptions.filter((o) => !optionsToRemove.includes(o.id));

      // Now add the new option
      state.machineCredits[mcIndex].selectedOptions.push(action.payload.option);

      // Recalculate the vend amount with adders.
      state.machineCredits[mcIndex].amount =
        state.machineCredits[mcIndex].machine.defaultVendAmount +
        state.machineCredits[mcIndex].selectedOptions.map((o) => o.additionalAmount).reduce((accumulator, amount) => accumulator + amount, 0);
    })
    .addCase(deleteOptionFromMachine, (state, action) => {
      const mcIndex = state.machineCredits.findIndex((mc) => mc.machine.id === action.payload.machine.id);

      state.machineCredits[mcIndex].selectedOptions = state.machineCredits[mcIndex].selectedOptions.filter((o) => o.name !== action.payload.option.name); // deletes the option, if it was there

      // Add all the selected adders to the default to calculate vend amount
      state.machineCredits[mcIndex].amount =
        state.machineCredits[mcIndex].machine.defaultVendAmount +
        state.machineCredits[mcIndex].selectedOptions.map((o) => o.additionalAmount).reduce((accumulator, amount) => accumulator + amount, 0);
    })
    .addCase(replaceCart, (state, action) => {
      return action.payload;
    })
    .addCase(deleteMachineCredits, (state, action) => {
      state.machineCredits = [];
    })
    .addCase(addPromoCode, (state, action) => {
      state.promoCode = action.payload;
    })
    .addCase(deletePromoCode, (state, action) => {
      state.promoCode = undefined;
    });
});

export default cartReducer;
