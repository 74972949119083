import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PayButton from "../PayButton";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { useLocale, usePaymentMethods } from "../../../../hooks";
import { PaymentTokenType } from "../../../../api/gen";
import Payment from "payment";
import CardTextBox from "./ui/CardTextBox";
import CvcTextBox from "./ui/CvcTextBox";
import CardExpiryDateTextBox from "./ui/CardExpiryDateTextBox";
import EmailTextBox from "./ui/EmailTextBox";
import validator from "validator";
import { loadDatacapApi } from "./datacapSdkProvider";
import { ControlMountedCallback } from "../common/controlMountedCallback";

// https://dsidevportal.com/api/payapi/#webtoken
const DatacapCardEntry = ({ amount, onElementMounted, onTokenReceived }: DatacapCardEntryPropTypes) => {
  const [busy, setBusy] = useState(false);
  const [cardType, setCardType] = useState("default");
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [email, setEmail] = useState("");
  const locale = useLocale();
  const { cardChannel } = usePaymentMethods();

  useEffect(() => {
    // Datacap does not require any "warmup" to use it. The first invocation of Datacap is when we are ready to get a token.
    onElementMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once only

  const handlePayButtonClick = async () => {
    if (cardChannel) {
      setBusy(true);
      loadDatacapApi().then(() => {
        DatacapWebToken.requestToken(cardChannel.applicationId, "payment_form", (response) => {
          setBusy(false);
          if (response.Token) {
            onTokenReceived({
              token: response.Token,
              // tokenMeta: { tokenType: PaymentTokenType.NonceUsaV3, cardLast4: response.Last4, source: SourceType.CARD, rememberCard: rememberCard },
              tokenMeta: { tokenType: PaymentTokenType.NonceUsaV3, cardLast4: response.Last4, rememberCard: false, email: email }
            });
          }
        });
      });
    }
  };

  const cardNumberRef = useRef<HTMLInputElement>(null);

  if (cardNumberRef.current) {
    Payment.formatCardNumber(cardNumberRef.current);
  }

  const handleCardNumberChanged = (event: string) => {
    const cardType = Payment.fns.cardType(event);
    setCardType(cardType);
    setCardNumber(event);
  };

  const handleCvcChanged = (event: string) => {
    setCvc(event);
  };

  const handleExpiryDateChanged = (expiry: string) => {
    setExpiry(expiry);
  };

  const handleEmailAddressChanged = (email: string) => {
    setEmail(email);
  };

  // const handleOnRememberMyCardChanged = () => {
  //   setRememberCard(!rememberCard);
  // };

  const isReadyToPay =
    Payment.fns.validateCardExpiry(expiry) && Payment.fns.validateCardNumber(cardNumber) && Payment.fns.validateCardCVC(cvc, cardType) && validator.isEmail(email);

  return (
    <Box display={"flex"} flexDirection={"column"} bgcolor={"white"} p={2} borderRadius={1}>
      <form id="payment_form">
        <EmailTextBox email={email} onChange={handleEmailAddressChanged} />
        <CardTextBox cardBrand={cardType} cardNumber={cardNumber} onChange={handleCardNumberChanged} />
        <Box display={"flex"} flexDirection={"row"}>
          <CardExpiryDateTextBox onChange={handleExpiryDateChanged} expiry={expiry} />
          <Box mx={1}>
            <CvcTextBox cvc={cvc} cardType={cardType} onChange={handleCvcChanged} />
          </Box>
        </Box>

        {/*<RememberMyCard checked={rememberCard} onChange={handleOnRememberMyCardChanged} disabled={(userProfile?.cardsOnFile.length || 0) > 0} />*/}

        <input type="hidden" id="token" />
      </form>

      <Box mt={3}>
        <PayButton onClick={handlePayButtonClick} amount={locale.formatCurrency(amount)} busy={busy} disabled={!isReadyToPay} />
      </Box>
    </Box>
  );
};

type DatacapCardEntryPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  onElementMounted: ControlMountedCallback;
  amount: number;
};

export default DatacapCardEntry;
