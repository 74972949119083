/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CardOnFileRequest,
  CreateUserProfileRequest,
  ErrorCondition,
  PatchUserProfileRequest,
  ProblemDetails,
  UserProfileResponse,
} from '../models/index';
import {
    CardOnFileRequestFromJSON,
    CardOnFileRequestToJSON,
    CreateUserProfileRequestFromJSON,
    CreateUserProfileRequestToJSON,
    ErrorConditionFromJSON,
    ErrorConditionToJSON,
    PatchUserProfileRequestFromJSON,
    PatchUserProfileRequestToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UserProfileResponseFromJSON,
    UserProfileResponseToJSON,
} from '../models/index';

export interface UserProfilesAddCardToProfileRequest {
    id: string;
    request: CardOnFileRequest;
}

export interface UserProfilesCreateUserProfileRequest {
    createUserProfileRequest: CreateUserProfileRequest;
}

export interface UserProfilesDeleteCardFromProfileRequest {
    id: string;
    cardId: string;
}

export interface UserProfilesGetUserProfileRequest {
    id: string;
}

export interface UserProfilesPatchProfileRequest {
    id: string;
    request: PatchUserProfileRequest;
}

/**
 * 
 */
export class UserProfilesApi extends runtime.BaseAPI {

    /**
     */
    async userProfilesAddCardToProfileRaw(requestParameters: UserProfilesAddCardToProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userProfilesAddCardToProfile().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling userProfilesAddCardToProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/UserProfiles/{id}/cards`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CardOnFileRequestToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileResponseFromJSON(jsonValue));
    }

    /**
     */
    async userProfilesAddCardToProfile(requestParameters: UserProfilesAddCardToProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileResponse> {
        const response = await this.userProfilesAddCardToProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userProfilesCreateUserProfileRaw(requestParameters: UserProfilesCreateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileResponse>> {
        if (requestParameters['createUserProfileRequest'] == null) {
            throw new runtime.RequiredError(
                'createUserProfileRequest',
                'Required parameter "createUserProfileRequest" was null or undefined when calling userProfilesCreateUserProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/UserProfiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserProfileRequestToJSON(requestParameters['createUserProfileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileResponseFromJSON(jsonValue));
    }

    /**
     */
    async userProfilesCreateUserProfile(requestParameters: UserProfilesCreateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileResponse> {
        const response = await this.userProfilesCreateUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userProfilesDeleteCardFromProfileRaw(requestParameters: UserProfilesDeleteCardFromProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userProfilesDeleteCardFromProfile().'
            );
        }

        if (requestParameters['cardId'] == null) {
            throw new runtime.RequiredError(
                'cardId',
                'Required parameter "cardId" was null or undefined when calling userProfilesDeleteCardFromProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/UserProfiles/{id}/cards/{cardId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters['cardId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileResponseFromJSON(jsonValue));
    }

    /**
     */
    async userProfilesDeleteCardFromProfile(requestParameters: UserProfilesDeleteCardFromProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileResponse> {
        const response = await this.userProfilesDeleteCardFromProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userProfilesGetUserProfileRaw(requestParameters: UserProfilesGetUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userProfilesGetUserProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/UserProfiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileResponseFromJSON(jsonValue));
    }

    /**
     */
    async userProfilesGetUserProfile(requestParameters: UserProfilesGetUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileResponse> {
        const response = await this.userProfilesGetUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userProfilesPatchProfileRaw(requestParameters: UserProfilesPatchProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userProfilesPatchProfile().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling userProfilesPatchProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/UserProfiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchUserProfileRequestToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileResponseFromJSON(jsonValue));
    }

    /**
     */
    async userProfilesPatchProfile(requestParameters: UserProfilesPatchProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileResponse> {
        const response = await this.userProfilesPatchProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
