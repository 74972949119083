/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrderFeedbackModel,
  OrderRequestModel,
  OrderResultModel,
  ProblemDetails,
  ReceiptRequest,
  ReminderRequest,
  ReminderResponse,
} from '../models/index';
import {
    OrderFeedbackModelFromJSON,
    OrderFeedbackModelToJSON,
    OrderRequestModelFromJSON,
    OrderRequestModelToJSON,
    OrderResultModelFromJSON,
    OrderResultModelToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ReceiptRequestFromJSON,
    ReceiptRequestToJSON,
    ReminderRequestFromJSON,
    ReminderRequestToJSON,
    ReminderResponseFromJSON,
    ReminderResponseToJSON,
} from '../models/index';

export interface OrdersCreateReceiptRequest {
    orderId: string;
    receiptRequest: ReceiptRequest;
}

export interface OrdersCreateReminderRequest {
    orderId: string;
    reminderRequest: ReminderRequest;
}

export interface OrdersCreateUpdateFeedbackRequest {
    id: string;
    feedbackModel: OrderFeedbackModel;
}

export interface OrdersGetOrderRequest {
    siteId: string;
    orderId: string;
}

export interface OrdersMakeOrderRequest {
    order: OrderRequestModel;
}

/**
 * 
 */
export class OrdersApi extends runtime.BaseAPI {

    /**
     */
    async ordersCreateReceiptRaw(requestParameters: OrdersCreateReceiptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling ordersCreateReceipt().'
            );
        }

        if (requestParameters['receiptRequest'] == null) {
            throw new runtime.RequiredError(
                'receiptRequest',
                'Required parameter "receiptRequest" was null or undefined when calling ordersCreateReceipt().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Orders/{orderId}/receipt`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReceiptRequestToJSON(requestParameters['receiptRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersCreateReceipt(requestParameters: OrdersCreateReceiptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersCreateReceiptRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersCreateReminderRaw(requestParameters: OrdersCreateReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReminderResponse>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling ordersCreateReminder().'
            );
        }

        if (requestParameters['reminderRequest'] == null) {
            throw new runtime.RequiredError(
                'reminderRequest',
                'Required parameter "reminderRequest" was null or undefined when calling ordersCreateReminder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Orders/{orderId}/reminder`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReminderRequestToJSON(requestParameters['reminderRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReminderResponseFromJSON(jsonValue));
    }

    /**
     */
    async ordersCreateReminder(requestParameters: OrdersCreateReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReminderResponse> {
        const response = await this.ordersCreateReminderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async ordersCreateUpdateFeedbackRaw(requestParameters: OrdersCreateUpdateFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling ordersCreateUpdateFeedback().'
            );
        }

        if (requestParameters['feedbackModel'] == null) {
            throw new runtime.RequiredError(
                'feedbackModel',
                'Required parameter "feedbackModel" was null or undefined when calling ordersCreateUpdateFeedback().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Orders/{id}/feedback`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderFeedbackModelToJSON(requestParameters['feedbackModel']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersCreateUpdateFeedback(requestParameters: OrdersCreateUpdateFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersCreateUpdateFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersGetOrderRaw(requestParameters: OrdersGetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderResultModel>> {
        if (requestParameters['siteId'] == null) {
            throw new runtime.RequiredError(
                'siteId',
                'Required parameter "siteId" was null or undefined when calling ordersGetOrder().'
            );
        }

        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling ordersGetOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Orders/{siteId}/{orderId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters['siteId']))).replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResultModelFromJSON(jsonValue));
    }

    /**
     */
    async ordersGetOrder(requestParameters: OrdersGetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderResultModel> {
        const response = await this.ordersGetOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async ordersMakeOrderRaw(requestParameters: OrdersMakeOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderResultModel>> {
        if (requestParameters['order'] == null) {
            throw new runtime.RequiredError(
                'order',
                'Required parameter "order" was null or undefined when calling ordersMakeOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRequestModelToJSON(requestParameters['order']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResultModelFromJSON(jsonValue));
    }

    /**
     */
    async ordersMakeOrder(requestParameters: OrdersMakeOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderResultModel> {
        const response = await this.ordersMakeOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
