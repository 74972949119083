/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FaqModel
 */
export interface FaqModel {
    /**
     * 
     * @type {string}
     * @memberof FaqModel
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof FaqModel
     */
    answer: string;
}

/**
 * Check if a given object implements the FaqModel interface.
 */
export function instanceOfFaqModel(value: object): value is FaqModel {
    if (!('question' in value) || value['question'] === undefined) return false;
    if (!('answer' in value) || value['answer'] === undefined) return false;
    return true;
}

export function FaqModelFromJSON(json: any): FaqModel {
    return FaqModelFromJSONTyped(json, false);
}

export function FaqModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaqModel {
    if (json == null) {
        return json;
    }
    return {
        
        'question': json['question'],
        'answer': json['answer'],
    };
}

export function FaqModelToJSON(value?: FaqModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'question': value['question'],
        'answer': value['answer'],
    };
}

