import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import RangeSelectionControl from "../common/controls/RangeSelectionControl";
import { LabelledNumber } from "../../models";
import { useTranslation } from "react-i18next";
import "../../i18n";

const VariableAmountSelector = ({ values, onChange, value }: VariableAmountSelectorProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider variant="fullWidth" />
      <Box p={2}>
        <Typography variant="caption" component="span">
          {t("Customise-Amount")}
        </Typography>
        <RangeSelectionControl onChange={onChange} values={values} value={value} />
      </Box>
    </>
  );
};

type VariableAmountSelectorProps = {
  values: Array<LabelledNumber>;
  value: number;
  onChange: (value: number) => void;
};

export default VariableAmountSelector;
