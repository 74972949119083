import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { LoyaltyModel } from "../../../api/gen";
import { Locale } from "../../../models/locale";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const LoyaltyRedemptionPanel = ({ loyalty, locale }: LoyaltyRedemptionPanelPropTypes) => {
  const valueOfPoints = loyalty.availableBalanceValue > 0 ? `${locale.formatCurrency(loyalty.availableBalanceValue)}` : `${loyalty.availableBalance} points`;
  const { t } = useTranslation();

  return (
    <Alert icon={<FavoriteIcon fontSize={"small"} sx={{ color: "white" }} />} sx={{ backgroundColor: "#e85fbf", color: "white" }}>
      <AlertTitle>{loyalty.name}</AlertTitle>
      {loyalty.availableBalance && <>{t("Loyalty-Redemption-Alert", { points: valueOfPoints })}</>}
    </Alert>
  );
};

type LoyaltyRedemptionPanelPropTypes = {
  loyalty: LoyaltyModel;
  locale: Locale;
};

export default LoyaltyRedemptionPanel;
