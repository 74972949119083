import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import DialogWithTitle from "../common/dialog/DialogWithTitle";
import { Box, Select, MenuItem, FormControl, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { Us, Es, Lt } from "react-flags-select";

const LanguageDialog = ({ open, onClose }: LanguageDialogPropTypes) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language || "en");

  useEffect(() => {
    setLanguage(i18n.language || "en");
    console.log("Current language:", i18n.language);
  }, [i18n.language]);

  const changeLanguage = async (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    try {
      await i18n.changeLanguage(newLanguage);
      setLanguage(newLanguage);
    } catch (error) {
      console.error("Error changing language: ", error);
    }
  };

  useEffect(() => {
    if (open) {
      ReactGA.event({
        category: "Hamburger",
        action: "Show Language page",
      });
    }
  }, [open]);

  return (
    <DialogWithTitle open={open} onClose={onClose} title={t("Language")}>
      <Box className={"gradient-background"} flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"stretch"}>
        <Box mx={2} display={"flex"} flexDirection={"column"} alignItems={"stretch"} pt={3}>
          <FormControl variant="outlined">
            <Select labelId="language-select-label" value={language} onChange={changeLanguage}>
              <MenuItem value={"en"}>
                <Box display="flex" alignItems="center">
                  <Us />
                  <Box ml={1}>English</Box>
                </Box>
              </MenuItem>
              <MenuItem value={"lt"}>
                <Box display="flex" alignItems="center">
                  <Lt />
                  <Box ml={1}>Lithuanian</Box>
                </Box>
              </MenuItem>
              <MenuItem value={"es"}>
                <Box display="flex" alignItems="center">
                  <Es />
                  <Box ml={1}>Spanish</Box>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {/*Empty space to allow scroll up*/}
        <Box p={8} />
      </Box>
    </DialogWithTitle>
  );
};

type LanguageDialogPropTypes = {
  onClose: () => void;
  open: boolean;
};

export default LanguageDialog;
