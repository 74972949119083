import { TokenReceivedCallback } from "../../CheckoutPage";
import { useLocale, usePaymentMethods } from "../../../../hooks";
import { PaymentTokenType } from "../../../../api/gen";
import GooglePayButtonWrapper from "../common/GooglePayButtonWrapper";
import React, { useEffect, useState } from "react";
import ApplePayButton from "../common/ApplePayButton";
import { DatacapApi } from "../../../../api/datacapApi";
import { DigitalWalletType } from "../../../../models";

const DatacapPaymentButton = ({ amount, onTokenReceived, digitalWalletType }: DatacapButtonPropTypes) => {
  const { isProduction, applePaySettings, digitalWalletChannel, googlePaySettings } = usePaymentMethods();
  const locale = useLocale();
  const [datacapApi, setDatacapApi] = useState<DatacapApi>();

  useEffect(() => {
    if (digitalWalletChannel?.applicationId && applePaySettings) {
      setDatacapApi(new DatacapApi(digitalWalletChannel.applicationId, isProduction, applePaySettings.merchantId, applePaySettings.merchantName));
    }
  }, [digitalWalletChannel, isProduction, applePaySettings]);

  const handleTokenResponse = (tokenResponse: DatacapWebToken.TokenResponse, paymentData: ApplePayJS.ApplePayPayment | google.payments.api.PaymentData) => {
    if (tokenResponse.Error) {
      console.warn("Datacap tokenisation failed for Google Pay: " + tokenResponse.Error);
    }

    const isApplePay = digitalWalletType === DigitalWalletType.APPLE_PAY;
    onTokenReceived({
      token: tokenResponse.Token || "",
      tokenMeta: {
        tokenType: isApplePay ? PaymentTokenType.ApplePayUsaV3 : PaymentTokenType.GooglePayUsaV3,
        digitalWalletType: isApplePay ? DigitalWalletType.APPLE_PAY : DigitalWalletType.GOOGLE_PAY,
        email: isApplePay ? (paymentData as ApplePayJS.ApplePayPayment).shippingContact?.emailAddress : (paymentData as google.payments.api.PaymentData).email,
        cardLast4: isApplePay ? undefined : (paymentData as google.payments.api.PaymentData).paymentMethodData.info?.cardDetails
      }
    });
  };

  const handleTokenReceived = async (paymentData: ApplePayJS.ApplePayPayment | google.payments.api.PaymentData) => {
    console.debug(paymentData);
    if (digitalWalletType === DigitalWalletType.APPLE_PAY && datacapApi) {
      datacapApi.exchangeAppleTokenWithPaymentTokenAsync(paymentData as ApplePayJS.ApplePayPayment).then((response) => handleTokenResponse(response, paymentData));
    } else if (datacapApi) {
      datacapApi.exchangeGoogleTokenWithPaymentTokenAsync(paymentData as google.payments.api.PaymentData).then((response) => handleTokenResponse(response, paymentData));
    }
  };

  if (digitalWalletType === DigitalWalletType.APPLE_PAY && datacapApi) {
    return (
      <ApplePayButton
        locale={locale}
        onPaymentDataReceived={handleTokenReceived}
        amount={amount}
        applePaySettings={applePaySettings!}
        applePaySessionApi={datacapApi}
        supportsAmex={true}
      />
    );
  } else {
    return (
      <GooglePayButtonWrapper
        isProduction={isProduction}
        amount={amount}
        onPaymentDataReceived={handleTokenReceived}
        googlePaySettings={googlePaySettings!}
        locale={locale}
        supportsAmex={true}
      />
    );
  }
};

type DatacapButtonPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  amount: number;
  digitalWalletType: DigitalWalletType
};

export default DatacapPaymentButton;
