/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentChannelIdentifier } from './PaymentChannelIdentifier';
import {
    PaymentChannelIdentifierFromJSON,
    PaymentChannelIdentifierFromJSONTyped,
    PaymentChannelIdentifierToJSON,
} from './PaymentChannelIdentifier';
import type { PaymentChannel } from './PaymentChannel';
import {
    PaymentChannelFromJSON,
    PaymentChannelFromJSONTyped,
    PaymentChannelToJSON,
} from './PaymentChannel';
import type { SiteStatus } from './SiteStatus';
import {
    SiteStatusFromJSON,
    SiteStatusFromJSONTyped,
    SiteStatusToJSON,
} from './SiteStatus';
import type { GooglePaySettings } from './GooglePaySettings';
import {
    GooglePaySettingsFromJSON,
    GooglePaySettingsFromJSONTyped,
    GooglePaySettingsToJSON,
} from './GooglePaySettings';
import type { Machine } from './Machine';
import {
    MachineFromJSON,
    MachineFromJSONTyped,
    MachineToJSON,
} from './Machine';
import type { ApplePaySettings } from './ApplePaySettings';
import {
    ApplePaySettingsFromJSON,
    ApplePaySettingsFromJSONTyped,
    ApplePaySettingsToJSON,
} from './ApplePaySettings';

/**
 * 
 * @export
 * @interface SiteConfig
 */
export interface SiteConfig {
    /**
     * 
     * @type {boolean}
     * @memberof SiteConfig
     */
    showCategorySelector: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SiteConfig
     */
    showZoneSelector: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SiteConfig
     */
    isProduction: boolean;
    /**
     * 
     * @type {SiteStatus}
     * @memberof SiteConfig
     */
    status: SiteStatus;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    siteId: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    transactionFeeLabel: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    locale: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    backgroundImage: string;
    /**
     * 
     * @type {PaymentChannelIdentifier}
     * @memberof SiteConfig
     */
    digitalWalletProvider: PaymentChannelIdentifier;
    /**
     * 
     * @type {PaymentChannelIdentifier}
     * @memberof SiteConfig
     */
    cardPaymentProvider: PaymentChannelIdentifier;
    /**
     * 
     * @type {GooglePaySettings}
     * @memberof SiteConfig
     */
    googlePaySettings?: GooglePaySettings;
    /**
     * 
     * @type {ApplePaySettings}
     * @memberof SiteConfig
     */
    applePaySettings?: ApplePaySettings;
    /**
     * 
     * @type {string}
     * @memberof SiteConfig
     */
    smsReminderMode: string;
    /**
     * 
     * @type {number}
     * @memberof SiteConfig
     */
    maxNumberOfSelectableMachines: number;
    /**
     * 
     * @type {Array<Machine>}
     * @memberof SiteConfig
     */
    machines: Array<Machine>;
    /**
     * 
     * @type {Array<PaymentChannel>}
     * @memberof SiteConfig
     */
    paymentChannels: Array<PaymentChannel>;
    /**
     * 
     * @type {boolean}
     * @memberof SiteConfig
     */
    requoteRequiredOnPaymentTypeChange: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SiteConfig
     */
    isProcessingFeeDependentOnSelectedPaymentGateway: boolean;
}

/**
 * Check if a given object implements the SiteConfig interface.
 */
export function instanceOfSiteConfig(value: object): value is SiteConfig {
    if (!('showCategorySelector' in value) || value['showCategorySelector'] === undefined) return false;
    if (!('showZoneSelector' in value) || value['showZoneSelector'] === undefined) return false;
    if (!('isProduction' in value) || value['isProduction'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('siteId' in value) || value['siteId'] === undefined) return false;
    if (!('currencyCode' in value) || value['currencyCode'] === undefined) return false;
    if (!('transactionFeeLabel' in value) || value['transactionFeeLabel'] === undefined) return false;
    if (!('countryCode' in value) || value['countryCode'] === undefined) return false;
    if (!('locale' in value) || value['locale'] === undefined) return false;
    if (!('backgroundImage' in value) || value['backgroundImage'] === undefined) return false;
    if (!('digitalWalletProvider' in value) || value['digitalWalletProvider'] === undefined) return false;
    if (!('cardPaymentProvider' in value) || value['cardPaymentProvider'] === undefined) return false;
    if (!('smsReminderMode' in value) || value['smsReminderMode'] === undefined) return false;
    if (!('maxNumberOfSelectableMachines' in value) || value['maxNumberOfSelectableMachines'] === undefined) return false;
    if (!('machines' in value) || value['machines'] === undefined) return false;
    if (!('paymentChannels' in value) || value['paymentChannels'] === undefined) return false;
    if (!('requoteRequiredOnPaymentTypeChange' in value) || value['requoteRequiredOnPaymentTypeChange'] === undefined) return false;
    if (!('isProcessingFeeDependentOnSelectedPaymentGateway' in value) || value['isProcessingFeeDependentOnSelectedPaymentGateway'] === undefined) return false;
    return true;
}

export function SiteConfigFromJSON(json: any): SiteConfig {
    return SiteConfigFromJSONTyped(json, false);
}

export function SiteConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'showCategorySelector': json['showCategorySelector'],
        'showZoneSelector': json['showZoneSelector'],
        'isProduction': json['isProduction'],
        'status': SiteStatusFromJSON(json['status']),
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'name': json['name'],
        'siteId': json['siteId'],
        'currencyCode': json['currencyCode'],
        'transactionFeeLabel': json['transactionFeeLabel'],
        'countryCode': json['countryCode'],
        'locale': json['locale'],
        'backgroundImage': json['backgroundImage'],
        'digitalWalletProvider': PaymentChannelIdentifierFromJSON(json['digitalWalletProvider']),
        'cardPaymentProvider': PaymentChannelIdentifierFromJSON(json['cardPaymentProvider']),
        'googlePaySettings': json['googlePaySettings'] == null ? undefined : GooglePaySettingsFromJSON(json['googlePaySettings']),
        'applePaySettings': json['applePaySettings'] == null ? undefined : ApplePaySettingsFromJSON(json['applePaySettings']),
        'smsReminderMode': json['smsReminderMode'],
        'maxNumberOfSelectableMachines': json['maxNumberOfSelectableMachines'],
        'machines': ((json['machines'] as Array<any>).map(MachineFromJSON)),
        'paymentChannels': ((json['paymentChannels'] as Array<any>).map(PaymentChannelFromJSON)),
        'requoteRequiredOnPaymentTypeChange': json['requoteRequiredOnPaymentTypeChange'],
        'isProcessingFeeDependentOnSelectedPaymentGateway': json['isProcessingFeeDependentOnSelectedPaymentGateway'],
    };
}

export function SiteConfigToJSON(value?: SiteConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'showCategorySelector': value['showCategorySelector'],
        'showZoneSelector': value['showZoneSelector'],
        'isProduction': value['isProduction'],
        'status': SiteStatusToJSON(value['status']),
        'phoneNumber': value['phoneNumber'],
        'emailAddress': value['emailAddress'],
        'name': value['name'],
        'siteId': value['siteId'],
        'currencyCode': value['currencyCode'],
        'transactionFeeLabel': value['transactionFeeLabel'],
        'countryCode': value['countryCode'],
        'locale': value['locale'],
        'backgroundImage': value['backgroundImage'],
        'digitalWalletProvider': PaymentChannelIdentifierToJSON(value['digitalWalletProvider']),
        'cardPaymentProvider': PaymentChannelIdentifierToJSON(value['cardPaymentProvider']),
        'googlePaySettings': GooglePaySettingsToJSON(value['googlePaySettings']),
        'applePaySettings': ApplePaySettingsToJSON(value['applePaySettings']),
        'smsReminderMode': value['smsReminderMode'],
        'maxNumberOfSelectableMachines': value['maxNumberOfSelectableMachines'],
        'machines': ((value['machines'] as Array<any>).map(MachineToJSON)),
        'paymentChannels': ((value['paymentChannels'] as Array<any>).map(PaymentChannelToJSON)),
        'requoteRequiredOnPaymentTypeChange': value['requoteRequiredOnPaymentTypeChange'],
        'isProcessingFeeDependentOnSelectedPaymentGateway': value['isProcessingFeeDependentOnSelectedPaymentGateway'],
    };
}

