/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MachineOptionsMode = {
    One: 'One',
    Multiple: 'Multiple',
    NotApplicable: 'NotApplicable'
} as const;
export type MachineOptionsMode = typeof MachineOptionsMode[keyof typeof MachineOptionsMode];


export function instanceOfMachineOptionsMode(value: any): boolean {
    for (const key in MachineOptionsMode) {
        if (Object.prototype.hasOwnProperty.call(MachineOptionsMode, key)) {
            if ((MachineOptionsMode as Record<string, MachineOptionsMode>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MachineOptionsModeFromJSON(json: any): MachineOptionsMode {
    return MachineOptionsModeFromJSONTyped(json, false);
}

export function MachineOptionsModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineOptionsMode {
    return json as MachineOptionsMode;
}

export function MachineOptionsModeToJSON(value?: MachineOptionsMode | null): any {
    return value as any;
}

