import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export function PageSpinner({ open, message }) {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress color="inherit" />
        <Typography fontFamily={"Quicksand"} variant={"h5"} sx={{ paddingTop: "24px" }}>
          {message}
        </Typography>
      </Box>
    </Backdrop>
  );
}

PageSpinner.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};
