import { Cart } from "../models";
import { MachineCreditRequest, PaymentChannelIdentifier, QuotesApi } from "./gen";
import { defaultConfiguration } from "./apiUtils";

const QUOTES_API = new QuotesApi(defaultConfiguration);

export async function getQuote(siteId: string, cart: Cart, userProfileId?: string, paymentChannelType?: PaymentChannelIdentifier) {
  return await QUOTES_API.quotesCalculateQuote({
    quoteRequestModel: {
      siteId: siteId,
      promoCode: cart.promoCode,
      userProfileId: userProfileId,
      paymentChannelIdentifier: paymentChannelType,
      machineCredits: cart.machineCredits.map(
        (mc) =>
          ({
            machineId: mc.machine.id,
            vendAmount: mc.amount
          } as MachineCreditRequest)
      )
    }
  });
}
