import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendTelemetry } from "../api/telemetryApi";
import { useTranslation } from "react-i18next";
import "../i18n";

const SiteConfigError = () => {
  const navigate = useNavigate();
  const { siteId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.search) {
      sendTelemetry(`Site config error for ${siteId}`).catch(() => console.error("Error sending telemetry"));
    }
  }, [siteId]);

  const handleStartAgainClicked = () => {
    navigate(`/${siteId}`);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} p={6} height={"100vh"} bgcolor={"white"}>
      <Alert severity={"error"}>
        <AlertTitle>{t("Error")}</AlertTitle>
        {t("Error-Message")}
      </Alert>
      <Typography variant={"body2"} sx={{ my: 4 }}>
        {t("Contact")} <a href={"mailto:service@tangerpay.com"}>service@tangerpay.com</a>
        {t("Resolve-Issue")}.
      </Typography>
      <Button onClick={handleStartAgainClicked} fullWidth variant={"contained"}>
        {t("Start-Again")}
      </Button>
    </Box>
  );
};

export default SiteConfigError;
