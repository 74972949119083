import { DigitalWalletType, PaymentOption } from "../../../models";
import { CardScheme } from "../../../api/gen";
import TextWithIcon from "../../common/controls/TextWithIcon";
import React from "react";
import { ReactComponent as PayPalIcon } from "../../../components/checkout/assets/paypal-color-icon.svg";
import { CreditCardTwoTone } from "@mui/icons-material";
import { ReactComponent as ApplePayIcon } from "../../../components/checkout/assets/Apple_Pay_Mark_RGB_041619.svg";
import { ReactComponent as GooglePayIcon } from "../../../components/checkout/assets/google-pay-mark_800.svg";
import CardIconSelector from "../assets/cardicons/CardIconSelector";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const ICON_WIDTH = 56;

const PaymentTypeLabelFactory: React.FC<{
  paymentOption: PaymentOption,
  digitalWalletType?: DigitalWalletType,
  cardScheme?: CardScheme,
  cardLast4?: string,

}> = (props) => {

  const { t } = useTranslation();

  if (props.paymentOption === PaymentOption.CARD)
    return <TextWithIcon text={t("Card")} icon={<Box width={ICON_WIDTH} display={"flex"}><CreditCardTwoTone transform={"scale(1.2)"} /></Box>} />;
  if (props.paymentOption === PaymentOption.PAYPAL)
    return <TextWithIcon text="PayPal" icon={<Box width={ICON_WIDTH}><PayPalIcon height="24px" display={"flex"} /></Box>} />;
  if (props.paymentOption === PaymentOption.CARD_ON_FILE)
    return <TextWithIcon text={`•••• ${props.cardLast4}`} icon={<Box width={ICON_WIDTH}><CardIconSelector cardType={props.cardScheme} /></Box>} />;
  if (props.paymentOption === PaymentOption.DIGITAL_WALLET && props.digitalWalletType === DigitalWalletType.APPLE_PAY)
    return <TextWithIcon text="Apple Pay" icon={<Box width={ICON_WIDTH}><ApplePayIcon height="24px" display={"flex"} /></Box>} />;
  if (props.paymentOption === PaymentOption.DIGITAL_WALLET && props.digitalWalletType === DigitalWalletType.GOOGLE_PAY)
    return <TextWithIcon text="Google Pay" icon={<Box width={ICON_WIDTH}><GooglePayIcon height="24px" display={"flex"} /></Box>} />;
  return <></>;
};

export default PaymentTypeLabelFactory;