import { configureStore } from "@reduxjs/toolkit";
import cart from "./reducers/cartReducer";
import siteConfig from "./reducers/siteConfigReducer";
import order from "./reducers/orderReducer";
import quote from "./reducers/quoteReducer";
import sessionContext from "./reducers/sessionContextReducer";
import userProfile from "./reducers/userProfileReducer";
import splashMessage from "./reducers/splashMessageReducer";

export const store = configureStore({
  reducer: {
    cart,
    siteConfig,
    order,
    quote,
    sessionContext,
    userProfile,
    splashMessage,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
