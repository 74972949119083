// https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill

import { InputAdornment, TextField } from "@mui/material";
import CardIconSelector from "../../../assets/cardicons/CardIconSelector";
import React, { useRef, useState } from "react";
import Payment from "payment";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";

type CardTextBoxPropTypes = {
  cardNumber: string;
  cardBrand: string;
  onChange: (event: string) => void;
};

const acceptedCardTypes = ["amex", "visa", "mastercard"];

const CardTextBox = ({ cardBrand, cardNumber, onChange }: CardTextBoxPropTypes) => {
  const [error, setError] = useState("");
  const cardNumberRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  if (cardNumberRef.current) {
    Payment.formatCardNumber(cardNumberRef.current);
  }

  function handleChangeEvent(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    let ccNum = evt.target.value;

    if (ccNum.length > 2 && !acceptedCardTypes.includes(Payment.fns.cardType(ccNum))) {
      setError(t("Card-Not-Accepted"));
    } else {
      setError("");
    }

    onChange(ccNum);
  }

  // This could be reusable in the future with other gateways that use a similar integration style as Datacap.
  return (
    <TextField
      inputRef={cardNumberRef}
      label={t("Card-Number")}
      variant="outlined"
      fullWidth
      required
      size={"small"}
      inputProps={{ "data-token": "card_number", id: undefined }}
      onChange={(evt) => handleChangeEvent(evt)}
      value={cardNumber}
      onBlur={() => {
        if (!Payment.fns.validateCardNumber(cardNumber)) {
          setError(t("Card-Number-Invalid-1"));
        }
      }}
      autoComplete="cc-number"
      margin="normal"
      error={!!error}
      helperText={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <CardIconSelector cardType={cardBrand} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CardTextBox;
