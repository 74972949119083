import { SplashMessage, SplashMessageApi } from "./gen";
import { defaultConfiguration } from "./apiUtils";

const SPLASH_MESSAGE_API = new SplashMessageApi(defaultConfiguration);

export const getSplashMessages = async (siteId: string) : Promise<Array<SplashMessage>> => {
  console.info("Retrieving splash messages for site " + siteId);

  return await SPLASH_MESSAGE_API.splashMessageGetSplashMessages({
    siteId: siteId
  });
};
