/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentTokenType = {
    SquareNonce: 'SquareNonce',
    StripeNonce: 'StripeNonce',
    SquareTransaction: 'SquareTransaction',
    PayPalTransaction: 'PayPalTransaction',
    TransactionAuV1: 'TransactionAuV1',
    TransactionAuV2: 'TransactionAuV2',
    TransactionAuV3: 'TransactionAuV3',
    NonceAuV3: 'NonceAuV3',
    CofauV3: 'COFAuV3',
    ApplePayAuV1: 'ApplePayAuV1',
    ApplePayAuV3: 'ApplePayAuV3',
    GooglePayAuV1: 'GooglePayAuV1',
    GooglePayUsaV3: 'GooglePayUsaV3',
    GooglePayAuV3: 'GooglePayAuV3',
    TransactionUsaV2: 'TransactionUsaV2',
    TransactionUsaV3: 'TransactionUsaV3',
    NonceUsaV3: 'NonceUsaV3',
    NonceUsaV2: 'NonceUsaV2',
    ApplePayUsaV3: 'ApplePayUsaV3',
    GooglePayUsaV2: 'GooglePayUsaV2',
    ApplePayUsaV2: 'ApplePayUsaV2',
    TransactionNzV1: 'TransactionNzV1',
    WalletV1: 'WalletV1',
    RemoteStart: 'RemoteStart',
    PaymentBypass: 'PaymentBypass',
    Cash: 'Cash',
    TransactionEuV1: 'TransactionEuV1',
    TransactionEuV1EComm: 'TransactionEuV1EComm',
    NayaxTransaction: 'NayaxTransaction'
} as const;
export type PaymentTokenType = typeof PaymentTokenType[keyof typeof PaymentTokenType];


export function instanceOfPaymentTokenType(value: any): boolean {
    for (const key in PaymentTokenType) {
        if (Object.prototype.hasOwnProperty.call(PaymentTokenType, key)) {
            if ((PaymentTokenType as Record<string, PaymentTokenType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PaymentTokenTypeFromJSON(json: any): PaymentTokenType {
    return PaymentTokenTypeFromJSONTyped(json, false);
}

export function PaymentTokenTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTokenType {
    return json as PaymentTokenType;
}

export function PaymentTokenTypeToJSON(value?: PaymentTokenType | null): any {
    return value as any;
}

