import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const handleStartAgain = (siteId: string) => {
  window.location.pathname = siteId;
};

const QuoteErrorView = ({ showDialog, siteId }: QuoteErrorViewPropTypes) => {
  const { t } = useTranslation();

  return (
    <Dialog open={showDialog} fullWidth>
      <DialogTitle>{t("Quote-Error-Title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("Quote-Error-Message")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleStartAgain(siteId)}>{t("Start-Again")}</Button>
      </DialogActions>
    </Dialog>
  );
};

type QuoteErrorViewPropTypes = {
  showDialog: boolean;
  siteId: string;
};

export default QuoteErrorView;
