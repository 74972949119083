import { Outlet } from "react-router-dom";
import { useSiteConfig } from "../../../hooks";
import Header from "../../pageheader/Header";

const LayoutWithHeader = () => {
  const { siteConfig } = useSiteConfig();
  if (!siteConfig) {
    return (<div />);
  } else {
    return (
      <>
        <Header siteId={siteConfig.siteId} backgroundImage={siteConfig.backgroundImage} storeEmailAddress={siteConfig.emailAddress} storeName={siteConfig.name}
                storePhoneNumber={siteConfig.phoneNumber} />
        <Outlet />
      </>
    );
  }
};

export default LayoutWithHeader;