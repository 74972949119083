import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AuthenticationRequiredPage from "../common/AuthenticationRequiredPage";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from "@mui/material";
import { ChevronRight, Close } from "@mui/icons-material";
import MenuButton from "../common/controls/MenuButton";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { useTranslation } from "react-i18next";
import "../../i18n";

const ProfilePage = ({ siteId }: ProfilePagePropTypes) => {
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useAuth0();
  const { t } = useTranslation();

  if (!isAuthenticated) {
    return <AuthenticationRequiredPage siteId={siteId} />;
  }

  const handleLogoutClicked = () => {
    logout({
      logoutParams: {
        returnTo: "/logout",
      },
    });
  };

  return (
    <Box bgcolor={"white"} height={"100vh"}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              navigate(-1);
            }}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t("Profile-My-Account")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} m={1} py={3} height={"calc(100% - 150px)"}>
        <Box display={"flex"} flexDirection={"column"}>
          <MenuButton startIcon={<PersonIcon />} endIcon={<ChevronRight />} onClick={() => navigate(`edit`)}>
            {t("Profile-Edit-Account")}
          </MenuButton>
          <MenuButton startIcon={<ReceiptLongIcon />} endIcon={<ChevronRight />}>
            {t("Profile-View-Receipts")}
          </MenuButton>
          <MenuButton startIcon={<LoyaltyIcon />} endIcon={<ChevronRight />}>
            {t("Loyalty")}
          </MenuButton>
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          <Button variant={"outlined"} color={"warning"} fullWidth style={{ marginBottom: 12 }} onClick={handleLogoutClicked}>
            {t("Log-Out")}
          </Button>
          <Button variant={"text"} color={"error"} fullWidth style={{ marginBottom: 12 }}>
            {t("Profile-Delete-Account")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

type ProfilePagePropTypes = {
  siteId: string;
};

export default ProfilePage;
