import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";

const BusyButton = ({ busy, disabled, icon, onClick, children }) => (
  <Button color="primary" variant="contained" startIcon={busy ? <CircularProgress size={22} /> : icon} disabled={busy || disabled} fullWidth size="large" onClick={onClick}>
    {children}
  </Button>
);

BusyButton.propTypes = {
  busy: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired

};

export default BusyButton;
