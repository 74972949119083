import React, { useState } from "react";
import ChevronButton from "../../common/controls/ChevronButton";
import ReactGA from "react-ga4";
import { DigitalWalletType, PaymentOption } from "../../../models";
import { CardOnFileResponse } from "../../../api/gen";
import "../../../i18n";
import PaymentTypeSelectDialog from "./PaymentTypeSelectDialog";
import { Edit } from "@mui/icons-material";

const PaymentTypeSelectButton: React.FC<{
  label: string | JSX.Element;
  onChange: (paymentOption: PaymentOption) => void;
  selectedPaymentOption: PaymentOption;
  digitalWalletType?: DigitalWalletType;
  availablePaymentOptions: PaymentOption[];
  onDeleteSavedCardOnFile: () => void;
  isBusy: boolean;
  cardOnFileData?: CardOnFileResponse;
}> = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (paymentOption: PaymentOption) => {
    ReactGA.event({
      category: "Checkout",
      action: `Change payment method to ${paymentOption}`
    });
    props.onChange(paymentOption);
  };

  const handleForgetMyCard = async () => {
    props.onDeleteSavedCardOnFile();
  };

  if (open) {
    ReactGA.event({
      category: "Checkout",
      action: "Open payment method"
    });
  }

  return <>
    <ChevronButton
      onClick={handleClickOpen}
      label={props.label}
      icon={<Edit />}
    />

    <PaymentTypeSelectDialog open={open}
                             onSave={handleSave}
                             onClose={handleClose}
                             selectedPaymentOption={props.selectedPaymentOption}
                             availablePaymentOptions={props.availablePaymentOptions}
                             onDeleteSavedCardOnFile={handleForgetMyCard}
                             isBusy={props.isBusy}
                             cardOnFileData={props.cardOnFileData}
                             digitalWalletType={props.digitalWalletType} />
  </>;
};

export default PaymentTypeSelectButton;
