import FavoriteIcon from "@mui/icons-material/Favorite";
import { Alert } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../../i18n";

export const LoyaltyRedeemedAlert: React.FC<{ discount: string }> = (props) => {
  const { t } = useTranslation();
  return (
    <Alert icon={<FavoriteIcon fontSize={"small"} sx={{ color: "white" }} />} sx={{ backgroundColor: "#e85fbf", color: "white", my: 2 }}>
      {t("Loyalty-Redeemed-Alert", { discount: props.discount })}
    </Alert>
  );
};
