/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AmountsModel
 */
export interface AmountsModel {
    /**
     * 
     * @type {number}
     * @memberof AmountsModel
     */
    totalVendAmount: number;
    /**
     * 
     * @type {number}
     * @memberof AmountsModel
     */
    totalFee: number;
    /**
     * 
     * @type {number}
     * @memberof AmountsModel
     */
    siteDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof AmountsModel
     */
    loyaltyDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof AmountsModel
     */
    promoDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof AmountsModel
     */
    grandTotal: number;
}

/**
 * Check if a given object implements the AmountsModel interface.
 */
export function instanceOfAmountsModel(value: object): value is AmountsModel {
    if (!('totalVendAmount' in value) || value['totalVendAmount'] === undefined) return false;
    if (!('totalFee' in value) || value['totalFee'] === undefined) return false;
    if (!('siteDiscount' in value) || value['siteDiscount'] === undefined) return false;
    if (!('loyaltyDiscount' in value) || value['loyaltyDiscount'] === undefined) return false;
    if (!('promoDiscount' in value) || value['promoDiscount'] === undefined) return false;
    if (!('grandTotal' in value) || value['grandTotal'] === undefined) return false;
    return true;
}

export function AmountsModelFromJSON(json: any): AmountsModel {
    return AmountsModelFromJSONTyped(json, false);
}

export function AmountsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmountsModel {
    if (json == null) {
        return json;
    }
    return {
        
        'totalVendAmount': json['totalVendAmount'],
        'totalFee': json['totalFee'],
        'siteDiscount': json['siteDiscount'],
        'loyaltyDiscount': json['loyaltyDiscount'],
        'promoDiscount': json['promoDiscount'],
        'grandTotal': json['grandTotal'],
    };
}

export function AmountsModelToJSON(value?: AmountsModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalVendAmount': value['totalVendAmount'],
        'totalFee': value['totalFee'],
        'siteDiscount': value['siteDiscount'],
        'loyaltyDiscount': value['loyaltyDiscount'],
        'promoDiscount': value['promoDiscount'],
        'grandTotal': value['grandTotal'],
    };
}

