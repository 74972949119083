import React from "react";
import { Navigate } from "react-router-dom";
import { SiteConfig } from "../api/gen";

const StartPageResolver = ({ siteConfig }: { siteConfig: SiteConfig }) => {
  console.debug("Inside start page resolver");
  if (siteConfig?.machines.length === 0) {
    return <Navigate to={`configError`} />;
  }

  if (siteConfig?.showZoneSelector) {
    return <Navigate to={`zones`} />;
  } else if (siteConfig?.showCategorySelector) {
    const zoneId = siteConfig?.machines[0].zoneId;
    return <Navigate to={`zones/${zoneId}`} />;
  }
  // if no zone or machine type selection, just go straight to the machine list
  const zoneId = siteConfig?.machines[0].zoneId;
  return <Navigate to={`zones/${zoneId}/machines`} />;
};

export default StartPageResolver;
