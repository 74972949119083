import React from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import sanitize from "sanitize-html";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const SplashMessageWithTitle = ({ open, title, message, hideOkButton, onClose }: SplashMessageWithTitlePropTypes) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box
        sx={{
          backgroundColor: "lightgray",
          minHeight: "340px",
          minWidth: "280px",
          display: "flex",
          flexDirection: "column",
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <Typography
          sx={{
            mb: 1,
            textAlign: "center",
          }}
          variant="h6"
          component="div"
        >
          {title}
        </Typography>
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <Typography
            sx={{
              mt: 1,
              ml: 2,
              mr: 2,
              flex: 1,
            }}
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{ __html: sanitize(message) }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 2,
            mb: 1,
            padding: hideOkButton ? 1 : 0,
          }}
        >
          {!hideOkButton && (
            <Button onClick={onClose} fullWidth variant={"contained"}>
              {t("Ok")}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

type SplashMessageWithTitlePropTypes = {
  open: boolean;
  title: string;
  message: string;
  hideOkButton: boolean;
  onClose: () => void;
};

export default SplashMessageWithTitle;
