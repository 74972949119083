import { UserProfileResponse } from "../../api/gen";
import { createReducer } from "@reduxjs/toolkit";
import { createNewProfile, loadUserProfile, setUserProfile, updateProfile } from "../actions/userProfileActions";

const initialState = {
  isError: false,
  isLoading: false,
} as RemoteUserProfileModelWrapper;

// Redux state is always immutable
// Remember that the Redux Tools includes Immer, which allows the appearance of mutating state when in fact state is immutable.
export const userProfileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload;
      state.isError = false;
      state.isLoading = false;
    })
    .addCase(loadUserProfile.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.userProfile = undefined;
    })
    .addCase(loadUserProfile.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.userProfile = undefined;
    })
    .addCase(setUserProfile, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.userProfile = action.payload;
    })
    .addCase(createNewProfile.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.userProfile = action.payload;
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.userProfile = action.payload;
    });
});

export type RemoteUserProfileModelWrapper = {
  isLoading: boolean;
  isError: boolean;
  userProfile?: UserProfileResponse;
};

export default userProfileReducer;
