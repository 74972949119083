/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentChannelIdentifier } from './PaymentChannelIdentifier';
import {
    PaymentChannelIdentifierFromJSON,
    PaymentChannelIdentifierFromJSONTyped,
    PaymentChannelIdentifierToJSON,
} from './PaymentChannelIdentifier';
import type { MachineCreditRequest } from './MachineCreditRequest';
import {
    MachineCreditRequestFromJSON,
    MachineCreditRequestFromJSONTyped,
    MachineCreditRequestToJSON,
} from './MachineCreditRequest';

/**
 * 
 * @export
 * @interface QuoteRequestModel
 */
export interface QuoteRequestModel {
    /**
     * 
     * @type {Array<MachineCreditRequest>}
     * @memberof QuoteRequestModel
     */
    machineCredits: Array<MachineCreditRequest>;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestModel
     */
    siteId: string;
    /**
     * 
     * @type {PaymentChannelIdentifier}
     * @memberof QuoteRequestModel
     */
    paymentChannelIdentifier?: PaymentChannelIdentifier;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestModel
     */
    promoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestModel
     */
    userProfileId?: string;
}

/**
 * Check if a given object implements the QuoteRequestModel interface.
 */
export function instanceOfQuoteRequestModel(value: object): value is QuoteRequestModel {
    if (!('machineCredits' in value) || value['machineCredits'] === undefined) return false;
    if (!('siteId' in value) || value['siteId'] === undefined) return false;
    return true;
}

export function QuoteRequestModelFromJSON(json: any): QuoteRequestModel {
    return QuoteRequestModelFromJSONTyped(json, false);
}

export function QuoteRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteRequestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'machineCredits': ((json['machineCredits'] as Array<any>).map(MachineCreditRequestFromJSON)),
        'siteId': json['siteId'],
        'paymentChannelIdentifier': json['paymentChannelIdentifier'] == null ? undefined : PaymentChannelIdentifierFromJSON(json['paymentChannelIdentifier']),
        'promoCode': json['promoCode'] == null ? undefined : json['promoCode'],
        'userProfileId': json['userProfileId'] == null ? undefined : json['userProfileId'],
    };
}

export function QuoteRequestModelToJSON(value?: QuoteRequestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'machineCredits': ((value['machineCredits'] as Array<any>).map(MachineCreditRequestToJSON)),
        'siteId': value['siteId'],
        'paymentChannelIdentifier': PaymentChannelIdentifierToJSON(value['paymentChannelIdentifier']),
        'promoCode': value['promoCode'],
        'userProfileId': value['userProfileId'],
    };
}

