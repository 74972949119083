/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LoyaltyProgramType = {
    None: 'None',
    XWashFree: 'XWashFree',
    CustomPercentDiscount: 'CustomPercentDiscount',
    CustomDollarCredit: 'CustomDollarCredit',
    Points: 'Points'
} as const;
export type LoyaltyProgramType = typeof LoyaltyProgramType[keyof typeof LoyaltyProgramType];


export function instanceOfLoyaltyProgramType(value: any): boolean {
    for (const key in LoyaltyProgramType) {
        if (Object.prototype.hasOwnProperty.call(LoyaltyProgramType, key)) {
            if ((LoyaltyProgramType as Record<string, LoyaltyProgramType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LoyaltyProgramTypeFromJSON(json: any): LoyaltyProgramType {
    return LoyaltyProgramTypeFromJSONTyped(json, false);
}

export function LoyaltyProgramTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyProgramType {
    return json as LoyaltyProgramType;
}

export function LoyaltyProgramTypeToJSON(value?: LoyaltyProgramType | null): any {
    return value as any;
}

