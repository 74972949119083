import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { PaymentRequest } from "@stripe/stripe-js";
import { useLocale } from "../../../../hooks";
import { PaymentTokenType } from "../../../../api/gen";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { DigitalWalletType } from "../../../../models";
import { OnButtonMounted } from "./StripePaymentRequest";
import { useTranslation } from "react-i18next";
import "../../../../i18n";

// see https://stripe.com/docs/js and https://stripe.com/docs/api for docs

/**
 * Callback to notify whether the button was mounted or not.
 *
 * @callback onButtonMounted
 * @param {boolean} didMount was the payment button mounted? If so, a digital wallet payment option is avalable.
 * @param {string} walletType what type of wallet button is displayed - ApplePay or GooglePay?
 */

/**
 * A React component that renders a Stripe payment button.
 */
const StripePaymentRequestInternal = ({ amount, onTokenReceived, onButtonMounted }: StripePaymentRequestInternalPropTypes) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined);

  const locale = useLocale();
  const amountAsCurrency = locale.currencyMajorToMinorUnits(amount);

  const { t } = useTranslation();

  useEffect(() => {
    if (paymentRequest) {
      console.info("Setting up payment request", paymentRequest, locale, amount);
      paymentRequest.update({
        total: {
          amount: amountAsCurrency,
          label: t("Total"),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest, amountAsCurrency]); // the ONLY time we want to update the payment request is if the total has changed. This stops an error when re-rendering during the payment sheet being displayed.

  useEffect(() => {
    if (stripe) {
      console.info("Initialising Stripe PaymentRequest button");

      const pr = stripe.paymentRequest({
        country: locale.countryCode,
        currency: locale.currencyCode?.toLowerCase(),
        total: {
          label: t("Total"),
          amount: locale.currencyMajorToMinorUnits(amount),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(pr);
            onButtonMounted(true, result.applePay ? DigitalWalletType.APPLE_PAY : DigitalWalletType.GOOGLE_PAY);
          } else {
            console.warn("Stripe failed to mount payment button - receipt " + JSON.stringify(result ?? {}));
            onButtonMounted(false);
          }
        })
        .catch((e) => {
          console.warn("Exception thrown when loading Stripe request form: " + e);
          onButtonMounted(false);
        });

      pr.on("token", (result) => {
        result.complete("success"); // gets rid of the payment sheet
        console.debug("Stripe token result", result);
        onTokenReceived({
          token: result.token.id,
          tokenMeta: {
            tokenType: PaymentTokenType.StripeNonce,
            cardLast4: result.token.card?.last4,
            digitalWalletType: result.token.card?.tokenization_method === "android_pay" ? DigitalWalletType.GOOGLE_PAY : DigitalWalletType.APPLE_PAY,
            email: result.payerEmail,
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  } else {
    return <></>;
  }
};

type StripePaymentRequestInternalPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  amount: number;
  onButtonMounted: OnButtonMounted;
};

export default StripePaymentRequestInternal;
