/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProblemDetails,
  SplashMessage,
} from '../models/index';
import {
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SplashMessageFromJSON,
    SplashMessageToJSON,
} from '../models/index';

export interface SplashMessageGetSplashMessagesRequest {
    siteId?: string;
}

/**
 * 
 */
export class SplashMessageApi extends runtime.BaseAPI {

    /**
     */
    async splashMessageGetSplashMessagesRaw(requestParameters: SplashMessageGetSplashMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SplashMessage>>> {
        const queryParameters: any = {};

        if (requestParameters['siteId'] != null) {
            queryParameters['siteId'] = requestParameters['siteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/SplashMessage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SplashMessageFromJSON));
    }

    /**
     */
    async splashMessageGetSplashMessages(requestParameters: SplashMessageGetSplashMessagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SplashMessage>> {
        const response = await this.splashMessageGetSplashMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
