import React, { useEffect, useState } from "react";
import { DigitalWalletType, Token } from "../../../../models";
import ApplePayButton from "../common/ApplePayButton";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { useLocale, usePaymentMethods } from "../../../../hooks";
import { PaymentChannel, PaymentTokenType } from "../../../../api/gen";
import { useAppSelector } from "../../../../redux/hooks";
import GooglePayButtonWrapper from "../common/GooglePayButtonWrapper";
import { ApplePaySessionApi, DefaultApplePaySessionApi } from "../../../../api/applePaySessionApi";

function supportsAmex(pc?: PaymentChannel) {
  return pc?.configOptions?.find(o => o.key === "SupportsAmex")?.value?.toLowerCase() === "true";
}

const TillPaymentButton = ({ amount, onTokenReceived, digitalWalletType, paymentChannel }: TillPaymentButtonPropTypes) => {
  const locale = useLocale();
  const { digitalWalletChannel, isProduction, applePaySettings, googlePaySettings } = usePaymentMethods();
  const { userProfile } = useAppSelector((s) => s.userProfile);
  const [applePaySessionApi, setApplePaySessionApi] = useState<ApplePaySessionApi>();

  useEffect(() => {
    if (digitalWalletChannel?.applicationId && applePaySettings) {
      setApplePaySessionApi(new DefaultApplePaySessionApi(applePaySettings.merchantId));
    }
  }, [digitalWalletChannel, isProduction, applePaySettings]);

  // Apple payment data is this shape: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypayment
  // Google payment data is this shape:
  const handleTokenReceived = (paymentData: ApplePayJS.ApplePayPayment | google.payments.api.PaymentData) => {
    let token;
    if (digitalWalletType === DigitalWalletType.APPLE_PAY) {
      const appleData = paymentData as ApplePayJS.ApplePayPayment;

      token = {
        token: "applepay:" + JSON.stringify({ token: appleData.token }),
        tokenMeta: {
          tokenType: PaymentTokenType.ApplePayAuV3,
          digitalWalletType: DigitalWalletType.APPLE_PAY,
          email: appleData.shippingContact?.emailAddress || userProfile?.emailAddress
        }
      } as Token;
    } else {
      const googleData = paymentData as google.payments.api.PaymentData;

      token = {
        token: "googlepay:" + googleData.paymentMethodData.tokenizationData.token,
        tokenMeta: {
          tokenType: PaymentTokenType.GooglePayAuV3,
          cardLast4: googleData.paymentMethodData.info?.cardDetails,
          digitalWalletType: DigitalWalletType.GOOGLE_PAY,
          email: googleData.email || userProfile?.emailAddress
        }
      } as Token;
    }

    onTokenReceived(token);
  };

  if (applePaySessionApi && digitalWalletType === DigitalWalletType.APPLE_PAY) {
    console.debug("Render Till Apple Pay");
    return (
      <ApplePayButton
        locale={locale}
        onPaymentDataReceived={handleTokenReceived}
        amount={amount}
        applePaySettings={applePaySettings!}
        applePaySessionApi={applePaySessionApi}
        supportsAmex={supportsAmex(paymentChannel)} />
    );
  }

  console.debug(`Render Till Google Pay because digital wallet type is ${digitalWalletType}`);
  return (
    <GooglePayButtonWrapper
      isProduction={isProduction}
      amount={amount}
      onPaymentDataReceived={handleTokenReceived}
      googlePaySettings={googlePaySettings!}
      locale={locale}
      supportsAmex={supportsAmex(paymentChannel)}
    />
  );
};

type TillPaymentButtonPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  amount: number;
  digitalWalletType: DigitalWalletType;
  paymentChannel: PaymentChannel;
};

export default TillPaymentButton;
