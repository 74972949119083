/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Location = {
    PreHomeScreen: 'PreHomeScreen',
    PostHomeScreen: 'PostHomeScreen',
    PrePaymentScreen: 'PrePaymentScreen',
    PaymentConfirmationScreen: 'PaymentConfirmationScreen',
    PreLoyaltyScreen: 'PreLoyaltyScreen',
    PostLoyaltyScreen: 'PostLoyaltyScreen',
    MembershipInfoScreen: 'MembershipInfoScreen',
    MembershipTcScreen: 'MembershipTCScreen'
} as const;
export type Location = typeof Location[keyof typeof Location];


export function instanceOfLocation(value: any): boolean {
    for (const key in Location) {
        if (Object.prototype.hasOwnProperty.call(Location, key)) {
            if ((Location as Record<string, Location>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
    return json as Location;
}

export function LocationToJSON(value?: Location | null): any {
    return value as any;
}

