const TILL_SCRIPT = "https://gateway.tillpayments.com/js/integrated/payment.1.3.min.js";
const TILL_SANDBOX_SCRIPT = "https://test-gateway.tillpayments.com/js/integrated/payment.1.3.min.js";

let payments: PaymentJs;
const promiseResolvers: Array<(resolved: PaymentJs) => void> = [];

export const initialiseTill = (isProduction: boolean) => {
  if (!payments) {
    const tillScript = document.createElement("script");
    tillScript.src = isProduction ? TILL_SCRIPT : TILL_SANDBOX_SCRIPT;
    tillScript.type = "text/javascript";
    tillScript.async = false;
    tillScript.setAttribute("data-main", "payment-js");
    tillScript.onload = () => {
      // eslint-disable-next-line no-undef
      payments = new PaymentJs();
      console.debug("Till Payments SDK loaded, resolving promises");
      promiseResolvers.forEach((p) => p(payments));
    };
    document.getElementsByTagName("head")[0].appendChild(tillScript);
  } else {
    console.warn("Till script was already loaded");
  }
};

export const getTillApi: () => Promise<PaymentJs> = () => {
  if (payments) {
    console.debug("Till Payments SDK already loaded, returning the singleton");
    return Promise.resolve(payments);
  } else {
    // Allows a race condition to exist where a request for the Square payment API is made before the script has loaded
    console.debug("Till Payments SDK not ready yet, promise will resolve later");
    return new Promise((resolve, reject) => {
      promiseResolvers.push(resolve);
    });
  }
};
