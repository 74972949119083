import { loadStripe } from "@stripe/stripe-js";

let stripe; // holds the resolved, singleton Stripe object
const promiseResolvers = [];

export const initialiseStripe = (apiKey) => {
  if (!stripe) {
    loadStripe(apiKey).then((s) => {
      console.debug("Stripe loaded");
      stripe = s;
      promiseResolvers.forEach((promise) => {
        console.debug("Notifying promise that Stripe has resolved");
        promise(stripe);
      });
    });
  } else {
    console.warn("Stripe was already loaded");
  }
};

export const getStripe = () => {
  if (stripe) {
    console.debug("Returning resolved Stripe script");
    return Promise.resolve(stripe);
  } else {
    // Allows a race condition to exist where a request for the Square payment API is made before the script has loaded
    console.debug("Stripe not loaded yet, creating a promise to resolve it later");
    return new Promise((resolve, reject) => {
      promiseResolvers.push(resolve);
    });
  }
};
