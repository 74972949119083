import { Machine, MachineOption, MachineType, PaymentTokenType } from "../api/gen";

export interface MachineTypeDescriptions {
  type: MachineType;
  noun: string;
  verb: string;
  pluralisedNoun: string;
  priority: number;
}

export interface LabelledNumber {
  label: string;
  value: number;
}

export type SessionContext = {
  referrer?: "Kiosk" | "QR" | "NFC" | "Code";
  phoneNumber?: string;
};

export interface ReminderResult {
  success: boolean;
  phoneNumber: string;
  durationInMinutes: number;
  message: string;
}

export enum DigitalWalletType {
  APPLE_PAY = "ApplePay",
  GOOGLE_PAY = "GooglePay"
}

export enum PaymentOption {
  DIGITAL_WALLET = "digitalWallet",
  CARD = "card",
  PAYPAL = "payPal",
  CARD_ON_FILE = "cardOnFile",
}

/**
 * A token is an object that can be used to make a charge on a payment method.
 */
export type Token = {
  token: string;
  tokenMeta: TokenMeta;
};

/**
 * Information about a token
 */
export type TokenMeta = {
  tokenType: PaymentTokenType;
  cardLast4?: string;
  digitalWalletType?: DigitalWalletType;
  email?: string;
  zipCode?: string;
  cardholderName?: string;
  rememberCard?: boolean;
};

export type Cart = {
  promoCode?: string;
  machineCredits: Array<MachineCredit>;
};

export type MachineCredit = {
  machine: Machine;
  amount: number;
  selectedOptions: Array<MachineOption>;
};
