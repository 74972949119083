/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentChannelIdentifier } from './PaymentChannelIdentifier';
import {
    PaymentChannelIdentifierFromJSON,
    PaymentChannelIdentifierFromJSONTyped,
    PaymentChannelIdentifierToJSON,
} from './PaymentChannelIdentifier';
import type { KeyValurPairModel } from './KeyValurPairModel';
import {
    KeyValurPairModelFromJSON,
    KeyValurPairModelFromJSONTyped,
    KeyValurPairModelToJSON,
} from './KeyValurPairModel';

/**
 * 
 * @export
 * @interface PaymentChannel
 */
export interface PaymentChannel {
    /**
     * 
     * @type {PaymentChannelIdentifier}
     * @memberof PaymentChannel
     */
    type: PaymentChannelIdentifier;
    /**
     * 
     * @type {string}
     * @memberof PaymentChannel
     */
    applicationId: string;
    /**
     * 
     * @type {Array<KeyValurPairModel>}
     * @memberof PaymentChannel
     */
    configOptions: Array<KeyValurPairModel>;
}

/**
 * Check if a given object implements the PaymentChannel interface.
 */
export function instanceOfPaymentChannel(value: object): value is PaymentChannel {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('applicationId' in value) || value['applicationId'] === undefined) return false;
    if (!('configOptions' in value) || value['configOptions'] === undefined) return false;
    return true;
}

export function PaymentChannelFromJSON(json: any): PaymentChannel {
    return PaymentChannelFromJSONTyped(json, false);
}

export function PaymentChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentChannel {
    if (json == null) {
        return json;
    }
    return {
        
        'type': PaymentChannelIdentifierFromJSON(json['type']),
        'applicationId': json['applicationId'],
        'configOptions': ((json['configOptions'] as Array<any>).map(KeyValurPairModelFromJSON)),
    };
}

export function PaymentChannelToJSON(value?: PaymentChannel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': PaymentChannelIdentifierToJSON(value['type']),
        'applicationId': value['applicationId'],
        'configOptions': ((value['configOptions'] as Array<any>).map(KeyValurPairModelToJSON)),
    };
}

