const PROD_SCRIPT = "https://token.dcap.com/v1/client";
const TEST_SCRIPT = "https://token-cert.dcap.com/v1/client";

let loaded = false;
const promiseResolvers = [] as ((u: boolean) => void)[];

const loadScript = (src: string, onload: () => void) => {
  const scriptElement = document.createElement("script");
  scriptElement.src = src;
  scriptElement.type = "text/javascript";
  scriptElement.async = false;
  scriptElement.onload = onload;
  document.getElementsByTagName("head")[0].appendChild(scriptElement);
};

export const initialiseDatacap = (isProduction: boolean) => {
  if (!loaded) {
    const src = isProduction ? PROD_SCRIPT : TEST_SCRIPT;

    loadScript(src, () => {
      console.debug("Datacap Payments SDK loaded, resolving promises");
      loaded = true;
      promiseResolvers.forEach((p) => p(loaded));
    });
  } else {
    console.warn("Datacap script was already loaded");
  }
};

export const loadDatacapApi = () => {
  if (loaded) {
    console.debug("Datacap Payments SDK already loaded, returning the singleton");
    return Promise.resolve(loaded);
  } else {
    // Allows a race condition to exist where a request for the payment API is made before the script has loaded
    console.debug("Datacap Payments SDK not ready yet, promise will resolve later");
    return new Promise((resolve, reject) => {
      promiseResolvers.push(resolve);
    });
  }
};
