/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FeedbackType } from './FeedbackType';
import {
    FeedbackTypeFromJSON,
    FeedbackTypeFromJSONTyped,
    FeedbackTypeToJSON,
} from './FeedbackType';

/**
 * 
 * @export
 * @interface OrderFeedbackModel
 */
export interface OrderFeedbackModel {
    /**
     * 
     * @type {FeedbackType}
     * @memberof OrderFeedbackModel
     */
    type: FeedbackType;
    /**
     * 
     * @type {string}
     * @memberof OrderFeedbackModel
     */
    comments: string;
}

/**
 * Check if a given object implements the OrderFeedbackModel interface.
 */
export function instanceOfOrderFeedbackModel(value: object): value is OrderFeedbackModel {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('comments' in value) || value['comments'] === undefined) return false;
    return true;
}

export function OrderFeedbackModelFromJSON(json: any): OrderFeedbackModel {
    return OrderFeedbackModelFromJSONTyped(json, false);
}

export function OrderFeedbackModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderFeedbackModel {
    if (json == null) {
        return json;
    }
    return {
        
        'type': FeedbackTypeFromJSON(json['type']),
        'comments': json['comments'],
    };
}

export function OrderFeedbackModelToJSON(value?: OrderFeedbackModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': FeedbackTypeToJSON(value['type']),
        'comments': value['comments'],
    };
}

