import { TelemetryApi } from "./gen";
import { defaultConfiguration } from "./apiUtils";

const TELEMETRY_API = new TelemetryApi(defaultConfiguration);

export async function sendTelemetry(message: string) {
  return await TELEMETRY_API.telemetryCreateTelemetryLog({
    telemetry: {
      message: message,
    },
  });
}
