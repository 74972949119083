import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga4";
import StoreNotFound from "./components/StoreNotFound";
import SystemError from "./components/SystemError";
import HomePage from "./components/home/HomePage";
import { store } from "./redux/store";
import ThreeDSPostbackPlaceholder from "./components/common/ThreeDSPostbackPlaceholder";
import SiteClosed from "./components/SiteClosed";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const theme = createTheme({
  palette: {
    primary: {
      main: "#269ba2",
    },
    secondary: {
      main: "#FE7B00",
    },
    background: {
      default: "#e8eded",
    },
  },
});

ReactGA.initialize("G-D4DQ6PZN18");
ReactGA.send("pageview");

// swallow log output for production builds.
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.info = () => {};
  console.debug = () => {};
}

render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <React.StrictMode>
            <Suspense fallback={<div />}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/notfound" element={<StoreNotFound />} />
                <Route path="/error" element={<SystemError />} />
                <Route path="/3ds_success" element={<ThreeDSPostbackPlaceholder />} />
                <Route path="/3ds_error" element={<ThreeDSPostbackPlaceholder />} />
                <Route path="/3ds_cancel" element={<ThreeDSPostbackPlaceholder />} />
                <Route path="/closed" element={<SiteClosed />} />
                <Route path={"/:siteId/*"} element={<App />} />
              </Routes>
            </Suspense>
          </React.StrictMode>
        </Router>
      </I18nextProvider>
    </ThemeProvider>
  </ReduxProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
