import { PageSpinner } from "../common/PageSpinner";
import { Alert, AlertTitle, Box, Button, Collapse, Divider } from "@mui/material";
import OrderSummaryPart from "./parts/OrderSummaryPart";
import { KeyboardArrowLeft } from "@mui/icons-material";
import React from "react";
import TotalsPanel from "./parts/TotalsPanel";
import PaymentPart from "./parts/PaymentPart";
import { DigitalWalletTypeChangedEvent, TokenReceivedCallback } from "./CheckoutPage";
import { DigitalWalletType, PaymentOption } from "../../models";
import { PromoCodeValidationResult } from "./promo/PromoButton";
import { QuoteResponseModel } from "../../api/gen";
import { Locale } from "../../models/locale";
import { PromoPanel } from "./promo/PromoPanel";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const CheckoutView: React.FC<{
  showCalculatingSpinner: boolean;
  showProcessingPaymentSpinner: boolean;
  showContent: boolean;
  onBackToMachineSelectionClicked: () => void;
  paymentErrorMessage: string;
  onTokenReceived: TokenReceivedCallback;
  onPaymentOptionChanged?: (paymentOption: PaymentOption) => void;
  promoCode?: string;
  onPromoCodeApplied: (promoCode: string) => void;
  onPromoCodeRemoved: () => void;
  digitalWalletType?: DigitalWalletType;
  onDigitalWalletChanged: DigitalWalletTypeChangedEvent;
  promoCodeValidationResult: PromoCodeValidationResult;
  quote?: QuoteResponseModel;
  locale: Locale;
  transactionFeeLabel: string;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <PageSpinner open={props.showProcessingPaymentSpinner} message={t("Processing")} />
      <PageSpinner open={props.showCalculatingSpinner} message={t("Calculating")} />

      <Box p={2} pb={1} bgcolor="background.paper">
        <OrderSummaryPart />
        <Divider />
      </Box>
      <Box p={2} pt={1} bgcolor="background.paper">
        <PromoPanel
          onPromoCodeApplied={props.onPromoCodeApplied}
          showPromoCodeResolvingSpinner={props.showCalculatingSpinner}
          promoCode={props.promoCode}
          promoCodeValidationResult={props.promoCodeValidationResult}
          quote={props.quote}
          locale={props.locale}
        />
      </Box>

      <Box visibility={props.showContent ? "visible" : "hidden"}>
        <Box p={2}>
          <TotalsPanel
            locale={props.locale}
            quote={props.quote}
            transactionFeeLabel={props.transactionFeeLabel}
            onPromotionCodeDeleted={props.onPromoCodeRemoved}
            promoCode={props.promoCode}
          />
          <Collapse in={!!props.paymentErrorMessage}>
            <Alert severity="error" variant={"filled"}>
              <AlertTitle>{t("Payment-Alert-1")}</AlertTitle>
              {t("Payment-Alert-2")} {'"'}
              {props.paymentErrorMessage}
              {'"'}
            </Alert>
          </Collapse>

          <PaymentPart
            onPaymentTokenReceived={props.onTokenReceived}
            onPaymentOptionChanged={props.onPaymentOptionChanged}
            digitalWalletType={props.digitalWalletType}
            onDigitalWalletChanged={props.onDigitalWalletChanged}
          />
        </Box>

        <Box p={2} mb={7}>
          <Button variant={"text"} color={"primary"} size={"small"} fullWidth onClick={props.onBackToMachineSelectionClicked} startIcon={<KeyboardArrowLeft />}>
            {t("Back-To-Machine-Selection")}
          </Button>
        </Box>
      </Box>
    </>
  );
};
