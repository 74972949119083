import { Send } from "@mui/icons-material";
import { Box, Collapse, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import validator from "validator";
import { sendReceipt } from "../../../api/orderApi";
import InlineTextFieldWithSubmit from "../../common/controls/InlineTextFieldWithSubmit";
import { LocalSettingsService } from "../../../service/localSettingsService";
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import { createNewProfile, updateProfile } from "../../../redux/actions/userProfileActions";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const ReceiptRequestPart = () => {
  const { order } = useAppSelector((s) => s.order);
  const { userProfile } = useAppSelector((s) => s.userProfile);
  const [validationError, setValidationError] = useState("");
  const [lastEmailAddress, setLastEmailAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState(order?.emailAddress || "");
  const [busy, setBusy] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!emailAddress) {
      setEmailAddress(userProfile?.emailAddress || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    if (order) {
      setLastEmailAddress(order.emailAddress || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    setShow(!lastEmailAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastEmailAddress]);

  const handleEmailAddressChanged = (e: string) => {
    setEmailAddress(e);
  };

  const onSendEmailClicked = async (email: string) => {
    if (!order || !order.orderId) {
      return;
    }

    if (!email) {
      setValidationError("");
    } else {
      const isValidEmail = validator.isEmail(email);
      setValidationError(isValidEmail ? "" : t("Email-Address-Error-1"));
      if (isValidEmail) {
        setBusy(true);

        try {
          await sendReceipt(order.orderId, email);

          setLastEmailAddress(email);
          if (userProfile) {
            if (userProfile.emailAddress !== email) {
              console.log(`Updating existing profile email from ${userProfile.emailAddress} to ${email}`);
              dispatch(
                updateProfile({
                  profileId: userProfile?.id,
                  emailAddress: email,
                })
              );
            }
          } else {
            dispatch(
              createNewProfile({
                profileId: LocalSettingsService.getId()!,
                emailAddress: email,
              })
            );
          }

          setShow(false);
        } catch (e) {
          console.warn("Unable to send receipt", e);
        } finally {
          setBusy(false);
        }
      }
    }
  };

  return (
    <Paper elevation={1}>
      <Box p={2}>
        <Typography variant={"h6"} pb={0}>
          {t("Receipt")}
        </Typography>

        {lastEmailAddress && (
          <>
            <Typography variant={"caption"} py={1} component={"div"}>
              {t("Receipt-Message-1", { lastEmailAddress: lastEmailAddress })}
            </Typography>
            <Divider variant={"fullWidth"} />

            <Box onClick={() => setShow(!show)} py={1}>
              <Typography variant={"caption"} py={1} color={"text.secondary"}>
                {t("Send-Another")}
              </Typography>
            </Box>
          </>
        )}
        {!lastEmailAddress && (
          <>
            <Typography variant={"caption"} component={"div"} pb={2}>
              {t("Receipt-Message-2")}
            </Typography>
          </>
        )}

        <Collapse in={show}>
          <Box pt={2}>
            <InlineTextFieldWithSubmit
              label={t("Email-Address")}
              type={"email"}
              onSubmit={onSendEmailClicked}
              onChange={handleEmailAddressChanged}
              busy={busy}
              value={emailAddress}
              validationErrorMessage={validationError}
              buttonIcon={<Send />}
            />
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

export default ReceiptRequestPart;
