import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getOrder } from "../../api/orderApi";
import { OrderResultModel } from "../../api/gen";

export type OrderEnquiry = {
  siteId: string;
  orderId: string;
};

export const loadOrder = createAsyncThunk("orders/requestOrder", async (request: OrderEnquiry) => {
  return await getOrder(request.siteId, request.orderId);
});

export const deleteOrder = createAction("orders/delete");

export const setOrder = createAction<OrderResultModel>("orders/set");
