import { Box, Typography } from "@mui/material";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { useLocale, usePaymentMethods } from "../../../../hooks";
import { CreateOrderActions, OnApproveActions, OnApproveData } from "@paypal/paypal-js/types/components/buttons";
import { PaymentTokenType } from "../../../../api/gen";
import { useTranslation } from "react-i18next";
import "../../../../i18n";

const PayPalButton: React.FC<{
  visible: boolean;
  onTokenReceived: TokenReceivedCallback;
  amount: number;
  description: string;
  onInit: () => void;
}> = ({ visible, onTokenReceived, amount, description, onInit }) => {
  const locale = useLocale();
  const { payPalChannel } = usePaymentMethods();
  const { t } = useTranslation();

  const initialOptions = {
    "client-id": payPalChannel?.applicationId || "",
    currency: locale.currencyCode,
    intent: "authorize",
    "disable-funding": "credit,card",
  };

  const createOrder = (data: Record<string, unknown>, actions: CreateOrderActions) =>
    actions.order.create({
      intent: "AUTHORIZE",
      application_context: {
        shipping_preference: "NO_SHIPPING",
        payment_method: {
          payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
        },
      },
      purchase_units: [
        {
          amount: {
            value: amount.toString(),
            currency_code: locale.currencyCode,
          },
          description: description,
        },
      ],
    });

  const onApprove = (data: OnApproveData, actions: OnApproveActions) =>
    Promise.resolve(
      onTokenReceived({
        token: data.orderID,
        tokenMeta: { tokenType: PaymentTokenType.PayPalTransaction },
      })
    );

  return !payPalChannel ? (
    <></>
  ) : (
    <PayPalScriptProvider options={initialOptions}>
      <Box display={visible ? "flex" : "none"} alignContent="center" flexDirection="column">
        <Typography variant="body2" component="div" color="textSecondary" align="center">
          {t("Paypal-Checkout")}
        </Typography>

        <Box mt={2}>
          <PayPalButtons forceReRender={[amount]} style={{ layout: "vertical", label: "paypal" }} onInit={onInit} createOrder={createOrder} onApprove={onApprove} />
        </Box>
      </Box>
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
