/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentNotRequiredReason = {
    PaymentBypassMode: 'PaymentBypassMode',
    Loyalty: 'Loyalty',
    PrePaid: 'PrePaid',
    PromoCode: 'PromoCode',
    RemoteStart: 'RemoteStart',
    OffPlatform: 'OffPlatform'
} as const;
export type PaymentNotRequiredReason = typeof PaymentNotRequiredReason[keyof typeof PaymentNotRequiredReason];


export function instanceOfPaymentNotRequiredReason(value: any): boolean {
    for (const key in PaymentNotRequiredReason) {
        if (Object.prototype.hasOwnProperty.call(PaymentNotRequiredReason, key)) {
            if ((PaymentNotRequiredReason as Record<string, PaymentNotRequiredReason>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PaymentNotRequiredReasonFromJSON(json: any): PaymentNotRequiredReason {
    return PaymentNotRequiredReasonFromJSONTyped(json, false);
}

export function PaymentNotRequiredReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentNotRequiredReason {
    return json as PaymentNotRequiredReason;
}

export function PaymentNotRequiredReasonToJSON(value?: PaymentNotRequiredReason | null): any {
    return value as any;
}

