import { useLocale, useSiteConfig } from "../../../hooks";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TokenReceivedCallback } from "../CheckoutPage";
import { Lock } from "@mui/icons-material";
import { CardOnFileResponse, UserProfileResponse } from "../../../api/gen";
import PayButton from "./PayButton";
import { ControlMountedCallback } from "./common/controlMountedCallback";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const CardOnFile = ({ visible, onTokenReceived, onInit, userProfile, amount }: CardOnFilePropTypes) => {
  const { siteConfig } = useSiteConfig();
  const [cardOnFile, setCardOnFile] = useState<CardOnFileResponse | undefined>(undefined);
  const locale = useLocale();
  const { t } = useTranslation();

  useEffect(() => {
    if (userProfile) {
      const firstCard = userProfile.cardsOnFile.find((cof) => cof.paymentChannelType === siteConfig?.cardPaymentProvider);
      setCardOnFile(firstCard);
      onInit(!!firstCard);
    } else {
      setCardOnFile(undefined);
      onInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, siteConfig]);

  const handlePayButtonClick = () => {
    onTokenReceived({
      token: cardOnFile?.token!,
      tokenMeta: {
        tokenType: cardOnFile?.paymentTokenType!,
        cardLast4: cardOnFile?.last4!,
        email: userProfile?.emailAddress,
      },
    });
  };

  if (!userProfile) {
    return <></>;
  }

  return (
    <Box display={visible ? "block" : "none"}>
      <Box mt={3}>
        <PayButton onClick={handlePayButtonClick} amount={locale.formatCurrency(amount)} busy={false} />
      </Box>

      <Box color="text.secondary" pt={2} alignItems="center" textAlign="center">
        <div>
          <Lock />
        </div>
        <div>
          <Typography variant="caption">{t("Payment-Is-Secure")}</Typography>
        </div>
      </Box>
    </Box>
  );
};

type CardOnFilePropTypes = {
  onTokenReceived: TokenReceivedCallback;
  visible: boolean;
  onInit: ControlMountedCallback;
  userProfile?: UserProfileResponse;
  amount: number;
};

export default CardOnFile;
