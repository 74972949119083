import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PayButton from "../PayButton";
import { Box } from "@mui/material";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { PaymentTokenType } from "../../../../api/gen";
import { useLocale } from "../../../../hooks";
import { ControlMountedCallback } from "../common/controlMountedCallback";

const StripeCardEntryInternal = ({ amount, onElementMounted, onTokenReceived }: StripeCardEntryInternalPropTypes) => {
  const [busy, setBusy] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const locale = useLocale();

  const handleReady = () => {
    console.debug("Stripe card element ready");
    onElementMounted &&
    onElementMounted(true);
  };

  const handlePaymentClick = async () => {
    setBusy(true);

    try {
      const result = await stripe?.createToken(elements?.getElement(CardElement)!);

      console.debug("Stripe token result", result);

      if (result?.token) {
        onTokenReceived({ token: result.token.id, tokenMeta: { tokenType: PaymentTokenType.StripeNonce, cardLast4: result?.token?.card?.last4 } });
      } else {
        console.error("Error received from Stripe", result?.error);
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <>
      <Box p={2} mb={2} borderRadius="4px" border={1} borderColor={"divider"} bgcolor={"background.paper"}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "primary.main",
                backgroundColor: "background.paper",
                "::placeholder": {
                  color: "#999"
                }
              },
              invalid: {
                iconColor: "error.dark",
                color: "error.main"
              }
            }
          }}
          onReady={handleReady}
        />
      </Box>
      <PayButton onClick={handlePaymentClick} amount={locale.formatCurrency(amount)} busy={busy} />
    </>
  );
};

type StripeCardEntryInternalPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  onElementMounted: ControlMountedCallback;
  amount: number;
};

export default StripeCardEntryInternal;
