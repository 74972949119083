import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import ItemWithPriceLine from "../../common/itemised/ItemWithPriceLine";
import { PromotionCodeResolutionResult, QuoteResponseModel } from "../../../api/gen";
import Link from "@mui/material/Link";
import { Locale } from "../../../models/locale";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const TotalsPanel: React.FC<{ promoCode?: string; quote?: QuoteResponseModel; locale: Locale; transactionFeeLabel: string; onPromotionCodeDeleted: () => void }> = (props) => {
  const { t } = useTranslation();

  if (!props.quote) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h6" component="div">
        {t("Totals")}
      </Typography>

      <ItemWithPriceLine amount={props.locale.formatCurrency(props.quote.amounts.totalVendAmount ?? 0)} label={t("Sub-Total")} />

      {props.quote?.amounts && (
        <Box mb={1} sx={{ "& .MuiBox-root": { py: 0.25 } }}>
          {props.quote.amounts.siteDiscount > 0 && (
            <ItemWithPriceLine
              amount={props.locale.formatCurrency(props.quote.amounts.siteDiscount * -1)}
              label={t("Store-Discount")}
              textColor={"text.secondary"}
              textVariant={"body2"}
            />
          )}

          {props.quote.promotion.promotionCodeResult === PromotionCodeResolutionResult.Success && (
            <ItemWithPriceLine
              amount={props.quote.amounts.promoDiscount > 0 ? props.locale.formatCurrency(props.quote.amounts.promoDiscount * -1) : undefined}
              label={props.quote.promotion.name}
              textColor={"text.secondary"}
              textVariant={"body2"}
              caption={`Using code '${props.promoCode}'`}
              captionVariant={"caption"}
              action={
                <Link onClick={props.onPromotionCodeDeleted} variant={"caption"}>
                  {t("Remove")}
                </Link>
              }
            />
          )}

          {props.quote.loyalty && (
            <ItemWithPriceLine
              amount={props.quote.amounts.loyaltyDiscount > 0 ? props.locale.formatCurrency(props.quote.amounts.loyaltyDiscount * -1) : undefined}
              label={props.quote.loyalty.name}
              textColor={"text.secondary"}
              textVariant={"body2"}
              caption={t("Using-Code", { code: props.promoCode })}
              captionVariant={"caption"}
              action={
                <Link onClick={props.onPromotionCodeDeleted} variant={"caption"}>
                  {t("Remove")}
                </Link>
              }
            />
          )}

          {props.quote.amounts.totalFee > 0 && (
            <ItemWithPriceLine
              amount={props.locale.formatCurrency(props.quote.amounts.totalFee)}
              label={props.transactionFeeLabel}
              textColor={"text.secondary"}
              textVariant={"body2"}
            />
          )}

          <Divider variant="fullWidth" sx={{ my: 1 }} />
          <ItemWithPriceLine amount={props.locale.formatCurrency(props.quote.amounts.grandTotal ?? 0)} label={t("Amount-To-Pay")} labelWeight={"bold"} amountWeight={"bold"} />
        </Box>
      )}
    </>
  );
};

export default TotalsPanel;
