/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineCreditResponse
 */
export interface MachineCreditResponse {
    /**
     * 
     * @type {number}
     * @memberof MachineCreditResponse
     */
    vendAmount: number;
    /**
     * 
     * @type {string}
     * @memberof MachineCreditResponse
     */
    machineId: string;
}

/**
 * Check if a given object implements the MachineCreditResponse interface.
 */
export function instanceOfMachineCreditResponse(value: object): value is MachineCreditResponse {
    if (!('vendAmount' in value) || value['vendAmount'] === undefined) return false;
    if (!('machineId' in value) || value['machineId'] === undefined) return false;
    return true;
}

export function MachineCreditResponseFromJSON(json: any): MachineCreditResponse {
    return MachineCreditResponseFromJSONTyped(json, false);
}

export function MachineCreditResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineCreditResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'vendAmount': json['vendAmount'],
        'machineId': json['machineId'],
    };
}

export function MachineCreditResponseToJSON(value?: MachineCreditResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vendAmount': value['vendAmount'],
        'machineId': value['machineId'],
    };
}

