import { handleResponse, logResponse } from "./apiUtils";

export interface ApplePaySessionApi {
  /**
   * @param {string} validationUrl the URL to where the Apple Pay session will be verified and created
   * @return {Promise<any>} a promise that contains the opaque Apple Pay session
   */
  startSession(validationUrl: string): Promise<any>;
}

export class DefaultApplePaySessionApi implements ApplePaySessionApi {
  private readonly sessionApiUrl = "/api/applePayVerification";
  private readonly merchantId: string;

  constructor(merchantId: string) {
    this.merchantId = merchantId;
  }

  public startSession(validationUrl: string) {
    const body = JSON.stringify({ merchantId: this.merchantId, url: validationUrl });

    console.info("Sending to Apple Pay Session API " + body);

    return fetch(this.sessionApiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    })
      .then(handleResponse)
      .then(logResponse);
  }
}
