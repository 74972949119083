import React from "react";
import { Box, Link, Typography } from "@mui/material";
import logo_brand from "../../assets/tangerpay-brand.svg";
import DialogWithTitle from "../common/dialog/DialogWithTitle";
import ReactGA from "react-ga4";
import "../../App.css";
import "../../i18n";
import { useTranslation, Trans } from "react-i18next";

const AboutDialog = ({ open, onClose, storeName }: AboutDialogPropTypes) => {
  const { t } = useTranslation();

  if (open) {
    ReactGA.event({
      category: "Hamburger",
      action: "Show About page",
    });
  }
  return (
    <DialogWithTitle open={open} onClose={onClose} title={t("About")}>
      <Box className={"gradient-background"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"}>
        <Box mt={8} component={"div"} width={"100%"} py={4} textAlign={"center"}>
          <img src={logo_brand} alt="Tangerpay" height="52px" />
        </Box>
        <Box component={"div"} flexDirection={"column"} alignItems={"center"} mx={4}>
          <Typography variant={"body2"} color={"text.primary"} component={"div"} sx={{ mb: 4 }}>
            <strong>{storeName}</strong>
            {t("About-Message-1")}
          </Typography>

          <Typography variant={"body2"} color={"text.primary"} component={"div"}>
            <Trans i18nKey="About-Message-2" components={{ 1: <Link href={"https://tangerpay.com"} color={"secondary"} target={"_blank"} /> }} />
          </Typography>
        </Box>
        <Box mb={2} display={"flex"} flexDirection={"column"} alignItems={"center"}>
          {/*<div>*/}
          {/*  <img src={logo_brand} alt="Tangerpay" height="40px" />*/}
          {/*</div>*/}
          <Typography variant={"caption"} component={"div"} color={"text.primary"} fontSize={"0.6rem"} pt={2}>
            &copy; Tangerpay 2022. {t("Copyright")} ABN 15 639 086 258.
          </Typography>
          <Typography variant={"caption"} component={"div"} color={"text.secondary"} sx={{ mt: 2 }} fontSize={"0.5rem"}>
            {t("Version")} __Meta.Version__.
          </Typography>
        </Box>
      </Box>
    </DialogWithTitle>
  );
};

type AboutDialogPropTypes = {
  storeName: string;
  onClose: () => void;
  open: boolean;
};

export default AboutDialog;
