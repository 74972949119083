import { LocalSettingsService } from "../service/localSettingsService";
import { Cart, Token } from "../models";
import { FeedbackType, OrdersApi } from "./gen";
import { defaultConfiguration } from "./apiUtils";

const ORDERS_API = new OrdersApi(defaultConfiguration);

export async function createOrder(cart: Cart, token: Token, siteId: string, donation: any, sessionContext: any, orderId?: string) {
  console.info("Create order from cart and token", cart, token);
  return ORDERS_API.ordersMakeOrder({
    order: {
      orderId: orderId,
      siteId: siteId,
      paymentTokenType: token.tokenMeta.tokenType,
      paymentToken: token.token,
      promoCode: cart.promoCode,
      cardLastFour: token.tokenMeta.cardLast4,
      emailAddress: token.tokenMeta.email,
      digitalWalletType: token.tokenMeta.digitalWalletType,
      machineCredits: cart.machineCredits.map((i) => ({ machineId: i.machine.id, vendAmount: i.amount })),
      userProfileId: LocalSettingsService.getId() || undefined,
      referrer: sessionContext.referrer,
      phoneNumber: sessionContext.phoneNumber,
      cardholderName: token.tokenMeta.cardholderName,
      zipCode: token.tokenMeta.zipCode,
      rememberCardDetails: token.tokenMeta.rememberCard || false
    }
  }).catch((r: Response) => {
    if (r.status === 422) {
      return r.json();
    }
  });
}

export const getOrder = async (siteId: string, orderId: string) => {
  console.info("Loading order " + orderId + " from site " + siteId);

  return ORDERS_API.ordersGetOrder({
    orderId: orderId,
    siteId: siteId
  });
};

export const addFeedback = async (orderId: string, feedbackType: FeedbackType, comments: string) => {
  console.info("Adding feedback to order " + orderId + " of type " + feedbackType + " with comment " + comments);

  return ORDERS_API.ordersCreateUpdateFeedback({
    id: orderId,
    feedbackModel: {
      type: feedbackType,
      comments: comments
    }
  });
};

export async function sendReceipt(orderId: string, emailAddress: string) {
  return ORDERS_API.ordersCreateReceipt({
    orderId: orderId,
    receiptRequest: {
      emailAddress: emailAddress
    }
  });
}

/**
 * Set a reminder for an order.
 * @param {string} siteId - the site ID
 * @param {orderId} orderId - the uuid order ID
 * @param {phoneNumber} phoneNumber - the phone number to send a reminder to
 * @returns {Promise<ReminderResult>} - a ReminderResult object with information on whether the request succeeded or not
 */
export const setReminder = async (siteId: string, orderId: string, phoneNumber: string) => {
  return ORDERS_API.ordersCreateReminder({
    reminderRequest: {
      reminderPhoneNumber: phoneNumber
    },
    orderId: orderId
  });
};
