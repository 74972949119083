import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createOrder } from "../../api/orderApi";
import { CheckoutView } from "./CheckoutView";
import { deleteQuote, requestQuote } from "../../redux/actions/quoteActions";
import ReactGA from "react-ga4";
import MachineStatusChecker from "./parts/MachineStatusChecker";
import { Machine, OrderResultModel, PaymentChannelIdentifier, PaymentStatus, PromotionCodeResolutionResult } from "../../api/gen";
import { useAppSelector } from "../../redux/hooks";
import { useLocale, usePaymentMethods, useSiteConfig } from "../../hooks";
import { Cart, DigitalWalletType, PaymentOption, Token } from "../../models";
import QuoteErrorView from "./parts/QuoteErrorView";
import { setOrder } from "../../redux/actions/orderActions";
import { LocalSettingsService } from "../../service/localSettingsService";
import { createNewProfile, updateProfile } from "../../redux/actions/userProfileActions";
import ThreeDSecureChallenge from "./components/till/3ds/ThreeDSecureChallenge";
import { CartUrlParamsSerialiser } from "../../utils/cartUrlParamsSerialiser";
import { addPromoCode, deletePromoCode, replaceCart } from "../../redux/actions/cartActions";
import { ApplePayHelper } from "./components/common/applePay";
import { PromoCodeValidationResult } from "./promo/PromoButton";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import "../../i18n";

const dispatchOrder = async (cart: Cart, token: Token, siteId: string, donation: any, sessionContext: any, orderId?: string) => {
  try {
    return await createOrder(cart, token, siteId, donation, sessionContext, orderId);
  } catch (ex) {
    console.error(ex);
  }
};

function createCartFromUrlParams(machines: Array<Machine>, urlParams: string, siteId: string): Cart {
  const cart = CartUrlParamsSerialiser.fromUrlParams(urlParams, machines);

  return { ...cart, promoCode: cart.promoCode || LocalSettingsService.forSite(siteId).savedPromoCode };
}

const errorMessageFromPromotionCodeResult = (t: TFunction, result?: PromotionCodeResolutionResult): string => {
  switch (result) {
    case PromotionCodeResolutionResult.PolicyDecline:
      return t("Code-Not-Available");
    case PromotionCodeResolutionResult.NotFound:
      return t("Code-Not-Found");
    case PromotionCodeResolutionResult.NotActive:
      return t("Code-Not-Active");
    default:
      return "";
  }
};

const CheckoutPage = () => {
  const cart = useAppSelector((state) => state.cart);
  const sessionContext = useAppSelector((state) => state.sessionContext);
  const quote = useAppSelector((state) => state.quote);
  const locale = useLocale();
  const { userProfile } = useAppSelector((state) => state.userProfile);
  const paymentMethods = usePaymentMethods();
  const { siteConfig } = useSiteConfig();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [showMachineCheck, setShowMachineCheck] = useState(true);
  const [threeDSecureChallengeUrl, setThreeDSecureChallengeUrl] = useState("");
  const [orderId, setOrderId] = useState<string>();
  const [stagedToken, setStagedToken] = useState<Token>();
  const [selectedPaymentChannelType, setSelectedPaymentChannelType] = useState<PaymentChannelIdentifier | undefined>();
  const [digitalWalletType, setDigitalWalletType] = useState<DigitalWalletType>();

  // What's this for? Basically:
  // * In Redux (and also React state), items are immutable. When something changes, a new instance is returned. That's good.
  // * Every change in state - either through props or useState, causes this main function to execute. That's OK too
  // * This function contains some callbacks as closures. When a token is received from one of the payment handlers, it creates the order using the "cart" that is in the closure's state - passed in above. Good.
  // * Each closure has its own reference to cart. When the function is set up in the initial page render, the carts are all lined up. The state of the cart and the state of the closure all line up.
  // * Then the user adds a promo code, which updates the Redux cart, which sends a notification here. It re-renders, but the function with the callback still has a reference to the OLD CART!
  // * So, when the user pays then this outer part of the code is correct, but the inner callback has the reference to the stale cart and fails to send the right promo code to the Orders API. OOPS!
  // * useRef() helps us get around it by capturing the current value, and allowing access from within the closures. See the SO article.
  // see https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const stateRef = useRef(cart);
  stateRef.current = cart;

  const navigate = useNavigate();

  useEffect(() => {
    if (siteConfig) {
      const savedPromoCode = LocalSettingsService.forSite(siteConfig.siteId)?.savedPromoCode;

      // only dispatch if there is a promo code in local storage, otherwise just ignore it.
      if (savedPromoCode) {
        console.info(`Adding previously saved promo code ${savedPromoCode} to cart`);
        dispatch(addPromoCode(savedPromoCode));
      }
    }
  }, [dispatch, siteConfig]);

  useEffect(() => {
    if (!siteConfig) {
      return;
    }

    // if the cart is empty and we are here, then try and load state from the URL params - it might be a Kiosk handover
    if (cart.machineCredits.length === 0) {
      console.info("Build a cart from URL params");
      const cartFromUrl = createCartFromUrlParams(siteConfig.machines, window.location.search, siteConfig.siteId);

      // If the URL parse picked up a machine the great, update the cart. Otherwise, we've followed a link to a dead machine.
      if (cartFromUrl.machineCredits.length > 0) {
        const newCart = { ...cart, ...cartFromUrl } as Cart;
        dispatch(replaceCart(newCart));
      } else {
        navigate(`/${siteConfig.siteId}/machineNotFound${window.location.search}`);
      }
    }
  }, [cart, dispatch, navigate, siteConfig]);

  // Generate the quote
  useEffect(() => {
    // only get a quote if none of these are true:
    // - There are no machines to credit;
    // - The site config hasn't loaded yet;
    // - There is a per-gateway pricing configuration set but there is no selected payment channel and there are some payment channels to select from (whew!)
    if (
      cart.machineCredits.length === 0 ||
      !siteConfig ||
      (siteConfig.isProcessingFeeDependentOnSelectedPaymentGateway && !selectedPaymentChannelType && paymentMethods.isAnyMethodAvailable)
    ) {
      console.info(`Requesting quote for cart rejected ${selectedPaymentChannelType} / ${siteConfig?.isProcessingFeeDependentOnSelectedPaymentGateway}`);
      return;
    }

    console.info("Requesting quote for cart", cart);
    window.scrollTo(0, 0); // jump back to top of the page

    dispatch(
      requestQuote({
        siteId: siteConfig.siteId,
        cart: cart,
        paymentChannelType: selectedPaymentChannelType,
      })
    );
  }, [dispatch, cart, siteConfig, selectedPaymentChannelType, paymentMethods.isAnyMethodAvailable]); // recalculates the quote if the cart changes (e.g., a promo code is added, a donation is added, the payment option changes, etc.)

  // check whether Apple Pay is available. If it is, use it and assume it will work for Stripe (etc.). Otherwise, Google Pay is it - and Google Pay works everywhere.
  useEffect(() => {
    if (paymentMethods.applePaySettings?.merchantId) {
      ApplePayHelper.checkApplePayAvailable(paymentMethods.applePaySettings?.merchantId).then((result) => {
        let walletType = result ? DigitalWalletType.APPLE_PAY : DigitalWalletType.GOOGLE_PAY;
        console.info(`Setting digital wallet type to ${walletType}`);
        setDigitalWalletType(walletType);
      });
    } else {
      console.info(`Setting digital wallet type to ${DigitalWalletType.GOOGLE_PAY} because there is no Apple merchant ID set`);
      setDigitalWalletType(DigitalWalletType.GOOGLE_PAY);
    }
  }, [paymentMethods.applePaySettings?.merchantId]);

  const handleDigitalWalletTypeChanged = (type: DigitalWalletType) => {
    console.info(`Changing digital wallet type to ${type}`);
    setDigitalWalletType(type);
  };

  // this actually processes the order
  const handleTokenReceived = (token: Token) => {
    setIsProcessingPayment(true);

    if (!siteConfig) {
      return;
    }

    const handleResult = (result: OrderResultModel | undefined) => {
      setIsProcessingPayment(false);
      if (!result) {
        return;
      }

      if (result.isError && result.paymentStatus === PaymentStatus.Declined) {
        setOrderId(undefined);
        setPaymentErrorMessage(result.errorMessageText || t("Payment-Declined"));
        window.addEventListener("click", () => setPaymentErrorMessage(""));
      } else if (result.paymentStatus === PaymentStatus.AuthenticationRequired) {
        console.log("3DS challenge issued. Challenge URL is " + result.authenticationRedirectUrl);
        setOrderId(result.orderId);
        setStagedToken(token);
        setThreeDSecureChallengeUrl(result.authenticationRedirectUrl || "");
      } else {
        dispatch(setOrder(result));
        navigate(`/${siteConfig.siteId}/${result.orderId}/receipt`);

        if (result.cardOnFile) {
          LocalSettingsService.setPreferredPaymentMethod(PaymentOption.CARD_ON_FILE);
        }

        if (!userProfile) {
          dispatch(
            createNewProfile({
              cardOnFile: result.cardOnFile,
              emailAddress: result.emailAddress,
              profileId: LocalSettingsService.getId()!,
            })
          );
        } else {
          // update the profile if there is a new card on file token to add, or if the email address has changed
          if (userProfile && (result.cardOnFile || (result.emailAddress && userProfile?.emailAddress !== result.emailAddress))) {
            dispatch(
              updateProfile({
                profileId: userProfile?.id,
                emailAddress: result.emailAddress,
                cardOnFile: result.cardOnFile,
              })
            );
          }
        }
      }
    };

    dispatchOrder(stateRef.current, token, siteConfig.siteId, null, sessionContext, orderId)
      .then(handleResult)
      .catch(() => {
        setIsProcessingPayment(false);
      });
  };

  const handleBackButtonClicked = () => {
    if (!siteConfig) {
      return;
    }

    const lastMachineCredit = cart.machineCredits[cart.machineCredits.length - 1].machine;
    dispatch(deleteQuote());
    navigate(`/${siteConfig.siteId}/zones/${lastMachineCredit.zoneId}/machines?t=${lastMachineCredit.type}&m=${lastMachineCredit.id}`);
  };

  const handleMachineTestSuccess = () => {
    setTimeout(() => {
      setShowMachineCheck(false);
    }, 1000); // hold for a second before dismissing
  };

  const isLoadingPage = quote.isLoading;

  isLoadingPage &&
    ReactGA.event({
      category: "Checkout",
      action: "Checkout screen shown",
    });

  const handle3dsError = () => {
    setThreeDSecureChallengeUrl("");
    setOrderId(undefined);
    setPaymentErrorMessage(t("Payment-Declined-Issuer"));
  };

  const handle3dsCancel = () => {
    setThreeDSecureChallengeUrl("");
    setOrderId(undefined);
    setPaymentErrorMessage(t("Payment-Cancelled"));
  };

  const handle3dsTokenReceived = (token: string) => {
    setPaymentErrorMessage("");
    setThreeDSecureChallengeUrl("");
    handleTokenReceived({ ...stagedToken, token: token } as Token);
  };

  const handlePromoCodeApplied = (promoCode: string) => {
    dispatch(addPromoCode(promoCode));
  };

  const handlePromoCodeRemoved = () => {
    LocalSettingsService.setSavedCode(siteConfig!.siteId, null);
    dispatch(deletePromoCode());
  };

  // Some sites have per-channel pricing. This callback deals with that, but only if the site is configured in that way.
  const handlePaymentOptionChanged = (paymentOption: PaymentOption) => {
    if (siteConfig?.isProcessingFeeDependentOnSelectedPaymentGateway) {
      const optionsToChannels = new Map<PaymentOption, PaymentChannelIdentifier>([
        [PaymentOption.DIGITAL_WALLET, siteConfig.digitalWalletProvider],
        [PaymentOption.CARD, siteConfig.cardPaymentProvider],
        [PaymentOption.CARD_ON_FILE, siteConfig.cardPaymentProvider],
        [PaymentOption.PAYPAL, PaymentChannelIdentifier.PayPal],
      ]);
      const newPaymentChannel = optionsToChannels.get(paymentOption);

      console.info(`Site has per-gateway charging and the payment type changed from ${selectedPaymentChannelType} to ${newPaymentChannel}`);

      setSelectedPaymentChannelType(newPaymentChannel);
    }
  };

  const promoCodeValidationResult = {
    error:
      !quote?.quote?.loyalty &&
      (quote?.quote?.promotion.promotionCodeResult === PromotionCodeResolutionResult.NotActive ||
        quote?.quote?.promotion.promotionCodeResult === PromotionCodeResolutionResult.NotFound ||
        quote?.quote?.promotion.promotionCodeResult === PromotionCodeResolutionResult.PolicyDecline),
    name: quote?.quote?.promotion.name,
    errorMessage: errorMessageFromPromotionCodeResult(t, quote?.quote?.promotion.promotionCodeResult),
    discount: locale.formatCurrency(quote?.quote?.amounts.promoDiscount ?? 0),
  } as PromoCodeValidationResult;

  const transactionFeeLabel = siteConfig?.transactionFeeLabel ?? "Service fee";
  const translatedTransactionFeeLabel = transactionFeeLabel === "Platform Fee" ? t("Platform-Fee") : transactionFeeLabel === "Service Fee" ? t("Service-Fee") : transactionFeeLabel;

  return (
    <>
      <ThreeDSecureChallenge
        challengeUrl={threeDSecureChallengeUrl}
        open={!!threeDSecureChallengeUrl}
        onSuccess={handle3dsTokenReceived}
        onError={handle3dsError}
        onCancel={handle3dsCancel}
      />
      <QuoteErrorView showDialog={quote.isError} siteId={siteConfig?.siteId ?? ""} />

      {cart.machineCredits.length > 0 && (
        <MachineStatusChecker
          siteId={siteConfig?.siteId ?? ""}
          machines={cart.machineCredits.map((mc) => mc.machine)}
          show={showMachineCheck}
          onBackToSelection={handleBackButtonClicked}
          onMachineTestingSuccess={handleMachineTestSuccess}
        />
      )}

      <CheckoutView
        // only show content once everything has finished loading and the machines have been checked
        showContent={!showMachineCheck && !isProcessingPayment && !quote.isError && !isLoadingPage}
        // Shown the loading page spinner only if the machines are checked, but we're still waiting for something else (e.g., detection of Apple Pay)
        showCalculatingSpinner={!showMachineCheck && isLoadingPage && !quote.isError}
        showProcessingPaymentSpinner={isProcessingPayment}
        paymentErrorMessage={paymentErrorMessage}
        onBackToMachineSelectionClicked={handleBackButtonClicked}
        onTokenReceived={handleTokenReceived}
        onPaymentOptionChanged={handlePaymentOptionChanged}
        digitalWalletType={digitalWalletType}
        onDigitalWalletChanged={handleDigitalWalletTypeChanged}
        promoCodeValidationResult={promoCodeValidationResult}
        promoCode={cart.promoCode}
        onPromoCodeApplied={handlePromoCodeApplied}
        onPromoCodeRemoved={handlePromoCodeRemoved}
        quote={quote?.quote}
        locale={locale}
        transactionFeeLabel={translatedTransactionFeeLabel}
      />
    </>
  );
};

export type TokenReceivedCallback = (token: Token) => void;

export type DigitalWalletTypeChangedEvent = (type: DigitalWalletType) => void;

export default CheckoutPage;
