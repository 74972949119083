import { CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

const InlineTextFieldWithSubmit = ({
                                     busy = false,
                                     validationErrorMessage,
                                     onSubmit,
                                     onChange,
                                     label,
                                     type,
                                     value,
                                     buttonIcon,
                                     disabled = false
                                   }: InlineTextFieldWithSubmitPropTypes) => {

  const handleSubmit = () => {
    if (!busy && !disabled) {
      onSubmit(value);
    }
  }

  return (
    <TextField
      variant={"outlined"}
      label={label}
      type={type}
      error={!!validationErrorMessage}
      value={value}
      helperText={validationErrorMessage}
      disabled={busy || disabled}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" color={"primary"}>
            {/*<Button variant={"contained"} endIcon={busy ? (<CircularProgress />) : (<Send />)}>*/}
            {/*</Button>*/}
            <IconButton onClick={handleSubmit} edge="end" color="primary" disabled={disabled}>
              {busy ? (<CircularProgress />) : (buttonIcon)}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

type InlineTextFieldWithSubmitPropTypes = {
  value: string,
  busy: boolean,
  disabled?: boolean,
  label: string,
  type: string,
  validationErrorMessage?: string,
  onSubmit: (value: string) => void,
  onChange: (value: string) => void,
  buttonIcon: any
};

export default InlineTextFieldWithSubmit;