import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import StripeCardEntryInternal from "./StripeCardEntryInternal";
import { getStripe } from "./stripeSdkProvider";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { ControlMountedCallback } from "../common/controlMountedCallback";

// https://stripe.com/docs/api
// https://stripe.com/docs/js/element/other_element?type=card
// https://stripe.com/docs/stripe-js/react

const StripeCardEntry = ({ amount, onElementMounted, onTokenReceived }: StripeCardEntryPropTypes) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    getStripe().then((s) => setStripe(s));
  }, []);

  if (!stripe) {
    return <></>;
  } else {
    return (
      <Elements stripe={stripe}>
        <StripeCardEntryInternal onElementMounted={onElementMounted} onTokenReceived={onTokenReceived} amount={amount} />
      </Elements>
    );
  }
};

type StripeCardEntryPropTypes = {
  onTokenReceived: TokenReceivedCallback,
  onElementMounted: ControlMountedCallback,
  amount: number,
};

export default StripeCardEntry;
