/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CardOnFileRequest } from './CardOnFileRequest';
import {
    CardOnFileRequestFromJSON,
    CardOnFileRequestFromJSONTyped,
    CardOnFileRequestToJSON,
} from './CardOnFileRequest';

/**
 * 
 * @export
 * @interface CreateUserProfileRequest
 */
export interface CreateUserProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserProfileRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProfileRequest
     */
    emailAddress?: string;
    /**
     * 
     * @type {CardOnFileRequest}
     * @memberof CreateUserProfileRequest
     */
    cardOnFile?: CardOnFileRequest;
}

/**
 * Check if a given object implements the CreateUserProfileRequest interface.
 */
export function instanceOfCreateUserProfileRequest(value: object): value is CreateUserProfileRequest {
    return true;
}

export function CreateUserProfileRequestFromJSON(json: any): CreateUserProfileRequest {
    return CreateUserProfileRequestFromJSONTyped(json, false);
}

export function CreateUserProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserProfileRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'cardOnFile': json['cardOnFile'] == null ? undefined : CardOnFileRequestFromJSON(json['cardOnFile']),
    };
}

export function CreateUserProfileRequestToJSON(value?: CreateUserProfileRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'emailAddress': value['emailAddress'],
        'cardOnFile': CardOnFileRequestToJSON(value['cardOnFile']),
    };
}

