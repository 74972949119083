import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import logo from "../../assets/tangerpay-logo.svg";
import "../../App.css";
import { useTranslation } from "react-i18next";
import "../../i18n";

const LoadingSplash = () => {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} height={"100vh"} width={"100%"} className={"gradient-background"}>
      <div>
        <motion.img
          key="logo_spinner"
          src={logo}
          alt="Tangerpay"
          width="150px"
          height="150px"
          // animate={{ rotate: 360 }}
          animate={{
            scale: [1, 1.5, 1.5, 1],
            rotate: [0, 360, 360, 0],
          }}
          transition={{ duration: 2, repeat: Infinity, repeatDelay: 0.5 }}
        />
        <Typography zIndex={99} variant={"h5"} component={"div"} fontFamily={"Quicksand"} color={"white"}>
          {t("Spinning-Message")}
        </Typography>
      </div>
    </Box>
  );
};

export default LoadingSplash;
