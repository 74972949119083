import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../i18n";

type RememberMyCardPropTypes = {
  checked: boolean;
  duration?: "today" | "untilExpired";
  disabled: boolean;
  onChange: () => void;
};

const RememberMyCard = ({ checked, onChange, disabled, duration = "today" }: RememberMyCardPropTypes) => {
  const { t } = useTranslation();

  let reminderText = t("Remember-Card-Today");
  if (duration === "untilExpired") {
    reminderText = t("Remember-Card-Next-Time");
  }

  return (
    <FormGroup sx={{ mt: 2 }}>
      <FormControlLabel control={<Switch size={"small"} checked={checked} onChange={onChange} />} label={reminderText} disabled={disabled} sx={{ ml: 0 }} />

      <Typography variant={"caption"} color={"text.secondary"}>
        {!disabled && <>{t("Remember-Card-Message-1")}</>}
        {disabled && <>{t("Remember-Card-Message-2")}</>}
      </Typography>
    </FormGroup>
  );
};

export default RememberMyCard;
