import { SiteConfig } from "../../api/gen";
import { createReducer } from "@reduxjs/toolkit";
import { loadSiteConfig } from "../actions/siteConfigActions";

const initialState = {
  isLoading: false,
  isError: false
} as SiteConfigModelWrapper;

const siteConfigReducer = createReducer(initialState, (builder) => {
  builder.addCase(loadSiteConfig.fulfilled, (state, action) => {
    state.siteConfig = action.payload;
    state.isLoading = false;
    state.isError = false;
  }).addCase(loadSiteConfig.pending, (state, action) => {
    state.isLoading = true;
    state.isError = false;
  }).addCase(loadSiteConfig.rejected, (state, action) => {
    state.isLoading = true;
    state.isError = true;
  });
});

export type SiteConfigModelWrapper = {
  isLoading: boolean;
  isError: boolean;
  siteConfig?: SiteConfig
}

export default siteConfigReducer;
