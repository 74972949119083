import { Elements } from "@stripe/react-stripe-js";
import StripePaymentRequestInternal from "./StripePaymentRequestInternal";
import { useEffect, useState } from "react";
import StripeGooglePayButton from "./StripeGooglePayButton";
import { getStripe } from "./stripeSdkProvider";
import { DigitalWalletTypeChangedEvent, TokenReceivedCallback } from "../../CheckoutPage";
import { DigitalWalletType } from "../../../../models";

const StripePaymentRequest = ({ amount, onTokenReceived, onDigitalWalletChanged }: StripePaymentRequestPropTypes) => {
  const [useStripePaymentRequest, setUseStripePaymentRequest] = useState(true);
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    getStripe().then((s) => setStripe(s));
  }, []);

  const onStripePaymentRequestButtonMounted = (mounted: boolean, type?: DigitalWalletType) => {
    if (!mounted || !type) {
      console.debug("Stripe button did not mount, will try direct Google Pay button instead");
      setUseStripePaymentRequest(false);
      onDigitalWalletChanged(DigitalWalletType.GOOGLE_PAY);
    } else {
      console.debug("Stripe button mounted, using Stripe Payment Request API");
      onDigitalWalletChanged(type);
    }
  };

  if (!stripe) {
    return <></>;
  } else if (useStripePaymentRequest) {
    return (
      <Elements stripe={stripe}>
        <StripePaymentRequestInternal amount={amount} onTokenReceived={onTokenReceived} onButtonMounted={onStripePaymentRequestButtonMounted} />
      </Elements>
    );
  } else {
    return <StripeGooglePayButton onTokenReceived={onTokenReceived} amount={amount} />;
  }
};

type StripePaymentRequestPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  amount: number;
  onDigitalWalletChanged: DigitalWalletTypeChangedEvent;
};

export type OnButtonMounted = (mounted: boolean, type?: DigitalWalletType) => void;

export default StripePaymentRequest;
