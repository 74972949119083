/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CardOnFileResponse } from './CardOnFileResponse';
import {
    CardOnFileResponseFromJSON,
    CardOnFileResponseFromJSONTyped,
    CardOnFileResponseToJSON,
} from './CardOnFileResponse';

/**
 * 
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {Array<CardOnFileResponse>}
     * @memberof UserProfileResponse
     */
    cardsOnFile: Array<CardOnFileResponse>;
}

/**
 * Check if a given object implements the UserProfileResponse interface.
 */
export function instanceOfUserProfileResponse(value: object): value is UserProfileResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('cardsOnFile' in value) || value['cardsOnFile'] === undefined) return false;
    return true;
}

export function UserProfileResponseFromJSON(json: any): UserProfileResponse {
    return UserProfileResponseFromJSONTyped(json, false);
}

export function UserProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'cardsOnFile': ((json['cardsOnFile'] as Array<any>).map(CardOnFileResponseFromJSON)),
    };
}

export function UserProfileResponseToJSON(value?: UserProfileResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'emailAddress': value['emailAddress'],
        'cardsOnFile': ((value['cardsOnFile'] as Array<any>).map(CardOnFileResponseToJSON)),
    };
}

