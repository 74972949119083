/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MachineOptionsMode } from './MachineOptionsMode';
import {
    MachineOptionsModeFromJSON,
    MachineOptionsModeFromJSONTyped,
    MachineOptionsModeToJSON,
} from './MachineOptionsMode';
import type { MachineOption } from './MachineOption';
import {
    MachineOptionFromJSON,
    MachineOptionFromJSONTyped,
    MachineOptionToJSON,
} from './MachineOption';

/**
 * 
 * @export
 * @interface MachineOptionGroup
 */
export interface MachineOptionGroup {
    /**
     * 
     * @type {string}
     * @memberof MachineOptionGroup
     */
    name?: string;
    /**
     * 
     * @type {MachineOptionsMode}
     * @memberof MachineOptionGroup
     */
    machineOptionsMode: MachineOptionsMode;
    /**
     * 
     * @type {boolean}
     * @memberof MachineOptionGroup
     */
    machineOptionSelectionRequired: boolean;
    /**
     * 
     * @type {Array<MachineOption>}
     * @memberof MachineOptionGroup
     */
    machineOptions: Array<MachineOption>;
}

/**
 * Check if a given object implements the MachineOptionGroup interface.
 */
export function instanceOfMachineOptionGroup(value: object): value is MachineOptionGroup {
    if (!('machineOptionsMode' in value) || value['machineOptionsMode'] === undefined) return false;
    if (!('machineOptionSelectionRequired' in value) || value['machineOptionSelectionRequired'] === undefined) return false;
    if (!('machineOptions' in value) || value['machineOptions'] === undefined) return false;
    return true;
}

export function MachineOptionGroupFromJSON(json: any): MachineOptionGroup {
    return MachineOptionGroupFromJSONTyped(json, false);
}

export function MachineOptionGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineOptionGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'machineOptionsMode': MachineOptionsModeFromJSON(json['machineOptionsMode']),
        'machineOptionSelectionRequired': json['machineOptionSelectionRequired'],
        'machineOptions': ((json['machineOptions'] as Array<any>).map(MachineOptionFromJSON)),
    };
}

export function MachineOptionGroupToJSON(value?: MachineOptionGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'machineOptionsMode': MachineOptionsModeToJSON(value['machineOptionsMode']),
        'machineOptionSelectionRequired': value['machineOptionSelectionRequired'],
        'machineOptions': ((value['machineOptions'] as Array<any>).map(MachineOptionToJSON)),
    };
}

