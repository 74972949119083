import { Alert, AlertTitle, Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import DialogWithTitle from "../../common/dialog/DialogWithTitle";
import { isIOS, isMobileSafari, isSafari } from "react-device-detect";
import { DigitalWalletType, PaymentOption } from "../../../models";
import { CardOnFileResponse } from "../../../api/gen";
import { CardAcceptanceMarks } from "../components/common/CardAcceptanceMarks";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import RememberedCardLabel from "../parts/RememberedCardLabel";
import PaymentTypeLabelFactory from "./PaymentTypeLabelFactory";

const isPaymentOptionAvailable = (option: PaymentOption, paymentOptions: Array<PaymentOption>) => paymentOptions.indexOf(option) >= 0;

const PaymentTypeSelectDialog: React.FC<{
  open: boolean,
  onSave: (paymentOption: PaymentOption) => void,
  onClose: () => void,
  selectedPaymentOption: PaymentOption,
  digitalWalletType?: DigitalWalletType,
  availablePaymentOptions: PaymentOption[],
  onDeleteSavedCardOnFile: () => void,
  isBusy: boolean,
  cardOnFileData?: CardOnFileResponse
}> = (props) => {
  const { t } = useTranslation();

  const handleForgetMyCard = async () => {
    props.onDeleteSavedCardOnFile();
  };

  const showWhyIsApplePayMissingPanel = (isIOS || isMobileSafari || isSafari) && props.digitalWalletType !== DigitalWalletType.APPLE_PAY;

  return <DialogWithTitle open={props.open} onClose={props.onClose} title={t("Select-Payment-Type")}>
    <Box p={2} pt={4} display="flex" flexDirection="column">
      <Box mb={4} display="flex" flexDirection="column">
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <RadioGroup
            aria-label="paymentMethod"
            name="paymentMethod"
            value={props.selectedPaymentOption || props.selectedPaymentOption}
            onChange={(e: any) => props.onSave(e.target.value)}
          >
            {isPaymentOptionAvailable(PaymentOption.CARD_ON_FILE, props.availablePaymentOptions) && props.cardOnFileData && <FormControlLabel
              key={PaymentOption.CARD_ON_FILE}
              value={PaymentOption.CARD_ON_FILE}
              control={<Radio />}
              sx={{
                "& .MuiFormControlLabel-label": {
                  width: "100%"
                }
              }}
              label={
                <RememberedCardLabel
                  scheme={props.cardOnFileData.scheme}
                  last4={props.cardOnFileData.last4}
                  expireMonth={props.cardOnFileData.expireMonth}
                  expireYear={props.cardOnFileData.expireYear}
                  onDelete={handleForgetMyCard}
                  busy={props.isBusy}
                />
              }
            />}
            {isPaymentOptionAvailable(PaymentOption.DIGITAL_WALLET, props.availablePaymentOptions) && <FormControlLabel
              key={PaymentOption.DIGITAL_WALLET}
              value={PaymentOption.DIGITAL_WALLET}
              control={<Radio />}
              label={<PaymentTypeLabelFactory paymentOption={PaymentOption.DIGITAL_WALLET} digitalWalletType={props.digitalWalletType} />}
            />}
            {isPaymentOptionAvailable(PaymentOption.CARD, props.availablePaymentOptions) &&
              <FormControlLabel key={PaymentOption.CARD} value={PaymentOption.CARD} control={<Radio />} label={<PaymentTypeLabelFactory paymentOption={PaymentOption.CARD} />} />}
            {isPaymentOptionAvailable(PaymentOption.PAYPAL, props.availablePaymentOptions) &&
              <FormControlLabel key={PaymentOption.PAYPAL} value={PaymentOption.PAYPAL} control={<Radio />}
                                label={<PaymentTypeLabelFactory paymentOption={PaymentOption.PAYPAL} />} />}
          </RadioGroup>
        </FormControl>
        <Button variant="contained" color="secondary" onClick={props.onClose} sx={{ mt: 4 }}>
          {t("Save")}
        </Button>
      </Box>

      <CardAcceptanceMarks />

      {showWhyIsApplePayMissingPanel && <>
        <Divider sx={{ mt: 2 }} />
        <Box pt={4}>
          <Alert severity={"info"} variant={"outlined"}>
            <AlertTitle>{t("Apple-Pay-Alert-Title")}</AlertTitle>

            {(isSafari || isMobileSafari) && (
              <Typography variant="caption" component={"div"}>
                {t("Apple-Pay-Alert-Message-1")}
                <ul style={{ paddingLeft: 16 }}>
                  <li>{t("Apple-Pay-Alert-Reminder-1")}</li>
                  <li>{t("Apple-Pay-Alert-Reminder-2")}</li>
                  <li>{t("Apple-Pay-Alert-Reminder-3")}</li>
                </ul>
                {t("Apple-Pay-Alert-Message-2")}
              </Typography>
            )}
            {!(isSafari || isMobileSafari) && (
              <>
                <Typography variant="caption">{t("Apple-Pay-Alert-Message-3")}</Typography>
                <Typography variant="caption" component={"div"} sx={{ mt: 1 }}>
                  {t("Apple-Pay-Alert-Message-4")}
                </Typography>
              </>
            )}
          </Alert>
        </Box>
      </>}
    </Box>
  </DialogWithTitle>;
};

export default PaymentTypeSelectDialog;
