import { SplashMessage } from "../../api/gen";
import { createReducer } from "@reduxjs/toolkit";
import { getMessages } from "../actions/splashMessageActions";

const initialState = {
  isLoading: false,
  isError: false,
  messages: null,
} as SplashMessageResponseWrapper;

const splashMessageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getMessages.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.messages = null;
    })
    .addCase(getMessages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false
      state.messages = action.payload;
    })
    .addCase(getMessages.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.messages = null;
    })
});

export default splashMessageReducer;

export type SplashMessageResponseWrapper = {
  isLoading: boolean;
  isError: boolean;
  messages: SplashMessage[] | null;
};
