/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentChannelIdentifier } from './PaymentChannelIdentifier';
import {
    PaymentChannelIdentifierFromJSON,
    PaymentChannelIdentifierFromJSONTyped,
    PaymentChannelIdentifierToJSON,
} from './PaymentChannelIdentifier';
import type { CardScheme } from './CardScheme';
import {
    CardSchemeFromJSON,
    CardSchemeFromJSONTyped,
    CardSchemeToJSON,
} from './CardScheme';
import type { PaymentTokenType } from './PaymentTokenType';
import {
    PaymentTokenTypeFromJSON,
    PaymentTokenTypeFromJSONTyped,
    PaymentTokenTypeToJSON,
} from './PaymentTokenType';

/**
 * 
 * @export
 * @interface CardOnFileRequest
 */
export interface CardOnFileRequest {
    /**
     * 
     * @type {CardScheme}
     * @memberof CardOnFileRequest
     */
    scheme: CardScheme;
    /**
     * 
     * @type {string}
     * @memberof CardOnFileRequest
     */
    last4: string;
    /**
     * 
     * @type {number}
     * @memberof CardOnFileRequest
     */
    expireMonth: number;
    /**
     * 
     * @type {number}
     * @memberof CardOnFileRequest
     */
    expireYear: number;
    /**
     * 
     * @type {PaymentTokenType}
     * @memberof CardOnFileRequest
     */
    paymentTokenType: PaymentTokenType;
    /**
     * 
     * @type {PaymentChannelIdentifier}
     * @memberof CardOnFileRequest
     */
    paymentChannelType: PaymentChannelIdentifier;
    /**
     * 
     * @type {string}
     * @memberof CardOnFileRequest
     */
    token: string;
}

/**
 * Check if a given object implements the CardOnFileRequest interface.
 */
export function instanceOfCardOnFileRequest(value: object): value is CardOnFileRequest {
    if (!('scheme' in value) || value['scheme'] === undefined) return false;
    if (!('last4' in value) || value['last4'] === undefined) return false;
    if (!('expireMonth' in value) || value['expireMonth'] === undefined) return false;
    if (!('expireYear' in value) || value['expireYear'] === undefined) return false;
    if (!('paymentTokenType' in value) || value['paymentTokenType'] === undefined) return false;
    if (!('paymentChannelType' in value) || value['paymentChannelType'] === undefined) return false;
    if (!('token' in value) || value['token'] === undefined) return false;
    return true;
}

export function CardOnFileRequestFromJSON(json: any): CardOnFileRequest {
    return CardOnFileRequestFromJSONTyped(json, false);
}

export function CardOnFileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardOnFileRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'scheme': CardSchemeFromJSON(json['scheme']),
        'last4': json['last4'],
        'expireMonth': json['expireMonth'],
        'expireYear': json['expireYear'],
        'paymentTokenType': PaymentTokenTypeFromJSON(json['paymentTokenType']),
        'paymentChannelType': PaymentChannelIdentifierFromJSON(json['paymentChannelType']),
        'token': json['token'],
    };
}

export function CardOnFileRequestToJSON(value?: CardOnFileRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'scheme': CardSchemeToJSON(value['scheme']),
        'last4': value['last4'],
        'expireMonth': value['expireMonth'],
        'expireYear': value['expireYear'],
        'paymentTokenType': PaymentTokenTypeToJSON(value['paymentTokenType']),
        'paymentChannelType': PaymentChannelIdentifierToJSON(value['paymentChannelType']),
        'token': value['token'],
    };
}

