import React from "react";
import DialogWithTitle from "../common/dialog/DialogWithTitle";
import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import ReactGA from "react-ga4";
import "../../App.css";
import { useTranslation } from "react-i18next";
import "../../../src/i18n";

const ContactDialog = ({ open, onClose, emailAddress, phoneNumber, storeName }: ContactDialogPropTypes) => {
  const { t } = useTranslation();

  if (open) {
    ReactGA.event({
      category: "Hamburger",
      action: "Show Contact page",
    });
  }
  return (
    <DialogWithTitle open={open} onClose={onClose} title={t("Contact")}>
      <Box className={"gradient-background"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"center"} px={2}>
        <Box mt={8} component={"div"}>
          <Typography variant={"h4"} fontFamily={"Quicksand"} color={"white"}>
            {storeName}
          </Typography>
        </Box>

        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} px={2}>
          {phoneNumber && (
            <Card sx={{ minWidth: 275, my: 2 }}>
              <CardContent>
                <Typography color="text.primary" gutterBottom variant="h5">
                  <LocalPhoneIcon /> {t("Phone")}
                </Typography>
                <Typography variant={"body1"} component="div">
                  {phoneNumber}
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant={"text"} startIcon={<LocalPhoneIcon />} onClick={() => (window.location.href = "tel:" + phoneNumber.replaceAll("[^0-9]", ""))}>
                  {t("Call-Now")}
                </Button>
              </CardActions>
            </Card>
          )}

          <Card sx={{ minWidth: 275, my: 2 }}>
            <CardContent>
              <Typography color="text.primary" gutterBottom variant="h5" component={"div"}>
                {t("Email")}
              </Typography>
              <Typography variant={"body1"} component="div">
                {emailAddress}
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant={"text"} startIcon={<EmailIcon />} onClick={() => (window.location.href = "mailto:" + emailAddress)}>
                {t("Email-Now")}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Box>
    </DialogWithTitle>
  );
};

type ContactDialogPropTypes = {
  phoneNumber: string;
  storeName: string;
  emailAddress: string;
  onClose: () => void;
  open: boolean;
};

export default ContactDialog;
