/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CycleInfo } from './CycleInfo';
import {
    CycleInfoFromJSON,
    CycleInfoFromJSONTyped,
    CycleInfoToJSON,
} from './CycleInfo';
import type { MachineReadiness } from './MachineReadiness';
import {
    MachineReadinessFromJSON,
    MachineReadinessFromJSONTyped,
    MachineReadinessToJSON,
} from './MachineReadiness';

/**
 * 
 * @export
 * @interface MachineStatusResponse
 */
export interface MachineStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof MachineStatusResponse
     */
    machineId: string;
    /**
     * 
     * @type {MachineReadiness}
     * @memberof MachineStatusResponse
     */
    status: MachineReadiness;
    /**
     * 
     * @type {CycleInfo}
     * @memberof MachineStatusResponse
     */
    cycleInfo?: CycleInfo;
}

/**
 * Check if a given object implements the MachineStatusResponse interface.
 */
export function instanceOfMachineStatusResponse(value: object): value is MachineStatusResponse {
    if (!('machineId' in value) || value['machineId'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function MachineStatusResponseFromJSON(json: any): MachineStatusResponse {
    return MachineStatusResponseFromJSONTyped(json, false);
}

export function MachineStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineStatusResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'machineId': json['machineId'],
        'status': MachineReadinessFromJSON(json['status']),
        'cycleInfo': json['cycleInfo'] == null ? undefined : CycleInfoFromJSON(json['cycleInfo']),
    };
}

export function MachineStatusResponseToJSON(value?: MachineStatusResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'machineId': value['machineId'],
        'status': MachineReadinessToJSON(value['status']),
        'cycleInfo': CycleInfoToJSON(value['cycleInfo']),
    };
}

