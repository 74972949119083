import { Box, Typography } from "@mui/material";
import React from "react";

const TextWithIcon: React.FC<{ text: string, icon: JSX.Element }> = (props) =>
  <Box display={"flex"} alignItems={"center"} component={"div"}>
    <div style={{ display: "flex" }}>{props.icon}</div>
    <Typography variant={"body2"}>
      {props.text}
    </Typography>
  </Box>;

export default TextWithIcon;