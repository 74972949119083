import { Payment } from "@mui/icons-material";
import React from "react";
import BusyButton from "../../common/controls/BusyButton";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const PayButton = ({ busy, onClick, amount, disabled }: PayButtonPropTypes) => {
  const { t } = useTranslation();

  return (
    <BusyButton busy={busy} disabled={busy || disabled} icon={<Payment />} onClick={onClick}>
      {t("Pay")} {amount}
    </BusyButton>
  );
};

type PayButtonPropTypes = {
  busy: boolean;
  disabled?: boolean;
  onClick: () => void;
  amount: string;
};

export default PayButton;
