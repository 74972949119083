import React from "react";
import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSiteConfig } from "../../hooks";
import { useTranslation } from "react-i18next";
import "../../i18n";

const ZoneSelectPage = () => {
  const navigate = useNavigate();
  const { siteConfig } = useSiteConfig();
  const { t } = useTranslation();

  const zones = siteConfig?.machines.map((m) => ({ name: m.zone, id: m.zoneId })).filter((z, i, a) => a.findIndex((t) => t.id === z.id) === i) ?? [];

  const handleClick = (zoneId: string) => {
    if (siteConfig?.showCategorySelector) {
      navigate(`/${siteConfig?.siteId}/zones/${zoneId}`);
    } else {
      navigate(`/${siteConfig?.siteId}/zones/${zoneId}/machines`);
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} flexGrow={1} mt={-2} className={"gradient-background"}>
      <Box pt={8} textAlign={"center"}>
        <Typography variant="h5" gutterBottom color="common.white">
          {t("Welcome")}
        </Typography>
        <Typography variant={"body1"} gutterBottom color="common.white">
          {t("Where-Location")}
        </Typography>
      </Box>

      <Box p={4}>
        {zones?.map((z) => (
          <Card key={z.id} sx={{ py: 2, my: 4 }}>
            <CardActionArea>
              <CardContent onClick={() => handleClick(z.id)}>
                <Box display="flex" alignItems="center">
                  {/*<Box mr={2}>*/}
                  {/*  <MachineTypeIcon machineType={props.machineType} width={"52px"} />*/}
                  {/*</Box>*/}
                  <Box display="flex" flexDirection="column">
                    <Box width="100%">
                      <Typography variant="h6" component="div">
                        {z.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
      {/*Empty space to allow scroll up*/}
      <Box p={8} />
    </Box>
  );
};

export default ZoneSelectPage;
