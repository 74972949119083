import { AppBar, Box, Button, FormControlLabel, FormGroup, IconButton, Switch, TextField, Toolbar, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AuthenticationRequiredPage from "../common/AuthenticationRequiredPage";
import { useTranslation } from "react-i18next";
import "../../i18n";

const AccountEditPage = ({ siteId }: AccountEditPageProps) => {
  const navigate = useNavigate();
  const auth0 = useAuth0();
  const { t } = useTranslation();

  if (!auth0.isAuthenticated) {
    return <AuthenticationRequiredPage siteId={siteId} />;
  }

  return (
    <Box bgcolor={"white"} height={"100vh"}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              navigate(-1);
            }}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t("Profile-Edit-Account")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} m={1} py={3} height={"calc(100% - 150px)"}>
        <Box display={"flex"} flexDirection={"column"}>
          <TextField label={t("Email")} type={"email"} value={auth0.user?.email} />
          <FormGroup>
            <FormControlLabel control={<Switch />} label={t("Profile-Special-Offers")} />
          </FormGroup>
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          <Button variant={"contained"} color={"primary"} fullWidth style={{ marginBottom: 12 }}>
            {t("Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

type AccountEditPageProps = {
  siteId: string;
};

export default AccountEditPage;
