import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import React from "react";
import MachineTypeIcon from "../common/machineType/MachineTypeIcon";
import { MachineType } from "../../api/gen";
import { useTranslation } from "react-i18next";
import "../../i18n";

const MachineTypeCard = (props: MachineTypeCardPropTypes) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardActionArea>
        <CardContent onClick={props.onClick}>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <MachineTypeIcon machineType={props.machineType} width={"52px"} />
            </Box>
            <Box display="flex" flexDirection="column">
              <Box width="100%">
                <Typography variant="h6" component="div">
                  {t(props.name)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

type MachineTypeCardPropTypes = {
  onClick: () => void;
  name: string;
  machineType: MachineType;
};

export default MachineTypeCard;
