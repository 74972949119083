/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentChannelIdentifier = {
    None: 'None',
    Square: 'Square',
    PayPal: 'PayPal',
    Stripe: 'Stripe',
    UsaV2: 'UsaV2',
    UsaV3: 'UsaV3',
    AuV1: 'AuV1',
    AuV2: 'AuV2',
    AuV3: 'AuV3',
    NzV1: 'NzV1',
    EuV1: 'EuV1',
    Wallet: 'Wallet',
    Nayax: 'Nayax'
} as const;
export type PaymentChannelIdentifier = typeof PaymentChannelIdentifier[keyof typeof PaymentChannelIdentifier];


export function instanceOfPaymentChannelIdentifier(value: any): boolean {
    for (const key in PaymentChannelIdentifier) {
        if (Object.prototype.hasOwnProperty.call(PaymentChannelIdentifier, key)) {
            if ((PaymentChannelIdentifier as Record<string, PaymentChannelIdentifier>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PaymentChannelIdentifierFromJSON(json: any): PaymentChannelIdentifier {
    return PaymentChannelIdentifierFromJSONTyped(json, false);
}

export function PaymentChannelIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentChannelIdentifier {
    return json as PaymentChannelIdentifier;
}

export function PaymentChannelIdentifierToJSON(value?: PaymentChannelIdentifier | null): any {
    return value as any;
}

