import { Lock } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import CardConnectCardEntry from "./cardconnect/CardConnectCardEntry";
import StripeCardEntry from "./stripe/StripeCardEntry";
import TillCardEntry from "./till/TillCardEntry";
import { TokenReceivedCallback } from "../CheckoutPage";
import { Cart } from "../../../models";
import { usePaymentMethods } from "../../../hooks";
import { AmountsModel, PaymentChannel, PaymentChannelIdentifier } from "../../../api/gen";
import DatacapCardEntry from "./datacap/DatacapCardEntry";
import MyPosEmbeddedPayment from "./mypos/MyPosEmbeddedPayment";
import { ControlMountedCallback } from "./common/controlMountedCallback";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const selectCardEntry = (
  onTokenReceived: TokenReceivedCallback,
  description: string,
  disableSaveMyCard: boolean,
  onButtonMounted: ControlMountedCallback,
  paymentChannel?: PaymentChannel,
  amounts?: AmountsModel,
  cart?: Cart,
  initialEmailAddress?: string
) => {
  if (!amounts || !cart) {
    return null;
  }

  switch (paymentChannel?.type) {
    case PaymentChannelIdentifier.Stripe:
      return <StripeCardEntry amount={amounts.grandTotal} onTokenReceived={onTokenReceived} onElementMounted={onButtonMounted} />;
    case PaymentChannelIdentifier.UsaV2:
      return <CardConnectCardEntry width={300} amount={amounts.grandTotal} onTokenReceived={onTokenReceived} onElementMounted={onButtonMounted} />;
    case PaymentChannelIdentifier.UsaV3:
      return <DatacapCardEntry amount={amounts.grandTotal} onTokenReceived={onTokenReceived} onElementMounted={onButtonMounted} />;
    case PaymentChannelIdentifier.AuV3:
      return (
        <TillCardEntry
          amount={amounts.grandTotal}
          onTokenReceived={onTokenReceived}
          onElementMounted={onButtonMounted}
          cardChannel={paymentChannel}
          disableSaveMyCard={disableSaveMyCard}
          initialEmailAddress={initialEmailAddress}
        />
      );
    case PaymentChannelIdentifier.EuV1:
      return <MyPosEmbeddedPayment amounts={amounts} onTokenReceived={onTokenReceived} onElementMounted={onButtonMounted} cart={cart} />;
    default:
      return null;
  }
};

const CardEntry: React.FC<{
  onTokenReceived: TokenReceivedCallback;
  visible: boolean;
  description: string;
  onInit: ControlMountedCallback;
  amounts: AmountsModel;
  cart: Cart;
  disableSaveMyCard: boolean;
  initialEmailAddress?: string;
}> = (props) => {
  const { cardChannel } = usePaymentMethods();
  const { t } = useTranslation();

  let cardEntry = selectCardEntry(
    props.onTokenReceived,
    props.description,
    props.disableSaveMyCard,
    props.onInit,
    cardChannel,
    props.amounts,
    props.cart,
    props.initialEmailAddress
  );
  if (cardEntry) {
    return (
      <Box display={props.visible ? "block" : "none"}>
        {cardEntry}

        <Box color="text.secondary" pt={2} alignItems="center" textAlign="center">
          <div>
            <Lock />
          </div>
          <div>
            <Typography variant="caption">{t("Payment-Is-Secure")}</Typography>
          </div>
        </Box>
      </Box>
    );
  }
  return <></>;
};

export default CardEntry;
