import { Box } from "@mui/material";
import React from "react";
import CardConnectPaymentButton from "./cardconnect/CardConnectPaymentButton";
import StripePaymentRequest from "./stripe/StripePaymentRequest";
import TillPaymentButton from "./till/TillPaymentButton";
import { DigitalWalletType } from "../../../models";
import { DigitalWalletTypeChangedEvent, TokenReceivedCallback } from "../CheckoutPage";
import { PaymentChannel, PaymentChannelIdentifier } from "../../../api/gen";
import { usePaymentMethods } from "../../../hooks";
import DatacapPaymentButton from "./datacap/DatacapPaymentButton";

function digitalWalletButton(
  digitalWalletChannel: PaymentChannel | undefined,
  digitalWalletType: DigitalWalletType,
  amount: number,
  onTokenReceived: TokenReceivedCallback,
  onDigitalWalletChanged: DigitalWalletTypeChangedEvent
) {
  switch (digitalWalletChannel?.type) {
    case PaymentChannelIdentifier.Stripe:
      return <StripePaymentRequest amount={amount} onTokenReceived={onTokenReceived} onDigitalWalletChanged={onDigitalWalletChanged} />;
    case PaymentChannelIdentifier.UsaV2:
      return <CardConnectPaymentButton amount={amount} onTokenReceived={onTokenReceived} digitalWalletType={digitalWalletType} />;
    case PaymentChannelIdentifier.UsaV3:
      return <DatacapPaymentButton amount={amount} onTokenReceived={onTokenReceived} digitalWalletType={digitalWalletType} />;
    case PaymentChannelIdentifier.AuV3:
      return <TillPaymentButton amount={amount} onTokenReceived={onTokenReceived} digitalWalletType={digitalWalletType} paymentChannel={digitalWalletChannel} />;
    default: {
      return null;
    }
  }
}

const DigitalWalletEntry = ({ visible, onTokenReceived, amount, digitalWalletType, onDigitalWalletChanged }: DigitalWalletEntryPropTypes) => {
  const { digitalWalletChannel } = usePaymentMethods();
  const button = digitalWalletButton(digitalWalletChannel, digitalWalletType, amount, onTokenReceived, onDigitalWalletChanged);
  if (button) {
    return <Box display={visible ? "block" : "none"}>{button}</Box>;
  }
  return <></>;
};

type DigitalWalletEntryPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  visible: boolean;
  amount: number,
  digitalWalletType: DigitalWalletType;
  onDigitalWalletChanged: DigitalWalletTypeChangedEvent
};

export default DigitalWalletEntry;
