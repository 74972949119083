/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReminderRequest
 */
export interface ReminderRequest {
    /**
     * 
     * @type {string}
     * @memberof ReminderRequest
     */
    reminderPhoneNumber: string;
}

/**
 * Check if a given object implements the ReminderRequest interface.
 */
export function instanceOfReminderRequest(value: object): value is ReminderRequest {
    if (!('reminderPhoneNumber' in value) || value['reminderPhoneNumber'] === undefined) return false;
    return true;
}

export function ReminderRequestFromJSON(json: any): ReminderRequest {
    return ReminderRequestFromJSONTyped(json, false);
}

export function ReminderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReminderRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'reminderPhoneNumber': json['reminderPhoneNumber'],
    };
}

export function ReminderRequestToJSON(value?: ReminderRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reminderPhoneNumber': value['reminderPhoneNumber'],
    };
}

