/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AmountsModel } from './AmountsModel';
import {
    AmountsModelFromJSON,
    AmountsModelFromJSONTyped,
    AmountsModelToJSON,
} from './AmountsModel';
import type { OrderStatus } from './OrderStatus';
import {
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';
import type { PromotionModel } from './PromotionModel';
import {
    PromotionModelFromJSON,
    PromotionModelFromJSONTyped,
    PromotionModelToJSON,
} from './PromotionModel';
import type { PaymentNotRequiredReason } from './PaymentNotRequiredReason';
import {
    PaymentNotRequiredReasonFromJSON,
    PaymentNotRequiredReasonFromJSONTyped,
    PaymentNotRequiredReasonToJSON,
} from './PaymentNotRequiredReason';
import type { LoyaltyModel } from './LoyaltyModel';
import {
    LoyaltyModelFromJSON,
    LoyaltyModelFromJSONTyped,
    LoyaltyModelToJSON,
} from './LoyaltyModel';
import type { PaymentStatus } from './PaymentStatus';
import {
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './PaymentStatus';
import type { CardOnFileRequest } from './CardOnFileRequest';
import {
    CardOnFileRequestFromJSON,
    CardOnFileRequestFromJSONTyped,
    CardOnFileRequestToJSON,
} from './CardOnFileRequest';
import type { MachineCreditResultModel } from './MachineCreditResultModel';
import {
    MachineCreditResultModelFromJSON,
    MachineCreditResultModelFromJSONTyped,
    MachineCreditResultModelToJSON,
} from './MachineCreditResultModel';

/**
 * 
 * @export
 * @interface OrderResultModel
 */
export interface OrderResultModel {
    /**
     * 
     * @type {boolean}
     * @memberof OrderResultModel
     */
    isError: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderResultModel
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResultModel
     */
    customerReference: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResultModel
     */
    statementNarration: string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderResultModel
     */
    orderStatus: OrderStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof OrderResultModel
     */
    paymentStatus: PaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderResultModel
     */
    errorMessageText?: string;
    /**
     * 
     * @type {PaymentNotRequiredReason}
     * @memberof OrderResultModel
     */
    paymentStatusReason?: PaymentNotRequiredReason;
    /**
     * 
     * @type {string}
     * @memberof OrderResultModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderResultModel
     */
    minutesRemaining?: number;
    /**
     * 
     * @type {Array<MachineCreditResultModel>}
     * @memberof OrderResultModel
     */
    credits: Array<MachineCreditResultModel>;
    /**
     * 
     * @type {Date}
     * @memberof OrderResultModel
     */
    orderTimestamp: Date;
    /**
     * 
     * @type {PromotionModel}
     * @memberof OrderResultModel
     */
    promotion: PromotionModel;
    /**
     * 
     * @type {LoyaltyModel}
     * @memberof OrderResultModel
     */
    loyalty?: LoyaltyModel;
    /**
     * 
     * @type {AmountsModel}
     * @memberof OrderResultModel
     */
    initialAmounts: AmountsModel;
    /**
     * 
     * @type {AmountsModel}
     * @memberof OrderResultModel
     */
    currentAmounts: AmountsModel;
    /**
     * 
     * @type {number}
     * @memberof OrderResultModel
     */
    voidAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderResultModel
     */
    adjustmentAmount: number;
    /**
     * 
     * @type {CardOnFileRequest}
     * @memberof OrderResultModel
     */
    cardOnFile?: CardOnFileRequest;
    /**
     * 
     * @type {string}
     * @memberof OrderResultModel
     */
    authenticationRedirectUrl?: string;
}

/**
 * Check if a given object implements the OrderResultModel interface.
 */
export function instanceOfOrderResultModel(value: object): value is OrderResultModel {
    if (!('isError' in value) || value['isError'] === undefined) return false;
    if (!('orderId' in value) || value['orderId'] === undefined) return false;
    if (!('customerReference' in value) || value['customerReference'] === undefined) return false;
    if (!('statementNarration' in value) || value['statementNarration'] === undefined) return false;
    if (!('orderStatus' in value) || value['orderStatus'] === undefined) return false;
    if (!('paymentStatus' in value) || value['paymentStatus'] === undefined) return false;
    if (!('credits' in value) || value['credits'] === undefined) return false;
    if (!('orderTimestamp' in value) || value['orderTimestamp'] === undefined) return false;
    if (!('promotion' in value) || value['promotion'] === undefined) return false;
    if (!('initialAmounts' in value) || value['initialAmounts'] === undefined) return false;
    if (!('currentAmounts' in value) || value['currentAmounts'] === undefined) return false;
    if (!('voidAmount' in value) || value['voidAmount'] === undefined) return false;
    if (!('adjustmentAmount' in value) || value['adjustmentAmount'] === undefined) return false;
    return true;
}

export function OrderResultModelFromJSON(json: any): OrderResultModel {
    return OrderResultModelFromJSONTyped(json, false);
}

export function OrderResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderResultModel {
    if (json == null) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'orderId': json['orderId'],
        'customerReference': json['customerReference'],
        'statementNarration': json['statementNarration'],
        'orderStatus': OrderStatusFromJSON(json['orderStatus']),
        'paymentStatus': PaymentStatusFromJSON(json['paymentStatus']),
        'errorMessageText': json['errorMessageText'] == null ? undefined : json['errorMessageText'],
        'paymentStatusReason': json['paymentStatusReason'] == null ? undefined : PaymentNotRequiredReasonFromJSON(json['paymentStatusReason']),
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'minutesRemaining': json['minutesRemaining'] == null ? undefined : json['minutesRemaining'],
        'credits': ((json['credits'] as Array<any>).map(MachineCreditResultModelFromJSON)),
        'orderTimestamp': (new Date(json['orderTimestamp'])),
        'promotion': PromotionModelFromJSON(json['promotion']),
        'loyalty': json['loyalty'] == null ? undefined : LoyaltyModelFromJSON(json['loyalty']),
        'initialAmounts': AmountsModelFromJSON(json['initialAmounts']),
        'currentAmounts': AmountsModelFromJSON(json['currentAmounts']),
        'voidAmount': json['voidAmount'],
        'adjustmentAmount': json['adjustmentAmount'],
        'cardOnFile': json['cardOnFile'] == null ? undefined : CardOnFileRequestFromJSON(json['cardOnFile']),
        'authenticationRedirectUrl': json['authenticationRedirectUrl'] == null ? undefined : json['authenticationRedirectUrl'],
    };
}

export function OrderResultModelToJSON(value?: OrderResultModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isError': value['isError'],
        'orderId': value['orderId'],
        'customerReference': value['customerReference'],
        'statementNarration': value['statementNarration'],
        'orderStatus': OrderStatusToJSON(value['orderStatus']),
        'paymentStatus': PaymentStatusToJSON(value['paymentStatus']),
        'errorMessageText': value['errorMessageText'],
        'paymentStatusReason': PaymentNotRequiredReasonToJSON(value['paymentStatusReason']),
        'emailAddress': value['emailAddress'],
        'minutesRemaining': value['minutesRemaining'],
        'credits': ((value['credits'] as Array<any>).map(MachineCreditResultModelToJSON)),
        'orderTimestamp': ((value['orderTimestamp']).toISOString()),
        'promotion': PromotionModelToJSON(value['promotion']),
        'loyalty': LoyaltyModelToJSON(value['loyalty']),
        'initialAmounts': AmountsModelToJSON(value['initialAmounts']),
        'currentAmounts': AmountsModelToJSON(value['currentAmounts']),
        'voidAmount': value['voidAmount'],
        'adjustmentAmount': value['adjustmentAmount'],
        'cardOnFile': CardOnFileRequestToJSON(value['cardOnFile']),
        'authenticationRedirectUrl': value['authenticationRedirectUrl'],
    };
}

