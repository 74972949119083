import React, { useState } from "react";
import { Locale } from "../../../../models/locale";
import { ApplePaySettings } from "../../../../api/gen";
import { ApplePayContext, ApplePayHelper } from "./applePay";
import { ApplePaySessionApi } from "../../../../api/applePaySessionApi";

const ApplePayButton = ({ locale, onPaymentDataReceived, amount, applePaySettings, applePaySessionApi, supportsAmex }: ApplePayButtonPropTypes) => {
  const [applePayHelper] = useState(() => new ApplePayHelper(applePaySessionApi));

  const supportedNetworks = supportsAmex ? ["amex", "masterCard", "visa", "discover"] : ["masterCard", "visa", "discover"];

  const handleApplePayClick = () => {
    let applePayContext = new ApplePayContext(locale.currencyCode, locale.countryCode, amount.toFixed(2), applePaySettings.merchantName, supportedNetworks);

    applePayHelper.collectPayment(applePayContext, applePaySettings.merchantId, (paymentData: ApplePayJS.ApplePayPayment) => {
      console.debug("Apple payment token", paymentData);
      onPaymentDataReceived(paymentData);
    });
  };

  return <div className="apple-pay-button apple-pay-button-black" onClick={handleApplePayClick} />;
};

type ApplePayButtonPropTypes = {
  locale: Locale;
  onPaymentDataReceived: (paymentData: ApplePayJS.ApplePayPayment) => void;
  amount: number;
  applePaySettings: ApplePaySettings;
  applePaySessionApi: ApplePaySessionApi;
  supportsAmex: boolean;
};

export default ApplePayButton;
