import { CheckCircle, Warning } from "@mui/icons-material";
import { Box, Card, CardActionArea, CardContent, Collapse, Typography, useTheme } from "@mui/material";
import React, { useRef } from "react";
import VariableAmountSelector from "./VariableAmountSelector";
import MachineTypeIcon from "../common/machineType/MachineTypeIcon";
import { CycleStatus, Machine, MachineOption, MachineReadiness } from "../../api/gen";
import { Locale } from "../../models/locale";
import BusyIndicator from "./BusyIndicator";
import { useTranslation } from "react-i18next";
import "../../i18n";
import MachineOptionsGroup from "./MachineOptionsGroup";

const hasOptions = (machine: Machine) => machine.machineOptionGroups.length > 0;
const hasVariablePricing = (machine: Machine) => !hasOptions(machine) && machine.minAmount !== machine.maxAmount;

const fromVendToCycleMap = (machine: Machine) => {
  return machine.vendToCycleMaps.map((vcm) => ({
    value: vcm.vendAmount,
    label: vcm.cycleName
  }));
};

const fromMinAndMax = (machine: Machine, locale: Locale) => {
  const values = [];
  for (let val = machine.minAmount; val <= machine.maxAmount; val += machine.incrementByAmount) {
    values.push({
      value: val,
      label: locale.formatCurrency(val)
    });
  }
  return values;
};

const generateMachineSelectionValues = (machine: Machine, locale: Locale) => {
  if (machine.vendToCycleMaps?.length > 0) {
    // if the machine is a vend-to-cycle map, use that
    return fromVendToCycleMap(machine);
  }
  return fromMinAndMax(machine, locale);
};


const MachineCard = ({
                       locale,
                       onSelectMachine,
                       onVendAmountChange,
                       selected,
                       machine,
                       selectedOptions,
                       onMachineOptionClicked,
                       vendAmount,
                       scrollTo,
                       isValid
                     }: MachineCardProps) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  setTimeout(() => {
    if (scrollTo && ref.current) {
      ref.current?.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, 500);

  const inUse = machine.cycleInfo?.cycleStatus === CycleStatus.Running;
  const isNotAvailable = machine.machineReadiness === MachineReadiness.Error;
  const awaitingOptionSelection = isValid;
  let backgroundColor: string;
  if (selected && !awaitingOptionSelection) {
    backgroundColor = theme.palette.primary.light;
  } else if (selected && awaitingOptionSelection) {
    backgroundColor = theme.palette.warning.light;
  } else {
    backgroundColor = theme.palette.background.paper;
  }

  return (
    <Card
      ref={ref}
      style={{
        backgroundColor: backgroundColor
      }}
    >
      {isNotAvailable ? (
        <CardContent>
          <Box display="flex" alignItems="center" position="relative">
            <Box mr={2}>
              <MachineTypeIcon machineType={machine.type} width={"52px"} isNotAvailable={isNotAvailable} />
            </Box>
            <Box display="flex" flexDirection="column" sx={{ color: theme.palette.text.disabled }}>
              <Box width="100%">
                <Typography variant="h6" component="div">
                  {machine.name}
                </Typography>
              </Box>
              <div>
                <Typography variant="body1">{locale.formatCurrency(vendAmount)}</Typography>
              </div>
            </Box>
            <Box position="absolute" bottom={theme.spacing(-1)} right={theme.spacing(0)}>
              <Typography color="#808080" style={{ fontSize: "10px" }}>
                {t("Not-Available")}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      ) : (
        <CardActionArea onClick={() => onSelectMachine(vendAmount, !selected)}>
          <CardContent>
            <Box display="flex" alignItems="center" position="relative">
              <Box mr={2}>
                <MachineTypeIcon machineType={machine.type} width={"52px"} />
              </Box>
              <Box display="flex" flexDirection="column" sx={{ color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary }}>
                <Box component="span" hidden={!selected} position="absolute" top={theme.spacing(1)} right={theme.spacing(1)}>
                  {!awaitingOptionSelection && <CheckCircle sx={{ color: theme.palette.primary.contrastText }} />}
                  {awaitingOptionSelection && <Warning sx={{ color: theme.palette.primary.contrastText }} />}
                </Box>
                <Box
                  hidden={selected || !inUse}
                  position="absolute"
                  bottom={-16}
                  right={-16}
                  padding={theme.spacing(0.5)}
                  bgcolor={theme.palette.background.default}
                  borderRadius={"50% 50% 0 0"}
                  width={"112px"}
                >
                  <BusyIndicator
                    iconColour={theme.palette.text.secondary}
                    textColour={theme.palette.text.secondary}
                    minutesRemaining={machine.cycleInfo?.cycleMinutesRemaining ?? 0}
                  />
                </Box>
                <Box width="100%">
                  <Typography variant="h6" component="div">
                    {machine.name}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  {!(awaitingOptionSelection && selected) && <Typography variant="body1">{locale.formatCurrency(vendAmount)}</Typography>}
                  {awaitingOptionSelection && selected && <Typography variant="body1">{t("Select-Option")}</Typography>}
                </Box>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      )}

      {machine.minAmount !== machine.maxAmount && (
        <Collapse in={selected}>
          {hasVariablePricing(machine) && <VariableAmountSelector onChange={onVendAmountChange} value={vendAmount} values={generateMachineSelectionValues(machine, locale)} />}

          {hasOptions(machine) && (
            <MachineOptionsGroup
              machineOptionGroups={machine.machineOptionGroups}
              onOptionClicked={onMachineOptionClicked}
              selectedOptions={selectedOptions}
            />
          )}
        </Collapse>
      )}
    </Card>
  );
};

type MachineCardProps = {
  onSelectMachine: (vendAmount: number, selected: boolean) => void;
  onVendAmountChange: (vendAmount: number) => void;
  onMachineOptionClicked: (option: MachineOption) => void;
  selected: boolean;
  locale: Locale;
  vendAmount: number;
  machine: Machine;
  selectedOptions: Array<MachineOption>;
  scrollTo?: boolean;
  isValid: boolean;
};

export default MachineCard;
