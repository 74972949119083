import React from "react";
import { Box, Chip, Divider, Typography } from "@mui/material";
import { MachineOption, MachineOptionsMode } from "../../api/gen";

type MachineOptionsSelectorProps = {
  availableOptions: Array<MachineOption>;
  selectedOptions: Array<MachineOption>;
  mode: MachineOptionsMode;
  name?: string;
  onOptionClicked: (option: MachineOption) => void;
};

const MachineOptionsSelector = ({ availableOptions, onOptionClicked, mode, selectedOptions, name }: MachineOptionsSelectorProps) => {
  const handleClick = (option: MachineOption) => {
    onOptionClicked(option);
  };

  return (
    <>
      <Divider variant="fullWidth" />
      <Box p={2} bgcolor={"white"} display={"flex"} flexDirection={"column"}>
        <Typography variant="caption" component="div">
          {name}
        </Typography>
        <Box display={"flex"} flexWrap={"wrap"}>
          {availableOptions.map((option) => (
            <Chip
              key={option.name}
              size={"small"}
              variant={selectedOptions.some((so) => so.name === option.name) ? "filled" : "outlined"}
              label={option.name}
              sx={{ mr: 1, mb: 1 }}
              color={"primary"}
              onClick={() => handleClick(option)}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default MachineOptionsSelector;
