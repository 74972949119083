import { useAppSelector } from "../redux/hooks";
import { PaymentChannelIdentifier } from "../api/gen";
import { Locale } from "../models/locale";

export function usePaymentMethods() {
  const { siteConfig } = useAppSelector((state) => state.siteConfig);

  const digitalWalletChannel = siteConfig?.paymentChannels?.find((pc) => pc.type === siteConfig?.digitalWalletProvider);
  const cardChannel = siteConfig?.paymentChannels?.find((pc) => pc.type === siteConfig?.cardPaymentProvider);
  const payPalChannel = siteConfig?.paymentChannels?.find((pc) => pc.type === PaymentChannelIdentifier.PayPal);
  const googlePaySettings = siteConfig?.googlePaySettings;
  const applePaySettings = siteConfig?.applePaySettings;
  const isProduction = siteConfig?.isProduction ?? false;
  const canPayWithGateway = !!cardChannel || !!payPalChannel || !!digitalWalletChannel;

  return {
    digitalWalletChannel: digitalWalletChannel,
    cardChannel: cardChannel,
    payPalChannel: payPalChannel,
    googlePaySettings: googlePaySettings,
    applePaySettings: applePaySettings,
    isProduction: isProduction,
    isAnyMethodAvailable: canPayWithGateway
  };
}

export function useLocale() {
  const { siteConfig } = useAppSelector((state) => state.siteConfig);

  return new Locale(siteConfig?.locale ?? "en-AU", siteConfig?.currencyCode ?? "AUD", siteConfig?.countryCode ?? "AU");
}

export function useSiteConfig() {
  return useAppSelector((state) => state.siteConfig);
}
