import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Cart } from "../../models";
import { getQuote } from "../../api/quoteApi";
import { LocalSettingsService } from "../../service/localSettingsService";
import { sendTelemetry } from "../../api/telemetryApi";
import { PaymentChannelIdentifier } from "../../api/gen";

export type QuoteRequest = {
  siteId: string;
  cart: Cart;
  paymentChannelType?: PaymentChannelIdentifier
};

// This thunk deals with the user profile ID in addition to making the API call
export const requestQuote = createAsyncThunk("quotes/requestQuote", async (request: QuoteRequest) => {
  return await getQuote(request.siteId, request.cart, LocalSettingsService.getId() || undefined, request.paymentChannelType)
    .then((resp) => {
      LocalSettingsService.setId(resp.userProfileId);
      if (resp.promotion?.canRemember || resp.loyalty) {
        LocalSettingsService.setSavedCode(request.siteId, request.cart.promoCode || null);
      }
      return resp;
    })
    .catch((ex) => {
      sendTelemetry(`Quote request failed. Site ${request.siteId} with cart contents ${JSON.stringify(request.cart)}`);
      throw ex;
    });
});

export const deleteQuote = createAction("quotes/delete");
