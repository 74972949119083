import React from "react";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";


const DialogWithTitle = ({ title, open, onClose, children }: DialogWithTitlePropTypes) => {

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      {children}
    </Dialog>
  );
};

type DialogWithTitlePropTypes = {
  open: boolean,
  onClose: () => void,
  title: string,
  children: React.ReactNode
};

export default DialogWithTitle;
