import { Box, Typography } from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { HorizontalRule } from "@mui/icons-material";
import { Link } from "react-router-dom";

const AuthenticationRequiredPage = ({ siteId }: AuthenticationRequiredPageProps) => (

  <Box display={"flex"} flexDirection={"column"} justifyContent={"space-evenly"} alignItems={"center"}>
    <Typography variant={"h1"}><LockPersonIcon color={"error"} fontSize={"large"} /></Typography>
    <Typography variant={"h5"}>Members Only</Typography>
    <HorizontalRule sx={{ py: 3 }} />
    <Typography variant={"body1"}>You must be logged in to access this area</Typography>
    <Typography variant={"body1"}><Link to={"/siteId"}>Go Back</Link></Typography>
  </Box>
);

type AuthenticationRequiredPageProps = {
  siteId: string
}

export default AuthenticationRequiredPage;