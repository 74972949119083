import { Box } from "@mui/material";
import React from "react";
import { ItemWithPrice } from "./itemWithPrice";
import ItemWithPriceLine from "./ItemWithPriceLine";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const ItemsWithPricePanel = ({ items }: ItemsWithPricePanelPropTypes) => {
  const { t } = useTranslation();

  const translateCaption = (caption: string | undefined) => {
    if (!caption) {
      return "";
    }

    const stringsToTranslate = ["Washer", "Dryer", "Services", "Token", "Soap", "Massage-Chair", "Shower", "Arcade-Machine", "Game-Machine", "Milk-Dispenser", "Other"];
    let translatedCaption = caption;

    stringsToTranslate.forEach((str) => {
      if (caption.includes(str)) {
        translatedCaption = translatedCaption.replace(str, t(str));
      }
    });

    translatedCaption = translatedCaption.replace("minutes", t("Minutes"));

    return translatedCaption;
  };

  return (
    <>
      {items.map((item, index) => (
        <Box key={item.id || index} my={1} py={1}>
          <ItemWithPriceLine amount={item.amount} caption={translateCaption(item.caption)} label={item.name} labelWeight={"bold"} action={item.action} />
        </Box>
      ))}
    </>
  );
};

type ItemsWithPricePanelPropTypes = {
  items: Array<ItemWithPrice>;
};

export default ItemsWithPricePanel;
