import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";

const BusyIndicator = ({ iconColour, textColour, minutesRemaining }: BusyIndicatorProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div>
        <DirectionsRunIcon sx={{ color: iconColour }} />
      </div>
      <Typography variant="caption" color={textColour} mt={-0.5}>
        {minutesRemaining} {t("Mins-Remaining")}
      </Typography>
    </div>
  );
};

type BusyIndicatorProps = {
  iconColour: string;
  textColour: string;
  minutesRemaining: number;
};

export default BusyIndicator;
