import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, TextField } from "@mui/material";
import ChevronButton from "../../common/controls/ChevronButton";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import TextWithIcon from "../../common/controls/TextWithIcon";

const PromoDialog = ({ onClose, open, promoCode, showSpinner, onPromoTextChanged, promoCodeValidationResult, onApplyClicked }: PromoDialogPropTypes) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle variant={"body1"} color={"text.primary"} fontWeight={"bold"}>
        {t("Add-Discount-Voucher-Code")}{" "}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <DialogContentText variant={"body2"} color={"text.primary"}>
          {t("Enter-Your-Code")}
        </DialogContentText>
        <TextField
          variant={"outlined"}
          fullWidth
          size={"small"}
          margin={"normal"}
          label={t("Discount-Or-Voucher")}
          value={promoCode}
          onChange={onPromoTextChanged}
          error={promoCodeValidationResult?.error}
          helperText={promoCodeValidationResult?.errorMessage}
          inputProps={{ maxLength: 10 }}
          autoFocus
        />
        <LoadingButton variant={"contained"} fullWidth onClick={onApplyClicked} loading={showSpinner}>
          {t("Apply")}
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

type PromoDialogPropTypes = {
  onClose: () => void;
  open: boolean;
  showSpinner: boolean;
  promoCode: string;
  promoCodeValidationResult?: PromoCodeValidationResult;
  onPromoTextChanged: React.ChangeEventHandler<HTMLInputElement>;
  onApplyClicked: () => void;
};

const PromoButton = ({ showSpinner, onPromoCodeApplied, promoCodeValidationResult, promoCode }: PromoPropTypes) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentPromoCode, setCurrentPromoCode] = useState(promoCode || "");
  const [internalCodeValidationResult, setInternalCodeValidationResult] = useState<PromoCodeValidationResult | undefined>(undefined);
  const { t } = useTranslation();

  const handlePromoCodeButtonClicked = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleApplyClicked = () => {
    if (currentPromoCode.length === 0) {
      setInternalCodeValidationResult({
        error: true,
        errorMessage: t("Code-Required")
      } as PromoCodeValidationResult);
    } else {
      onPromoCodeApplied(currentPromoCode.toUpperCase());
    }
  };

  const handlePromoCodeTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value.toUpperCase();
    setCurrentPromoCode(code);
    setInternalCodeValidationResult(undefined);
  };

  return (
    <>
      <PromoDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onApplyClicked={handleApplyClicked}
        showSpinner={showSpinner}
        promoCode={currentPromoCode}
        onPromoTextChanged={handlePromoCodeTextChange}
        promoCodeValidationResult={internalCodeValidationResult ?? promoCodeValidationResult}
      />

      <Box display={"flex"} flexDirection={"column"} width={"100%"}>
        <ChevronButton
          onClick={handlePromoCodeButtonClicked}
          label={<TextWithIcon text={t("Add-Discount-Voucher-Code")} icon={<LoyaltyIcon />} />}
        />
      </Box>
    </>
  );
};

type PromoPropTypes = {
  onPromoCodeApplied: (promoCode: string) => void;
  promoCodeValidationResult: PromoCodeValidationResult;
  showSpinner: boolean;
  promoCode?: string;
};

export interface PromoCodeValidationResult {
  error: boolean;
  errorMessage?: string;
  discount: string;
}

export default PromoButton;
