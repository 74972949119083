/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CycleStatus = {
    Idle: 'Idle',
    Running: 'Running'
} as const;
export type CycleStatus = typeof CycleStatus[keyof typeof CycleStatus];


export function instanceOfCycleStatus(value: any): boolean {
    for (const key in CycleStatus) {
        if (Object.prototype.hasOwnProperty.call(CycleStatus, key)) {
            if ((CycleStatus as Record<string, CycleStatus>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CycleStatusFromJSON(json: any): CycleStatus {
    return CycleStatusFromJSONTyped(json, false);
}

export function CycleStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CycleStatus {
    return json as CycleStatus;
}

export function CycleStatusToJSON(value?: CycleStatus | null): any {
    return value as any;
}

