/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GooglePaySettings
 */
export interface GooglePaySettings {
    /**
     * 
     * @type {string}
     * @memberof GooglePaySettings
     */
    gatewayId: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePaySettings
     */
    merchantId: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePaySettings
     */
    merchantName: string;
}

/**
 * Check if a given object implements the GooglePaySettings interface.
 */
export function instanceOfGooglePaySettings(value: object): value is GooglePaySettings {
    if (!('gatewayId' in value) || value['gatewayId'] === undefined) return false;
    if (!('merchantId' in value) || value['merchantId'] === undefined) return false;
    if (!('merchantName' in value) || value['merchantName'] === undefined) return false;
    return true;
}

export function GooglePaySettingsFromJSON(json: any): GooglePaySettings {
    return GooglePaySettingsFromJSONTyped(json, false);
}

export function GooglePaySettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GooglePaySettings {
    if (json == null) {
        return json;
    }
    return {
        
        'gatewayId': json['gatewayId'],
        'merchantId': json['merchantId'],
        'merchantName': json['merchantName'],
    };
}

export function GooglePaySettingsToJSON(value?: GooglePaySettings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gatewayId': value['gatewayId'],
        'merchantId': value['merchantId'],
        'merchantName': value['merchantName'],
    };
}

