import { Box, Typography } from "@mui/material";
import React from "react";
import { Variant } from "@mui/material/styles/createTypography";

const ItemWithPriceLine = ({
                             label,
                             amount,
                             caption,
                             action,
                             labelWeight = "normal",
                             textColor = "text.primary",
                             textVariant = "body1",
                             captionVariant = "body2",
                             amountWeight = "normal",
                             sx = {}
                           }: ItemWithPriceLinePropTypes) => (
  <Box display="flex" flexDirection="column" sx={sx}>
    <Box display="flex" justifyContent="space-between">
      <Typography variant={textVariant} fontWeight={labelWeight} color={textColor}>
        {label}
      </Typography>
      <Typography variant={textVariant} fontWeight={amountWeight} color={textColor}>
        {amount}
      </Typography>
    </Box>
    {(caption || action) && (
      <Box display="flex" justifyContent="space-between">
        <Typography variant={captionVariant} color="text.secondary" style={{ fontWeight: "lighter" }}>
          {caption}
        </Typography>
        <div>{action}</div>
      </Box>
    )}
  </Box>
);

type ItemWithPriceLinePropTypes = {
  label: string,
  amount: string | undefined,
  caption?: string | undefined,
  labelWeight?: string | undefined,
  amountWeight?: string | undefined,
  textColor?: string | undefined
  textVariant?: Variant
  captionVariant?: Variant
  sx?: any
  action?: any
};

export default ItemWithPriceLine;
