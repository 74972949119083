import { Box, Chip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { MachineType } from "../../api/gen";
import { useTranslation } from "react-i18next";
import "../../i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
  },
  chips: {
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: 0.5,
    },
  },
}));

type FiltersProps = {
  filters: Array<Filter>;
  selectedId?: MachineType;
  onSelect: (filter: Filter) => void;
};

export type Filter = {
  id: MachineType;
  label: string;
};

const Filters = ({ filters, selectedId, onSelect }: FiltersProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getTranslatedLabel = (label: string) => {
    switch (label) {
      case "Washer":
        return t("Washer");
      case "Dryer":
        return t("Dryer");
      case "Services":
        return t("Services");
      case "Token":
        return t("Token");
      case "Soap":
        return t("Soap");
      case "Massage Chair":
        return t("Massage-Chair");
      case "Shower":
        return t("Shower");
      case "Arcade Machine":
        return t("Arcade-Machine");
      case "Game Machine":
        return t("Game-Machine");
      case "Milk Dispenser":
        return t("Milk-Dispenser");
      default:
        return t("Other");
    }
  };

  return (
    <Box className={classes.root} px={4} py={1} flexDirection="column">
      <Box display="flex">
        <Typography component="span" variant="caption">
          {t("Filters")}
        </Typography>
      </Box>
      <Box className={classes.chips}>
        {filters.map((filter, i) => {
          const label = getTranslatedLabel(filter.label);
          return <Chip label={label} key={i} sx={{ margin: "2px" }} color={filter.id === selectedId ? "primary" : "default"} onClick={() => onSelect(filter)} />;
        })}
      </Box>
    </Box>
  );
};

export default Filters;
