import React, { useEffect, useState } from "react";
import { DigitalWalletType, Token } from "../../../../models";
import GooglePayButtonWrapper from "../common/GooglePayButtonWrapper";
import ApplePayButton from "../common/ApplePayButton";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { PaymentTokenType } from "../../../../api/gen";
import { useLocale, usePaymentMethods } from "../../../../hooks";
import { ApplePaySessionApi, DefaultApplePaySessionApi } from "../../../../api/applePaySessionApi";

const CardConnectPaymentButton = ({ onTokenReceived, amount, digitalWalletType }: CardConnectPaymentButtonPropTypes) => {
  const { applePaySettings, googlePaySettings, isProduction } = usePaymentMethods();
  const locale = useLocale();
  const [applePaySessionApi, setApplePaySessionApi] = useState<ApplePaySessionApi>();

  useEffect(() => {
    if (applePaySettings) {
      setApplePaySessionApi(new DefaultApplePaySessionApi(applePaySettings.merchantId));
    }
  }, [applePaySettings]);

  const handleTokenReceived = (paymentData: ApplePayJS.ApplePayPayment | google.payments.api.PaymentData) => {
    let token: Token;
    if (digitalWalletType === DigitalWalletType.APPLE_PAY) {
      const appleData = paymentData as ApplePayJS.ApplePayPayment;

      token = {
        token: JSON.stringify(appleData?.token),
        tokenMeta: { tokenType: PaymentTokenType.TransactionUsaV2, digitalWalletType: DigitalWalletType.APPLE_PAY, email: appleData?.shippingContact?.emailAddress }
      };
    } else {
      const googleData = paymentData as google.payments.api.PaymentData;

      token = {
        token: googleData.paymentMethodData.tokenizationData.token,
        tokenMeta: {
          tokenType: PaymentTokenType.TransactionUsaV2,
          cardLast4: googleData.paymentMethodData.info?.cardDetails,
          digitalWalletType: DigitalWalletType.GOOGLE_PAY,
          email: googleData.email
        }
      };
    }

    onTokenReceived(token);
  };

  // Try Apple first. If that works, use it. Otherwise use Google Pay
  if (digitalWalletType === DigitalWalletType.APPLE_PAY && applePaySessionApi) {
    return (
      <ApplePayButton
        locale={locale}
        onPaymentDataReceived={handleTokenReceived}
        amount={amount}
        applePaySettings={applePaySettings!}
        applePaySessionApi={applePaySessionApi}
        supportsAmex={true}
      />
    );
  }

  return (
    <GooglePayButtonWrapper
      isProduction={isProduction}
      amount={amount}
      onPaymentDataReceived={handleTokenReceived}
      googlePaySettings={googlePaySettings!}
      locale={locale}
      supportsAmex={true}
    />
  );
};

type CardConnectPaymentButtonPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  amount: number;
  digitalWalletType: DigitalWalletType
};

export default CardConnectPaymentButton;
