/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoyaltyProgramType } from './LoyaltyProgramType';
import {
    LoyaltyProgramTypeFromJSON,
    LoyaltyProgramTypeFromJSONTyped,
    LoyaltyProgramTypeToJSON,
} from './LoyaltyProgramType';

/**
 * 
 * @export
 * @interface LoyaltyModel
 */
export interface LoyaltyModel {
    /**
     * 
     * @type {LoyaltyProgramType}
     * @memberof LoyaltyModel
     */
    type: LoyaltyProgramType;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyModel
     */
    currentBalance: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyModel
     */
    pointsEarnedThisOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyModel
     */
    pointsRedeemedThisOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyModel
     */
    availableBalance: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyModel
     */
    minimumPointsBeforeAvailable: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyModel
     */
    availableBalanceValue: number;
}

/**
 * Check if a given object implements the LoyaltyModel interface.
 */
export function instanceOfLoyaltyModel(value: object): value is LoyaltyModel {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('currentBalance' in value) || value['currentBalance'] === undefined) return false;
    if (!('availableBalance' in value) || value['availableBalance'] === undefined) return false;
    if (!('minimumPointsBeforeAvailable' in value) || value['minimumPointsBeforeAvailable'] === undefined) return false;
    if (!('availableBalanceValue' in value) || value['availableBalanceValue'] === undefined) return false;
    return true;
}

export function LoyaltyModelFromJSON(json: any): LoyaltyModel {
    return LoyaltyModelFromJSONTyped(json, false);
}

export function LoyaltyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyModel {
    if (json == null) {
        return json;
    }
    return {
        
        'type': LoyaltyProgramTypeFromJSON(json['type']),
        'name': json['name'],
        'currentBalance': json['currentBalance'],
        'pointsEarnedThisOrder': json['pointsEarnedThisOrder'] == null ? undefined : json['pointsEarnedThisOrder'],
        'pointsRedeemedThisOrder': json['pointsRedeemedThisOrder'] == null ? undefined : json['pointsRedeemedThisOrder'],
        'availableBalance': json['availableBalance'],
        'minimumPointsBeforeAvailable': json['minimumPointsBeforeAvailable'],
        'availableBalanceValue': json['availableBalanceValue'],
    };
}

export function LoyaltyModelToJSON(value?: LoyaltyModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': LoyaltyProgramTypeToJSON(value['type']),
        'name': value['name'],
        'currentBalance': value['currentBalance'],
        'pointsEarnedThisOrder': value['pointsEarnedThisOrder'],
        'pointsRedeemedThisOrder': value['pointsRedeemedThisOrder'],
        'availableBalance': value['availableBalance'],
        'minimumPointsBeforeAvailable': value['minimumPointsBeforeAvailable'],
        'availableBalanceValue': value['availableBalanceValue'],
    };
}

