import { usePaymentMethods } from "../../../../hooks";
import { PaymentChannel } from "../../../../api/gen";
import { Box } from "@mui/material";
import a_mc_v from "../../assets/amex_mastercard_visa.png";
import mc_v from "../../assets/mastercard_visa.png";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../i18n";

function supportsAmex(pc?: PaymentChannel) {
  return pc?.configOptions?.find((o) => o.key === "SupportsAmex")?.value?.toLowerCase() === "true";
}

export const CardAcceptanceMarks = () => {
  const paymentMethods = usePaymentMethods();
  const isAmexSupported = supportsAmex(paymentMethods.cardChannel);
  const { t } = useTranslation();

  return (
    <Box margin={"auto"}>
      {isAmexSupported && <img src={a_mc_v} width={"123px"} height={"40px"} alt={t("Accepts-Card-Types-1")} />}
      {!isAmexSupported && <img src={mc_v} width={"80px"} height={"40px"} alt={"Accepts-Card-Types-2"} />}
    </Box>
  );
};
