import React from "react";
import { Alert } from "@mui/material";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { useTranslation } from "react-i18next";
import "../../../i18n";

export const PromoAlert: React.FC<{ promoCode: string; discount: string }> = (props) => {
  const { t } = useTranslation();
  return (
    <Alert icon={<LoyaltyIcon fontSize={"small"} sx={{ color: "white" }} />} sx={{ backgroundColor: "#e85fbf", color: "white" }}>
      {t("Promo-Alert", { promoCode: props.promoCode, discount: props.discount })}
    </Alert>
  );
};
