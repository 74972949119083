import { ApplePaySessionApi } from "../../../../api/applePaySessionApi";

export class ApplePayHelper {
  readonly applePaySessionApi: ApplePaySessionApi;

  constructor(applePaySessionApi: ApplePaySessionApi) {
    this.applePaySessionApi = applePaySessionApi;
  }

  private validateMerchantEventHandler = async (e: ApplePayJS.ApplePayValidateMerchantEvent, clientSession: ApplePaySession) => {
    console.debug("Preparing to validate merchant using URL " + e.validationURL);
    const merchantSession = await this.applePaySessionApi.startSession(e.validationURL);
    clientSession.completeMerchantValidation(merchantSession);
  };

  private paymentAuthorisedEventHandler = async (e: ApplePayJS.ApplePayPaymentAuthorizedEvent, clientSession: ApplePaySession, onTokenCreated: ApplePayPaymentEvent) => {
    try {
      await onTokenCreated(e.payment);
      // eslint-disable-next-line no-undef
      clientSession.completePayment(ApplePaySession.STATUS_SUCCESS);
    } catch (e) {
      console.error(e);
      // eslint-disable-next-line no-undef
      clientSession.completePayment(ApplePaySession.STATUS_FAILURE);
    }
  };

  public static isAvailable(): boolean {
    // eslint-disable-next-line no-undef
    // @ts-ignore - the ApplePaySession is available in Safari only
    return window.ApplePaySession && ApplePaySession.canMakePayments();
  }

  public static checkApplePayAvailable(merchantId: string) {
    return new Promise<boolean>((resolve, reject) => {
      if (ApplePayHelper.isAvailable()) {
        console.debug(`Apple Pay might be available, performing deeper test using merchant ID ${merchantId}`);
        // eslint-disable-next-line no-undef
        ApplePaySession.canMakePaymentsWithActiveCard(merchantId)
          .then((r) => {
            console.info(`Apple Pay is ${r ? "" : "NOT "}available`);
            resolve(r);
          })
          .catch((ex) => {
            console.warn(`Exception when setting up Apple Pay: ${ex.message}`);
            reject();
          });
      } else {
        console.info(`Apple Pay is NOT available because the browser does not support it`);
        resolve(false);
      }
    });
  }

  /**
   * Collect payment using Apple Pay
   * @param {ApplePayContext} applePayContext
   * @param {string} merchantId the Apple Pay Merchant ID to use
   * @param {function} onTokenCreated
   */
  public collectPayment = (applePayContext: ApplePayContext, merchantId: string, onTokenCreated: ApplePayPaymentEvent) => {
    const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
      requiredShippingContactFields: ["email", "name"],
      requiredBillingContactFields: [],
      supportedNetworks: applePayContext.supportedNetworks, // ["masterCard", "visa", "amex"],
      merchantCapabilities: ["supports3DS"],
      countryCode: applePayContext.countryCode,
      currencyCode: applePayContext.currencyCode,
      total: {
        type: "final",
        amount: applePayContext.amount,
        label: applePayContext.label
      }
    };

    // eslint-disable-next-line no-undef
    const clientSession = new ApplePaySession(1, paymentRequest);

    // called when the payment sheet is opened. Apple will make a call to our server and authenticate to start a merchant session
    clientSession.onvalidatemerchant = (e) => this.validateMerchantEventHandler(e, clientSession);
    // called then the user authorises the payment (with their thumb, face or whatever)
    clientSession.onpaymentauthorized = (e) => this.paymentAuthorisedEventHandler(e, clientSession, onTokenCreated);
    // start the session
    clientSession.begin();
  };
}

export class ApplePayContext {
  currencyCode;
  countryCode;
  amount;
  label;
  supportedNetworks;

  constructor(currencyCode: string, countryCode: string, amount: string, label: string, supportedNetworks: string[]) {
    this.currencyCode = currencyCode;
    this.countryCode = countryCode;
    this.amount = amount;
    this.label = label;
    this.supportedNetworks = supportedNetworks;
  }
}

export type ApplePayPaymentEvent = (e: ApplePayJS.ApplePayPayment) => void;
