import { SiteConfig, SitesApi, SitesGetSiteConfigRequest } from "./gen";
import { defaultConfiguration } from "./apiUtils";

const SITES_API = new SitesApi(defaultConfiguration);

export function getSiteConfig(siteId: string): Promise<SiteConfig> {
  console.info("Get site config for " + siteId);

  return SITES_API.sitesGetSiteConfig({
    siteId
  } as SitesGetSiteConfigRequest);
}

export async function getMachineStatus(siteId: string, machineId: string) {
  console.info("Get machine status for site " + siteId + " and machine " + machineId);

  return SITES_API.sitesGetMachineStatus({
    siteId,
    machineId
  });
}

export async function getFaqs(siteId: string) {
  console.info("Get FAQs for site " + siteId);

  return SITES_API.sitesGetFaqs({
    siteId
  });
}
