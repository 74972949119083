import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { IconButton, Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import { LabelledNumber } from "../../../models";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const boxStyle = {
  display: "flex",
  justifyContent: "space-between",
  border: "1px solid gray",
  borderRadius: "25px",
  alignItems: "center",
};

const makeProgressBar = (value: number, maxValue: number, theme: Theme) => {
  const percent = (value / maxValue) * 100;
  return {
    ...boxStyle,
    ...{
      background: `linear-gradient(90deg, ${theme.palette.secondary.light} ${percent}%, ${theme.palette.common.white} ${percent}%)`,
    },
  };
};

const RangeSelectionControl = ({ value, values, onChange }: RangeSelectionControlProps) => {
  const currentValue = values[values.findIndex((v) => v.value === value)];
  const { t } = useTranslation();

  if (!currentValue) {
    console.warn(`Configuration error. Value ${value} was not found in ${values.map((v) => v.value)}`);
  }

  const theme = useTheme();
  const gradientBoxStyle = makeProgressBar(currentValue.value, values.slice(-1)[0].value, theme);

  const decrement = () => {
    let indexOfCurrentValue = values.findIndex((v) => v.value === currentValue.value);
    if (indexOfCurrentValue > 0) {
      indexOfCurrentValue = indexOfCurrentValue - 1;
      onChange(values[indexOfCurrentValue].value);
    }
  };

  const increment = () => {
    let indexOfCurrentValue = values.findIndex((v) => v.value === currentValue.value);
    if (indexOfCurrentValue < values.length - 1) {
      indexOfCurrentValue = indexOfCurrentValue + 1;
      onChange(values[indexOfCurrentValue].value);
    }
  };

  const textColor = theme.palette.secondary.contrastText;
  const labelText = currentValue.label.includes("minutes") ? currentValue.label.replace("minutes", t("Minutes")) : currentValue.label;

  return (
    <div style={gradientBoxStyle}>
      <IconButton style={{ padding: "8px" }} color="default" aria-label="decrease" onClick={decrement} size="large">
        <RemoveCircle style={{ color: textColor }} />
      </IconButton>

      <div>
        <Typography style={{ color: textColor }}>{labelText}</Typography>
      </div>

      <IconButton style={{ padding: "8px" }} color="secondary" aria-label="decrease" onClick={increment} size="large">
        <AddCircle style={{ color: textColor }} />
      </IconButton>
    </div>
  );
};

type RangeSelectionControlProps = {
  values: Array<LabelledNumber>;
  value: number;
  onChange: (value: number) => void;
};

export default RangeSelectionControl;
