import MenuIcon from "@mui/icons-material/Menu";
import { Avatar } from "@mui/material";
import React from "react";

const HamburgerButton = ({ onClick, show }: MenuBarPropTypes) => {
  return (
    <Avatar sx={{ bgcolor: "background.paper", border: "0.1px solid lightgray", zIndex: 12, ...(!show && { display: "none" }) }} onClick={onClick}>
      <MenuIcon sx={{ color: "common.black" }} />
    </Avatar>
  );
};

type MenuBarPropTypes = {
  onClick: () => void,
  show: boolean,
};

export default HamburgerButton;
