import { TokenReceivedCallback } from "../../CheckoutPage";
import * as MyPOSEmbedded from "mypos-embedded-checkout";
import { CallbackParams, CartItem } from "mypos-embedded-checkout";
import { useEffect, useState } from "react";
import { useLocale, usePaymentMethods, useSiteConfig } from "../../../../hooks";
import { v4 as uuidv4 } from "uuid";
import { Cart } from "../../../../models";
import { AmountsModel, PaymentTokenType } from "../../../../api/gen";
import { ControlMountedCallback } from "../common/controlMountedCallback";
import PaymentParams = MyPOSEmbedded.PaymentParams;

const MyPosEmbeddedPayment = ({ amounts, onTokenReceived, cart, onElementMounted }: MyPosEmbeddedPaymentPropTypes) => {
  const [iframeDisplayed, setIframeAddedToDom] = useState(false);
  const locale = useLocale();
  const { siteConfig } = useSiteConfig();

  const { cardChannel } = usePaymentMethods();

  useEffect(() => {
    if (!amounts || iframeDisplayed || !cardChannel) {
      return;
    }

    const cartItems = cart.machineCredits.map(mc => ({ article: mc.machine.name, currency: locale.currencyCode, price: mc.amount, quantity: 1 } as CartItem));
    if (amounts.totalFee > 0) {
      cartItems.push({ article: siteConfig?.transactionFeeLabel ?? "Service fee", currency: locale.currencyCode, price: amounts.totalFee, quantity: 1 });
    }
    if (amounts.loyaltyDiscount + amounts.siteDiscount + amounts.promoDiscount > 0) {
      cartItems.push({ article: "Discount", currency: locale.currencyCode, price: (amounts.loyaltyDiscount + amounts.siteDiscount + amounts.promoDiscount) * -1, quantity: 1 });
    }

    const paymentParams = {
      sid: cardChannel.configOptions.find(co => co.key === "StoreId")?.value,
      ipcLanguage: locale.languageTag.substring(0, 1), // first 2 chars of the language tag (en-UK and en-IE becomes en, fr-FR and fr-BE and fr-CH become fr, etc)
      walletNumber: cardChannel.applicationId,
      amount: amounts?.grandTotal,
      currency: locale.currencyCode,
      orderID: `${siteConfig?.siteId}|${uuidv4()}`, // the pipe is received by the API and is used as a delimiter, don't change it.
      urlNotify: cardChannel.configOptions.find(co => co.key === "PaymentCallbackUrl")?.value,
      urlOk: window.location.href, // Optional - required only for iDeal
      urlCancel: window.location.href, // Optional - required only for iDeal
      keyIndex: 1,
      cardTokenRequest: 2,
      paymentParametersRequired: 3,
      cartItems: cartItems
    } as PaymentParams;

    const callbackParams = {
      isSandbox: true,
      onSuccess: function(data) {
        console.log(data);
        onTokenReceived({
          token: data.OrderID,
          tokenMeta: {
            tokenType: PaymentTokenType.TransactionEuV1EComm,
            rememberCard: false
          }
        });
      },
      onError: function() {
        console.log("error");
      }
    } as CallbackParams;

    MyPOSEmbedded.createPayment("embeddedCheckout", paymentParams, callbackParams);
    setIframeAddedToDom(true);

    // CHORE it would be better to know that the content in the iframe has loaded.
    onElementMounted(true);
  }, [amounts, cardChannel, cart.machineCredits, iframeDisplayed, locale.currencyCode, locale.languageTag, onElementMounted, onTokenReceived, siteConfig]);

  return <div id={"embeddedCheckout"}></div>;

};


type MyPosEmbeddedPaymentPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  onElementMounted: ControlMountedCallback;
  amounts?: AmountsModel;
  cart: Cart;
};

export default MyPosEmbeddedPayment;