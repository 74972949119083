/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplePaySettings
 */
export interface ApplePaySettings {
    /**
     * 
     * @type {string}
     * @memberof ApplePaySettings
     */
    merchantId: string;
    /**
     * 
     * @type {string}
     * @memberof ApplePaySettings
     */
    merchantName: string;
}

/**
 * Check if a given object implements the ApplePaySettings interface.
 */
export function instanceOfApplePaySettings(value: object): value is ApplePaySettings {
    if (!('merchantId' in value) || value['merchantId'] === undefined) return false;
    if (!('merchantName' in value) || value['merchantName'] === undefined) return false;
    return true;
}

export function ApplePaySettingsFromJSON(json: any): ApplePaySettings {
    return ApplePaySettingsFromJSONTyped(json, false);
}

export function ApplePaySettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplePaySettings {
    if (json == null) {
        return json;
    }
    return {
        
        'merchantId': json['merchantId'],
        'merchantName': json['merchantName'],
    };
}

export function ApplePaySettingsToJSON(value?: ApplePaySettings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'merchantId': value['merchantId'],
        'merchantName': value['merchantName'],
    };
}

