import React from "react";
import GooglePayButtonWrapper from "../common/GooglePayButtonWrapper";
import { DigitalWalletType, Token } from "../../../../models";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { useLocale, usePaymentMethods } from "../../../../hooks";
import { GooglePaySettings, PaymentTokenType } from "../../../../api/gen";

const StripeGooglePayButton = ({ amount, onTokenReceived }: StripeGooglePayButtonPropTypes) => {
  const locale = useLocale();
  const { isProduction, digitalWalletChannel } = usePaymentMethods();

  const handleTokenReceived = (paymentData: google.payments.api.PaymentData) => {
    console.debug("Stripe token result (via Google Pay)", paymentData);
    const stripeTokenAsString = paymentData.paymentMethodData.tokenizationData.token;
    const stripeToken = JSON.parse(stripeTokenAsString);

    const token = {
      token: stripeToken.id,
      tokenMeta: {
        tokenType: PaymentTokenType.StripeNonce,
        cardLast4: paymentData.paymentMethodData.info?.cardDetails,
        digitalWalletType: DigitalWalletType.GOOGLE_PAY,
        email: paymentData.email
      }
    } as Token;

    onTokenReceived(token);
  };

  const stripeGooglePaySettings = {
    gatewayId: "stripe",
    merchantName: "Tangerpay",
    merchantId: digitalWalletChannel?.applicationId
  } as GooglePaySettings;

  return (
    <GooglePayButtonWrapper
      isProduction={isProduction}
      amount={amount}
      onPaymentDataReceived={handleTokenReceived}
      googlePaySettings={stripeGooglePaySettings}
      locale={locale}
      supportsAmex={true}
    />
  );
};

type StripeGooglePayButtonPropTypes = {
  onTokenReceived: TokenReceivedCallback;
  amount: number;
};

export default React.memo(StripeGooglePayButton);
