/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SiteStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    Trial: 'Trial',
    Closed: 'Closed'
} as const;
export type SiteStatus = typeof SiteStatus[keyof typeof SiteStatus];


export function instanceOfSiteStatus(value: any): boolean {
    for (const key in SiteStatus) {
        if (Object.prototype.hasOwnProperty.call(SiteStatus, key)) {
            if ((SiteStatus as Record<string, SiteStatus>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SiteStatusFromJSON(json: any): SiteStatus {
    return SiteStatusFromJSONTyped(json, false);
}

export function SiteStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteStatus {
    return json as SiteStatus;
}

export function SiteStatusToJSON(value?: SiteStatus | null): any {
    return value as any;
}

