import React, { useEffect, useState } from "react";
import { getMachineStatus } from "../../../api/siteApi";
import MachineStatusView, { MachineStatus } from "./MachineStatusView";
import { CycleStatus, Machine, MachineReadiness } from "../../../api/gen";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const MachineStatusChecker = ({ machines, siteId, show, onBackToSelection, onMachineTestingSuccess }: MachineStatusCheckerPropTypes) => {
  const [machineStatuses, setMachineStatuses] = useState(machines.map((m) => new MachineStatus(m, MachineReadiness.NotChecked)));
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const generateMessageToUser = (hasError: boolean, anyMachineBusy: boolean) => {
    if (isTesting) {
      return message;
    } else if (hasError) {
      return t("Machine-Status-Message-1");
    } else if (anyMachineBusy) {
      return t("Machine-Status-Message-2");
    } else {
      return "";
    }
  };

  useEffect(() => {
    machines.forEach((machine) => {
      getMachineStatus(siteId, machine.id).then((stat) => {
        setMachineStatuses((prevStatuses) => [...prevStatuses.filter((ms) => ms.machine.id !== machine.id), new MachineStatus(machine, stat.status, stat.cycleInfo)]);
      });
    });

    const timer1 = setTimeout(() => {
      setMessage(t("Machine-Status-Timeout-Message-1"));
    }, 10000);
    const timer2 = setTimeout(() => {
      setMessage(t("Machine-Status-Timeout-Message-2"));
    }, 30000);
    const timer3 = setTimeout(() => {
      setMessage(t("Machine-Status-Timeout-Message-3"));
    }, 50000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once only

  // Auto-dispose if all machines are available and we don't think they are running
  if (machineStatuses.every((ms) => ms.status === MachineReadiness.Available && ms.cycleInfo?.cycleStatus !== CycleStatus.Running)) {
    onMachineTestingSuccess && onMachineTestingSuccess();
  }

  const hasError = machineStatuses.some((ms) => ms.status === MachineReadiness.Error || ms.status === MachineReadiness.Unavailable);
  const isTesting = machineStatuses.some((ms) => ms.status === MachineReadiness.NotChecked);
  const anyMachineBusy = !hasError && machineStatuses.some((ms) => ms.cycleInfo?.cycleStatus === CycleStatus.Running || ms.status === MachineReadiness.EndOfCycle);
  const messageToUser = generateMessageToUser(hasError, anyMachineBusy);

  return (
    <MachineStatusView
      showDialog={show}
      machineStatuses={machineStatuses}
      onBackToSelection={onBackToSelection}
      showButtons={hasError || anyMachineBusy}
      message={messageToUser}
      allowContinueButton={anyMachineBusy}
      onContinue={onMachineTestingSuccess}
    />
  );
};

type MachineStatusCheckerPropTypes = {
  machines: Array<Machine>;
  siteId: string;
  onMachineTestingSuccess: () => void;
  onBackToSelection: () => void;
  show: boolean;
};

export default MachineStatusChecker;
