/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReminderResponse
 */
export interface ReminderResponse {
    /**
     * 
     * @type {string}
     * @memberof ReminderResponse
     */
    reminderPhoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof ReminderResponse
     */
    durationInMinutes: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReminderResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReminderResponse
     */
    message?: string;
}

/**
 * Check if a given object implements the ReminderResponse interface.
 */
export function instanceOfReminderResponse(value: object): value is ReminderResponse {
    if (!('reminderPhoneNumber' in value) || value['reminderPhoneNumber'] === undefined) return false;
    if (!('durationInMinutes' in value) || value['durationInMinutes'] === undefined) return false;
    if (!('success' in value) || value['success'] === undefined) return false;
    return true;
}

export function ReminderResponseFromJSON(json: any): ReminderResponse {
    return ReminderResponseFromJSONTyped(json, false);
}

export function ReminderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReminderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'reminderPhoneNumber': json['reminderPhoneNumber'],
        'durationInMinutes': json['durationInMinutes'],
        'success': json['success'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function ReminderResponseToJSON(value?: ReminderResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reminderPhoneNumber': value['reminderPhoneNumber'],
        'durationInMinutes': value['durationInMinutes'],
        'success': value['success'],
        'message': value['message'],
    };
}

