import { handleResponse, logResponse } from "./apiUtils";
import { ApplePaySessionApi } from "./applePaySessionApi";

const PRODUCTION_BASE_URL = "https://wallet.dcap.com";
const CERTIFICATION_BASE_URL = "https://wallet-cert.dcap.com";

interface TokenResponse {
  Token?: string;
  Brand?: string;
  ExpirationMonth?: string;
  ExpirationYear?: string;
  Last4?: string;
  Error?: string;
}

export class DatacapApi implements ApplePaySessionApi {
  private xsrfHeader: any;
  private readonly baseUrl: string;
  private readonly isProduction: boolean;
  private readonly tokenKey: string;
  private readonly storeName: string;
  private readonly merchantId: string;

  constructor(tokenKey: string, isProduction: boolean, merchantId: string, storeName: string) {
    this.isProduction = isProduction;
    this.baseUrl = isProduction ? PRODUCTION_BASE_URL : CERTIFICATION_BASE_URL;
    this.tokenKey = tokenKey;
    this.storeName = storeName;
    this.merchantId = merchantId;
    this.configureXsrfTokens().then((xsrfHeader) => (this.xsrfHeader = xsrfHeader));
  }

  startSession(validationUrl: string) {
    const body = JSON.stringify({
      validationUrl: validationUrl,
      merchantStoreName: this.storeName,
      appleMerchantID: this.merchantId,
      merchantHostName: window.location.hostname,
    });

    console.info("Sending to Apple Pay Session API " + body);

    return fetch(`${this.baseUrl}/applepay/validate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    })
      .then(handleResponse)
      .then(logResponse);
  }

  /**
   * @param {ApplePayJS.ApplePayPayment} paymentData The raw payment token data from Apple
   * @param {string} tokenKey A key issued by Datacap that represents the merchant
   * @return {Promise<object>}
   */
  async exchangeAppleTokenWithPaymentTokenAsync(paymentData: ApplePayJS.ApplePayPayment): Promise<TokenResponse> {
    console.info("Exchanging Apple token with Datacap token");

    const headerDict = {
      ...this.xsrfHeader,
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
    };

    const request = {
      token: paymentData.token,
      tokenKey: this.tokenKey,
    };
    
    return await fetch(`${this.baseUrl}/applepay/tokenize`, {
      method: "POST",
      headers: new Headers(headerDict),
      body: JSON.stringify(request),
    })
      .then(handleResponse)
      .then(logResponse)
      .then((responseJson) => {
        return responseJson as TokenResponse;
      });
  }

  private supports3DS(tokenKey: string) {
    const headerDict = {
      ...this.xsrfHeader,
      Accept: "text/plain",
      "Access-Control-Allow-Headers": "Content-Type",
    };
    return fetch(`${this.baseUrl}/applepay/supports3ds/${tokenKey}`, { headers: new Headers(headerDict) })
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
      })
      .then((responseText) => responseText === "true");
  }

  private configureXsrfTokens() {
    const xsrfTokenValueRegex = /xAntiforgeryToken="([A-Za-z0-9-_]*)"/;
    const xsrfTokenNameRegex = /xAntiforgeryName="([A-Za-z0-9-_]*)"/;

    return fetch(`${this.baseUrl}/v1/client/applepay`)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw Error("Unable to fetch Datacap Apple Pay JS");
        }
      })
      .then((text) => {
        const stripped = text.replaceAll(/\s/g, "");
        const nameMatch = stripped.match(xsrfTokenNameRegex);
        const valueMatch = stripped.match(xsrfTokenValueRegex);

        if (nameMatch && valueMatch && nameMatch.length > 1 && valueMatch.length > 1) {
          console.debug(`Datacap XSRF token data: {${nameMatch[1]}: ${valueMatch[1]}`);
          return { [nameMatch[1]]: valueMatch[1] };
        } else {
          throw Error("Unable to locate XSRF header or value");
        }
      });
  }

  async exchangeGoogleTokenWithPaymentTokenAsync(paymentData: google.payments.api.PaymentData) {
    console.info("Exchanging Google token with Datacap token");

    const headerDict = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
    };

    const request = {
      token: {
        payload: paymentData.paymentMethodData.tokenizationData.token,
      },
      tokenKey: this.tokenKey,
    };

    return await fetch(`${this.baseUrl}/googlepay/tokenize`, {
      method: "POST",
      headers: new Headers(headerDict),
      body: JSON.stringify(request),
    })
      .then(handleResponse)
      .then(logResponse)
      .then((responseJson) => {
        return responseJson as TokenResponse;
      });
  }
}
