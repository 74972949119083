/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MachineReadiness = {
    Available: 'Available',
    EndOfCycle: 'EndOfCycle',
    Unavailable: 'Unavailable',
    Error: 'Error',
    NotChecked: 'NotChecked',
    Unstable: 'Unstable'
} as const;
export type MachineReadiness = typeof MachineReadiness[keyof typeof MachineReadiness];


export function instanceOfMachineReadiness(value: any): boolean {
    for (const key in MachineReadiness) {
        if (Object.prototype.hasOwnProperty.call(MachineReadiness, key)) {
            if ((MachineReadiness as Record<string, MachineReadiness>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MachineReadinessFromJSON(json: any): MachineReadiness {
    return MachineReadinessFromJSONTyped(json, false);
}

export function MachineReadinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineReadiness {
    return json as MachineReadiness;
}

export function MachineReadinessToJSON(value?: MachineReadiness | null): any {
    return value as any;
}

