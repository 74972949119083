/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VendAttemptResult } from './VendAttemptResult';
import {
    VendAttemptResultFromJSON,
    VendAttemptResultFromJSONTyped,
    VendAttemptResultToJSON,
} from './VendAttemptResult';

/**
 * 
 * @export
 * @interface MachineCreditResultModel
 */
export interface MachineCreditResultModel {
    /**
     * 
     * @type {number}
     * @memberof MachineCreditResultModel
     */
    vendAmount: number;
    /**
     * 
     * @type {string}
     * @memberof MachineCreditResultModel
     */
    machineId: string;
    /**
     * 
     * @type {VendAttemptResult}
     * @memberof MachineCreditResultModel
     */
    vendAttemptResult: VendAttemptResult;
    /**
     * 
     * @type {string}
     * @memberof MachineCreditResultModel
     */
    machineName: string;
    /**
     * 
     * @type {number}
     * @memberof MachineCreditResultModel
     */
    cycleTime?: number;
}

/**
 * Check if a given object implements the MachineCreditResultModel interface.
 */
export function instanceOfMachineCreditResultModel(value: object): value is MachineCreditResultModel {
    if (!('vendAmount' in value) || value['vendAmount'] === undefined) return false;
    if (!('machineId' in value) || value['machineId'] === undefined) return false;
    if (!('vendAttemptResult' in value) || value['vendAttemptResult'] === undefined) return false;
    if (!('machineName' in value) || value['machineName'] === undefined) return false;
    return true;
}

export function MachineCreditResultModelFromJSON(json: any): MachineCreditResultModel {
    return MachineCreditResultModelFromJSONTyped(json, false);
}

export function MachineCreditResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineCreditResultModel {
    if (json == null) {
        return json;
    }
    return {
        
        'vendAmount': json['vendAmount'],
        'machineId': json['machineId'],
        'vendAttemptResult': VendAttemptResultFromJSON(json['vendAttemptResult']),
        'machineName': json['machineName'],
        'cycleTime': json['cycleTime'] == null ? undefined : json['cycleTime'],
    };
}

export function MachineCreditResultModelToJSON(value?: MachineCreditResultModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vendAmount': value['vendAmount'],
        'machineId': value['machineId'],
        'vendAttemptResult': VendAttemptResultToJSON(value['vendAttemptResult']),
        'machineName': value['machineName'],
        'cycleTime': value['cycleTime'],
    };
}

