import { Box } from "@mui/material";
import * as React from "react";

const MenuButton = ({ children, onClick, startIcon, endIcon }: MenuButtonPropTypes) => (

  <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} color={"text.primary"} px={1} py={2} onClick={onClick}>
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
      <Box mr={3}>{startIcon}</Box>
      <Box>{children}</Box>
    </Box>
    <Box>{endIcon}</Box>
  </Box>

);

type MenuButtonPropTypes = {
  onClick?: () => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: string
}

export default MenuButton;