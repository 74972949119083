import { initialiseTill } from "../components/checkout/components/till/tillSdkProvider";
import { initialiseStripe } from "../components/checkout/components/stripe/stripeSdkProvider";
import { PaymentChannel, PaymentChannelIdentifier } from "../api/gen";
import { initialiseDatacap } from "../components/checkout/components/datacap/datacapSdkProvider";

const loadPaymentScriptsToBrowser = (isProduction: boolean, digitalWalletChannel?: PaymentChannel, cardChannel?: PaymentChannel) => {
  
  if (cardChannel?.type === PaymentChannelIdentifier.AuV3 || digitalWalletChannel?.type === PaymentChannelIdentifier.AuV3) {
    console.info("Loading Till script");
    initialiseTill(isProduction);
  }

  if (cardChannel?.type === PaymentChannelIdentifier.UsaV3 || digitalWalletChannel?.type === PaymentChannelIdentifier.UsaV3) {
    console.info("Loading Datacap script");
    initialiseDatacap(isProduction);
  }

  if (cardChannel?.type === PaymentChannelIdentifier.Stripe) {
    console.info("Loading Stripe script");
    initialiseStripe(cardChannel?.applicationId);
  } else if (digitalWalletChannel?.type === PaymentChannelIdentifier.Stripe) {
    console.info("Loading Stripe script");
    initialiseStripe(digitalWalletChannel?.applicationId);
  }
};

export default loadPaymentScriptsToBrowser;
