import React from "react";
import washer from "../../../assets/washer.svg";
import dryer from "../../../assets/dryer.svg";
import milkbottle from "../../../assets/milkbottle.svg";
import dogwash from "../../../assets/dogwash.svg";
import other from "../../../assets/coint-slot.svg";
import { MachineType } from "../../../api/gen";

const TYPE_TO_IMAGE = new Map<MachineType, any>();
TYPE_TO_IMAGE.set(MachineType.Washer, washer);
TYPE_TO_IMAGE.set(MachineType.Dryer, dryer);
TYPE_TO_IMAGE.set(MachineType.MilkDispenser, milkbottle);
TYPE_TO_IMAGE.set(MachineType.DogWash, dogwash);

const MachineTypeIcon = ({ machineType, width, isNotAvailable = false }: MachineTypeIconPropTypes) => {
  const img = TYPE_TO_IMAGE.get(machineType) || other;

  const grayStyle = isNotAvailable ? { filter: "grayscale(100%)", opacity: 0.4 } : {};

  return <img src={img} width={width} style={grayStyle} alt="Icon" />;
};

type MachineTypeIconPropTypes = {
  machineType: MachineType;
  width: string;
  isNotAvailable?: boolean;
};

export default MachineTypeIcon;
