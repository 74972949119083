import { PaymentOption } from "../models";

const resolvePreferredPaymentOption = () => {
  const preferredPaymentOption = localStorage.getItem("preferred_payment_method");
  switch (preferredPaymentOption) {
    case PaymentOption.PAYPAL:
      return PaymentOption.PAYPAL;
    case PaymentOption.DIGITAL_WALLET:
      return PaymentOption.DIGITAL_WALLET;
    case PaymentOption.CARD:
      return PaymentOption.CARD;
    default:
      return undefined;
  }
};

export class LocalSettingsService {
  /**
   * Get a user profile
   * @param siteId
   * @returns {UserProfile}
   */
  static forSite(siteId: string): UserProfile {
    return {
      id: LocalSettingsService.getId(),
      savedPromoCode: localStorage.getItem(siteId + ":promo_code") || undefined,
      preferredPaymentOption: resolvePreferredPaymentOption(),
    };
  }

  /**
   * @param paymentOption The payment method ID that will become the favorite. Set to null to remove it.
   */
  static setPreferredPaymentMethod(paymentOption: PaymentOption | null) {
    if (paymentOption) {
      localStorage.setItem("preferred_payment_method", paymentOption);
    } else {
      localStorage.removeItem("preferred_payment_method");
    }
  }

  /**
   * @param siteId the siteId for the promo code
   * @param code the promo or loyalty code to save. Set to null to remove it.
   */
  static setSavedCode(siteId: string, code: string | null) {
    if (code) {
      localStorage.setItem(siteId + ":promo_code", code);
    } else {
      localStorage.removeItem(siteId + ":promo_code");
    }
  }

  /**
   * Sets the user profile ID of this device.
   * @param id
   */
  static setId(id: string) {
    localStorage.setItem("userProfileId", id);
  }

  /**
   * Get the profile ID
   */
  static getId() {
    return localStorage.getItem("userProfileId");
  }
}

export type UserProfile = {
  id?: string | null;
  savedPromoCode?: string;
  preferredPaymentOption?: PaymentOption;
  emailAddress?: string;
};
