/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PromotionType = {
    PercentDiscount: 'PercentDiscount',
    DollarDiscount: 'DollarDiscount',
    NoDiscount: 'NoDiscount',
    PrepaidVoucher: 'PrepaidVoucher'
} as const;
export type PromotionType = typeof PromotionType[keyof typeof PromotionType];


export function instanceOfPromotionType(value: any): boolean {
    for (const key in PromotionType) {
        if (Object.prototype.hasOwnProperty.call(PromotionType, key)) {
            if ((PromotionType as Record<string, PromotionType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PromotionTypeFromJSON(json: any): PromotionType {
    return PromotionTypeFromJSONTyped(json, false);
}

export function PromotionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromotionType {
    return json as PromotionType;
}

export function PromotionTypeToJSON(value?: PromotionType | null): any {
    return value as any;
}

