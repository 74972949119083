import { KeyboardArrowLeft } from "@mui/icons-material";
import { Backdrop, Box, Button, Collapse, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteOrder, loadOrder } from "../../redux/actions/orderActions";
import ItemsWithPricePanel from "../common/itemised/ItemsWithPricePanel";
import { ItemWithPrice } from "../common/itemised/itemWithPrice";
import LoadingSplash from "../common/LoadingSplash";
import PaymentDetailsPart from "./parts/PaymentDetailsPart";
import ReceiptRequestPart from "./parts/ReceiptRequestPart";
import SmsReminderPart from "./parts/SmsReminderPart";
import ReceiptHeadline from "./parts/ReceiptHeadline";
import FeedbackButton from "./parts/FeedbackButton";
import LoyaltyRedemptionPanel from "./parts/LoyaltyRedemptionPanel";
import ReactGA from "react-ga4";
import LoyaltyPart from "./parts/LoyaltyPart";
import { deleteQuote } from "../../redux/actions/quoteActions";
import { FeedbackType, VendAttemptResult } from "../../api/gen";
import { useLocale, useSiteConfig } from "../../hooks";
import { useAppSelector } from "../../redux/hooks";
import { deleteMachineCredits } from "../../redux/actions/cartActions";
import { loadSiteConfig } from "../../redux/actions/siteConfigActions";
import { useTranslation } from "react-i18next";
import StartMachineInstructionsPart from "./parts/StartMachineInstructionsPart";
import "../../i18n";

const ReceiptPage = () => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { siteConfig } = useSiteConfig();
  const orderResponse = useAppSelector((state) => state.order);
  const sessionContext = useAppSelector((state) => state.sessionContext);
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { t } = useTranslation();

  if (!orderId) {
    navigate("/notfound");
  }

  const handleStartAgain = () => {
    // TODO should move some/all of these to the router so that the back button also clears the state
    // This issue came along where back button was pressed. There is additional code in MachineSelectionPage which duplicates this.
    const siteId = siteConfig?.siteId ?? "";
    dispatch(deleteMachineCredits());
    dispatch(deleteOrder());
    dispatch(deleteQuote());
    dispatch(loadSiteConfig(siteId)); // reload the site config in case machine status has changed.
    navigate(`/${siteId}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // coming from the payment screen can leve the scroll somewhere in the middle. Go back to top!
  }, []);

  const order = orderResponse.order;

  // Will load the order if it doesn't exist
  useEffect(() => {
    if (!order && siteConfig) {
      dispatch(loadOrder({ siteId: siteConfig.siteId, orderId: orderId! }));
    }
  }, [dispatch, orderId, order, siteConfig]);

  useEffect(() => {
    // If there is an error then just start again
    if (orderResponse.isError) {
      console.warn("A request was made for an order that doesn't exist");
      handleStartAgain();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderResponse]); // don't want the handleStartAgain to re-run this hook

  const lineItems =
    order?.credits?.map(
      (mc) =>
        ({
          id: mc.machineId,
          name: mc.machineName,
          amount: locale.formatCurrency(mc.vendAttemptResult === VendAttemptResult.Fail ? 0 : mc.vendAmount),
          caption: mc.vendAttemptResult === VendAttemptResult.Fail ? t("Machine-Error") : "",
        } as ItemWithPrice)
    ) || [];

  const [feedback, setFeedback] = useState<FeedbackType | undefined>(undefined);

  const handleFeedbackReceived = (feedback: FeedbackType) => setFeedback(feedback);

  if (orderResponse) {
    ReactGA.event({
      category: "Receipt",
      action: "Receipt screen opened",
    });
  }

  return (
    <>
      <Backdrop open={orderResponse.isLoading || !siteConfig?.siteId}>
        <LoadingSplash />
      </Backdrop>

      {!orderResponse.isLoading && !!siteConfig?.siteId && order && (
        <>
          <ReceiptHeadline machineCreditResults={order.credits} orderStatus={order.orderStatus} customerReference={order.customerReference} />

          <Collapse in={!feedback}>
            <Box display={"flex"} mx={2} mt={3}>
              <FeedbackButton
                orderId={order.orderId}
                storeEmail={siteConfig?.emailAddress}
                storePhoneNumber={siteConfig?.phoneNumber}
                onFeedbackReceived={handleFeedbackReceived}
              />
            </Box>
          </Collapse>

          {orderResponse.order?.isError === false && <StartMachineInstructionsPart />}

          {order.loyalty && (
            <Box display="flex" flexDirection="column" mx={2} mt={3}>
              <Paper elevation={1} sx={{ px: 2 }}>
                <LoyaltyPart />
              </Paper>
            </Box>
          )}

          {(order.loyalty?.availableBalance ?? 0) > 0 && (
            <Box display="flex" flexDirection="column" mt={3} mx={2}>
              <LoyaltyRedemptionPanel loyalty={order.loyalty!} locale={locale} />
            </Box>
          )}

          <Box display="flex" flexDirection="column" mx={2} mt={3}>
            <ReceiptRequestPart />
          </Box>

          {/*Only offer a reminder if the store supports optional or mandatory reminders*/}
          {/*Only offer a reminder if there is more than 5 minutes to go*/}
          {/*Only offer a reminder if the phone number was not already given to the Order API (for Kiosk Transition) */}
          {(siteConfig?.smsReminderMode === "OPTIONAL" || siteConfig?.smsReminderMode === "MANDATORY") && (order.minutesRemaining ?? 0) > 5 && !sessionContext.phoneNumber && (
            <Box display="flex" flexDirection="column" mt={3} mx={2}>
              <SmsReminderPart locale={locale} siteId={siteConfig?.siteId} orderId={orderId!} />
            </Box>
          )}

          <Box display="flex" flexDirection="column" mt={3} mx={2}>
            <Paper elevation={1} sx={{ px: 2 }}>
              <Typography variant={"h6"} pt={2} pb={0}>
                {t("Your-Order")}
              </Typography>
              <Typography variant={"caption"} color={"text.secondary"}>
                {locale.formatDateObject(order.orderTimestamp)}
              </Typography>
              <ItemsWithPricePanel items={lineItems} />
            </Paper>
          </Box>

          <Box display="flex" flexDirection="column" mt={3} mx={2}>
            <Paper elevation={1} sx={{ px: 2 }}>
              <PaymentDetailsPart
                transactionFeeLabel={siteConfig.transactionFeeLabel}
                grandTotal={locale.formatCurrency(order.currentAmounts?.grandTotal ?? 0)}
                subTotal={locale.formatCurrency(order.initialAmounts?.totalVendAmount ?? 0)}
                statementDescription={order.statementNarration}
                paymentServiceFee={order.currentAmounts?.totalFee === 0 ? undefined : locale.formatCurrency(order.currentAmounts?.totalFee ?? 0)}
                promotionDiscount={
                  order.currentAmounts?.promoDiscount === 0
                    ? undefined
                    : ({
                        id: order.promotion?.name || "",
                        name: order.promotion?.name || "",
                        amount: locale.formatCurrency((order.currentAmounts?.promoDiscount ?? 0) * -1),
                      } as ItemWithPrice)
                }
                loyaltyDiscount={
                  order.currentAmounts?.loyaltyDiscount === 0
                    ? undefined
                    : ({
                        id: "loyalty",
                        name: t("Loyalty-Discount"),
                        amount: locale.formatCurrency((order.currentAmounts?.loyaltyDiscount ?? 0) * -1),
                      } as ItemWithPrice)
                }
                otherDiscount={order.currentAmounts?.siteDiscount === 0 ? undefined : locale.formatCurrency((order.currentAmounts?.siteDiscount ?? 0) * -1)}
                adjustments={
                  order.voidAmount > 0
                    ? ({
                        id: "Adjustments",
                        name: t("Reverse-Failed-Machines"),
                        amount: locale.formatCurrency(order.adjustmentAmount * -1),
                      } as ItemWithPrice)
                    : undefined
                }
              />
            </Paper>
          </Box>

          <Divider variant={"middle"} sx={{ my: 3 }} />
          <Box mb={7}>
            <Button variant={"text"} color={"primary"} size={"small"} onClick={handleStartAgain} fullWidth startIcon={<KeyboardArrowLeft />}>
              {t("Start-Again")}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default ReceiptPage;
