import { Cart, MachineCredit } from "../../models";
import { Machine, MachineOption } from "../../api/gen";
import { createAction } from "@reduxjs/toolkit";

export const addMachineToCart = createAction<MachineCredit>("cart/machine/add");
export const updateMachinePrice = createAction<MachineWithPrice>("cart/machine/changePrice");
export const deleteMachineFromCart = createAction<string>("cart/machine/delete");
export const deleteMachineCredits = createAction("cart/machine/deleteAll");
export const replaceCart = createAction<Cart>("cart/replace");
export const addPromoCode = createAction<string>("cart/promocode/add");
export const deletePromoCode = createAction("cart/promocode/delete");
export const addOptionToMachine = createAction<MachineWithOption>("cart/machine/options/add");
export const deleteOptionFromMachine = createAction<MachineWithOption>("cart/machine/options/delete");

export type MachineWithOption = {
  machine: Machine;
  option: MachineOption;
};

export type MachineWithPrice = {
  machine: Machine;
  amount: number;
};
