import React from "react";
import TillManagedTextBox from "./TillManagedTextBox";

type TillCvcTextBoxPropTypes = {
  id: string;
  errorMessage?: string | null;
};

const TillCvcTextBox = ({ id, errorMessage }: TillCvcTextBoxPropTypes) => {
  return <TillManagedTextBox label={"CVV"} divPlaceholderId={id} errorText={errorMessage} />;
};

export default TillCvcTextBox;
