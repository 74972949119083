import React from "react";
import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { styled, useTheme } from "@mui/styles";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import InfoIcon from "@mui/icons-material/Info";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import "../../i18n";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export type SideMenuItem = "FAQ" | "Contact" | "About" | "LogIn" | "LogOut" | "Language";
type OnItemClickEventType = (item: SideMenuItem) => void;

const SideMenu = ({ drawerWidth, open, handleDrawerClose, onItemClick }: SideMenuPropTypes) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader onClick={handleDrawerClose}>
        <IconButton onClick={handleDrawerClose}>{theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}</IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem key={"FAQ"} onClick={() => onItemClick("FAQ")}>
          <ListItemIcon>
            <QuestionAnswerIcon />
          </ListItemIcon>
          <ListItemText primary={t("FAQ-1")} />
        </ListItem>
        <ListItem key={"Contact"} onClick={() => onItemClick("Contact")}>
          <ListItemIcon>
            <LocalPhoneIcon />
          </ListItemIcon>
          <ListItemText primary={t("Contact")} />
        </ListItem>
        <ListItem key={"Language"} onClick={() => onItemClick("Language")}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary={t("Language")} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key={"About"} onClick={() => onItemClick("About")}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary={t("About") + " Tangerpay"} />
        </ListItem>
      </List>
    </Drawer>
  );
};

type SideMenuPropTypes = {
  drawerWidth?: number;
  open: boolean;
  handleDrawerClose: () => void;
  onItemClick: OnItemClickEventType;
};

export default SideMenu;
