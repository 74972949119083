import React, { useState } from "react";
import HamburgerButton from "./HamburgerButton";
import { SiteNameHeader } from "./SiteNameHeader";
import SideMenu, { SideMenuItem } from "../about/SideMenu";
import AboutDialog from "../about/AboutDialog";
import ContactDialog from "../about/ContactDialog";
import FaqDialog from "../about/FaqDialog";
// import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import LanguageDialog from "../about/LanguageDialog";
// import { useNavigate } from "react-router-dom";

const Header = ({ backgroundImage, siteId, storeEmailAddress, storeName, storePhoneNumber }: HeaderPropTypes) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [aboutDialogOpen, setAboutDialogOpen] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [faqDialogOpen, setFaqDialogOpen] = useState(false);
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);

  // const auth0 = useAuth0();
  // const navigate = useNavigate();

  const handleItemClick = async (itemId: SideMenuItem) => {
    setDrawerOpen(false);
    switch (itemId) {
      case "About": {
        setAboutDialogOpen(true);
        break;
      }
      case "Contact": {
        setContactDialogOpen(true);
        break;
      }
      case "FAQ": {
        setFaqDialogOpen(true);
        break;
      }
      case "Language": {
        setLanguageDialogOpen(true);
        break;
      }
      default:
        break;
    }
  };

  // const onProfileButtonClicked = async () => {
  //   if (auth0.isAuthenticated) {
  //     navigate(`/${siteId}/profile`);
  //   } else {
  //     await auth0.loginWithPopup();
  //   }
  // };

  return (
    <>
      <AboutDialog open={aboutDialogOpen} onClose={() => setAboutDialogOpen(false)} storeName={storeName} />
      <ContactDialog
        phoneNumber={storePhoneNumber || ""}
        emailAddress={storeEmailAddress || "service@tangerpay.com"}
        open={contactDialogOpen}
        storeName={storeName}
        onClose={() => setContactDialogOpen(false)}
      />
      <FaqDialog siteId={siteId} onClose={() => setFaqDialogOpen(false)} open={faqDialogOpen} />
      <LanguageDialog onClose={() => setLanguageDialogOpen(false)} open={languageDialogOpen} />
      <Box position={"absolute"} width={"100%"}>
        <Box display={"flex"} flexDirection={"row"} m={1} justifyContent={"space-between"}>
          <HamburgerButton onClick={() => setDrawerOpen(true)} show={!drawerOpen} />
          {/*<ProfileButton onClick={onProfileButtonClicked} />*/}
        </Box>
      </Box>

      <SideMenu open={drawerOpen} drawerWidth={240} handleDrawerClose={() => setDrawerOpen(false)} onItemClick={handleItemClick} />

      <SiteNameHeader storeName={storeName} backgroundImage={backgroundImage} />
    </>
  );
};

type HeaderPropTypes = {
  siteId: string;
  storeName: string;
  storePhoneNumber?: string;
  storeEmailAddress?: string;
  backgroundImage: string;
};

export default Header;
