import { Info } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PayButton from "../PayButton";
import { getTillApi } from "./tillSdkProvider";
import { TokenReceivedCallback } from "../../CheckoutPage";
import { useLocale } from "../../../../hooks";
import { PaymentChannel, PaymentTokenType } from "../../../../api/gen";
import TillCvcTextBox from "./ui/TillCvcTextBox";
import TillCardholderNameTextBox from "./ui/TillCardholderNameTextBox";
import TillCardTextBox from "./ui/TillCardTextBox";
import TillCardExpiryTextBox from "./ui/TillCardExpiryTextBox";
import { parseExpiryDate, ValidatedCardExpiry } from "./ui/creditCardExpiry";
import { isMobile } from "react-device-detect";
import RememberMyCard from "../common/RememberMyCard";
import TillEmailAddressTextBox from "./ui/TillEmailAddressTextBox";
import validator from "validator";
import { ControlMountedCallback } from "../common/controlMountedCallback";
import { CardAcceptanceMarks } from "../common/CardAcceptanceMarks";
import { useTranslation } from "react-i18next";
import "../../../../i18n";

const NUMBER_DIV_ID = "number_div";
const CVV_DIV_ID = "cvv_div";

const baseInputStyle = {
  height: "100%",
  paddingLeft: "14px",
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontSize: "1.1rem",
  fontWeight: "400",
  color: "rgba(0, 0, 0, 0.87)",
};

// TODO load the script and then initialise this. Need to avoid the race condition when someone hits the payment page redirect

function invalidCardNumber(data: EventCallbackData) {
  return (
    (data.cardType === "amex" && data.numberLength >= 15 && !data.validNumber) ||
    ((data.cardType === "visa" || data.cardType === "mastercard") && data.numberLength >= 16 && !data.validNumber)
  );
}

function invalidCvv(data: EventCallbackData) {
  return (
    (data.cardType === "amex" && data.cvvLength >= 4 && !data.validCvv) || ((data.cardType === "visa" || data.cardType === "mastercard") && data.cvvLength >= 3 && !data.validCvv)
  );
}

function supportsAmex(pc?: PaymentChannel) {
  return pc?.configOptions?.find((o) => o.key === "SupportsAmex")?.value?.toLowerCase() === "true";
}

const isSupportedCardType = (supportsAmex: boolean, cardType?: CardType) => {
  return (cardType === "amex" && supportsAmex) || cardType === "visa" || cardType === "mastercard";
};

// https://gateway.tillpayments.com/documentation/connectors for test cards
const TillCardEntry: React.FC<{
  onTokenReceived: TokenReceivedCallback;
  onElementMounted: ControlMountedCallback;
  amount: number;
  disableSaveMyCard: boolean;
  cardChannel: PaymentChannel;
  initialEmailAddress?: string;
}> = (props) => {
  const [busy, setBusy] = useState(false);
  const [payment, setPayment] = useState<PaymentJs | null>(null);
  const [expiry, setExpiry] = useState<ValidatedCardExpiry | null>(null);
  const [tillErrorMessage, setTillErrorMessage] = useState("");
  const [cardErrorMessage, setCardErrorMessage] = useState("");
  const [cvvErrorMessage, setCvvErrorMessage] = useState("");
  const [rememberCard, setRememberCard] = useState(false);
  const [cardType, setCardType] = useState<CardType | undefined>();
  const [cardDataValid, setCardDataValid] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const [emailAddress, setEmailAddress] = useState(props.initialEmailAddress ?? "");
  const { t } = useTranslation();

  const locale = useLocale();

  useEffect(() => {
    getTillApi().then((t) => setPayment(t));
  }, []);

  useEffect(() => {
    if (!payment || !props.cardChannel) {
      return;
    }

    payment.init(props.cardChannel.applicationId, NUMBER_DIV_ID, CVV_DIV_ID, (payment) => {
      console.debug("Initialising Till Payment Form");
      payment.enableAutofill();
      payment.setNumberStyle({
        ...baseInputStyle,
        borderRadius: "4px",
        border: "1px solid lightgray",
      });
      payment.setCvvStyle({
        ...baseInputStyle,
        borderRadius: "4px",
        border: "1px solid lightgray",
      });
      const isAmexSupported = supportsAmex(props.cardChannel);

      payment.numberOn("input", (data) => {
        setCardType(data.cardType);
        setCardDataValid(data.validNumber && data.validCvv && isSupportedCardType(isAmexSupported, data.cardType));

        if (invalidCardNumber(data)) {
          setCardErrorMessage(t("Card-Number-Invalid-2"));
        } else if (data.cardType && !isSupportedCardType(isAmexSupported, data.cardType)) {
          setCardErrorMessage(t("Card-Type-Unsupported"));
        } else {
          setCardErrorMessage("");
        }
      });
      payment.numberOn("blur", (data) => {
        setCardDataValid(data.validNumber && data.validCvv && isSupportedCardType(isAmexSupported, data.cardType));

        if (data.numberLength === 0) {
          setCardErrorMessage(t("Card-Number-Required"));
        } else if (data.cardType && !isSupportedCardType(isAmexSupported, data.cardType)) {
          setCardErrorMessage(t("Card-Type-Unsupported"));
        } else if (data.validNumber) {
          setCardErrorMessage("");
        } else {
          setCardErrorMessage(t("Card-Number-Invalid-2"));
        }
      });
      payment.cvvOn("input", (data) => {
        setCardDataValid(data.validNumber && data.validCvv);
        if (invalidCvv(data)) {
          setCvvErrorMessage(t("Cvv-Invalid-1"));
        } else {
          setCvvErrorMessage("");
        }
      });
      payment.cvvOn("blur", (data) => {
        setCardDataValid(data.validNumber && data.validCvv);
        if (data.validCvv) {
          setCvvErrorMessage("");
        } else {
          setCvvErrorMessage(t("Cvv-Invalid-1"));
        }
      });
      payment.setRequireCardHolder(true);
      payment.setNumberPlaceholder("");
      payment.setCvvPlaceholder("CVV");
      payment.onAutofill((data) => {
        if (data.month && data.year) {
          const expiry = parseExpiryDate(data.month + "/" + data.year.slice(-2));
          setExpiry(expiry);
        }
        setCardholderName(data.card_holder || "");
      });

      props.onElementMounted && props.onElementMounted(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment, props.cardChannel]);

  const handlePayButtonClick = async () => {
    if (payment == null) {
      return;
    }

    setBusy(true);

    const data: AdditionalTokenisationData = {
      month: String(expiry?.month || 0),
      year: String(expiry?.year || 0),
      card_holder: cardholderName,
      email: emailAddress,
    };

    console.info("About to send tokenize request");
    await payment.tokenize(
      data,
      (token: string, cardData: any) => {
        setBusy(false);
        props.onTokenReceived({
          token: token,
          tokenMeta: {
            tokenType: PaymentTokenType.NonceAuV3,
            cardLast4: cardData.last_four_digits,
            rememberCard: rememberCard,
            email: emailAddress,
            cardholderName: cardholderName,
          },
        });
      },
      (error) => {
        setTillErrorMessage(error[0].message);
        console.warn(JSON.stringify(error));
        setBusy(false);
      }
    );
  };

  const handleCardholderNameChange = (name: string) => {
    setCardholderName(name);
  };

  const handleEmailAddressChange = (emailAddress: string) => {
    setEmailAddress(emailAddress);
  };

  const handleOnRememberMyCardChanged = () => {
    setRememberCard(!rememberCard);
  };

  const handleExpiryChange = (event: ValidatedCardExpiry) => {
    if (!payment) return;
    setExpiry(event);
  };

  return (
    // <StyledBox display={"flex"} flexDirection={"column"} bgcolor={"white"} p={2} borderRadius={1}>
    <Box display={"flex"} flexDirection={"column"} bgcolor={"white"} p={2} borderRadius={1}>
      <CardAcceptanceMarks />

      <TillCardTextBox id={NUMBER_DIV_ID} cardType={cardType} errorMessage={cardErrorMessage} />

      <TillCardholderNameTextBox value={cardholderName} onChange={handleCardholderNameChange} />

      <Box display={"flex"} flexDirection={"row"} margin={0}>
        <Box flex={1} mr={1}>
          <TillCardExpiryTextBox expiry={expiry?.expiry || ""} onChange={handleExpiryChange} />
        </Box>
        <Box flex={1} ml={1}>
          <TillCvcTextBox id={CVV_DIV_ID} errorMessage={cvvErrorMessage} />
        </Box>
      </Box>

      <TillEmailAddressTextBox value={emailAddress} onChange={handleEmailAddressChange} />

      <Box height="1.5rem" mt={1} hidden={!tillErrorMessage}>
        <Typography display={"flex"} alignItems={"center"} variant={"body2"} color={"error"}>
          <Info />
          &nbsp;&nbsp;&nbsp;{tillErrorMessage}
        </Typography>
      </Box>

      {isMobile && <RememberMyCard checked={rememberCard} duration={"untilExpired"} onChange={handleOnRememberMyCardChanged} disabled={props.disableSaveMyCard} />}

      <Box mt={3}>
        <PayButton
          onClick={handlePayButtonClick}
          amount={locale.formatCurrency(props.amount)}
          busy={busy}
          disabled={!cardDataValid || !expiry?.valid || !cardholderName || !validator.isEmail(emailAddress)}
        />
      </Box>
    </Box>
  );
};

export default React.memo(TillCardEntry);
