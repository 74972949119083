import PromoButton, { PromoCodeValidationResult } from "./PromoButton";
import { PromoAlert } from "./PromoAlert";
import { RemoteStartAlert } from "./RemoteStartAlert";
import { LoyaltyRedeemedAlert } from "./LoyaltyRedeemedAlert";
import React from "react";
import { PromotionType, QuoteResponseModel } from "../../../api/gen";
import { Locale } from "../../../models/locale";
import { LoyaltyTrackingAlert } from "./LoyaltyTrackingAlert";
import { PrepaidVoucherAlert } from "./PrepaidVoucherAlert";
import { useTranslation } from "react-i18next";
import "../../../i18n";

function noPromoOrLoyaltyConfigured(
  props: {
    quote?: QuoteResponseModel;
    onPromoCodeApplied: (promoCode: string) => void;
    showPromoCodeResolvingSpinner: boolean;
    promoCode?: string;
    promoCodeValidationResult: PromoCodeValidationResult;
    locale: Locale;
  } & { children?: React.ReactNode | undefined }
) {
  return !props.quote?.amounts?.promoDiscount && !props.quote?.loyalty;
}

function isPromoAndNotRemoteStartOrVoucher(
  props: {
    quote?: QuoteResponseModel;
    onPromoCodeApplied: (promoCode: string) => void;
    showPromoCodeResolvingSpinner: boolean;
    promoCode?: string;
    promoCodeValidationResult: PromoCodeValidationResult;
    locale: Locale;
  } & { children?: React.ReactNode | undefined }
) {
  return !!props.quote?.amounts?.promoDiscount && !props.quote?.promotion.name.startsWith("Remote Start") && props.quote?.promotion.type !== PromotionType.PrepaidVoucher;
}

function isVoucher(
  props: {
    quote?: QuoteResponseModel;
    onPromoCodeApplied: (promoCode: string) => void;
    showPromoCodeResolvingSpinner: boolean;
    promoCode?: string;
    promoCodeValidationResult: PromoCodeValidationResult;
    locale: Locale;
  } & { children?: React.ReactNode | undefined }
) {
  return !!props.quote?.amounts?.promoDiscount && props.quote?.promotion.type === PromotionType.PrepaidVoucher;
}

function isRemoteStart(
  props: {
    quote?: QuoteResponseModel;
    onPromoCodeApplied: (promoCode: string) => void;
    showPromoCodeResolvingSpinner: boolean;
    promoCode?: string;
    promoCodeValidationResult: PromoCodeValidationResult;
    locale: Locale;
  } & { children?: React.ReactNode | undefined }
) {
  return !!props.quote?.amounts?.promoDiscount && props.quote?.promotion.name.startsWith("Remote Start");
}

function isLoyaltyRedeemed(
  props: {
    quote?: QuoteResponseModel;
    onPromoCodeApplied: (promoCode: string) => void;
    showPromoCodeResolvingSpinner: boolean;
    promoCode?: string;
    promoCodeValidationResult: PromoCodeValidationResult;
    locale: Locale;
  } & { children?: React.ReactNode | undefined }
) {
  return !!props.quote?.amounts?.loyaltyDiscount;
}

function isLoyaltyButNotRedeemed(
  props: {
    quote?: QuoteResponseModel;
    onPromoCodeApplied: (promoCode: string) => void;
    showPromoCodeResolvingSpinner: boolean;
    promoCode?: string;
    promoCodeValidationResult: PromoCodeValidationResult;
    locale: Locale;
  } & { children?: React.ReactNode | undefined }
) {
  return !!props.quote?.loyalty && !props.quote?.amounts?.loyaltyDiscount;
}

export const PromoPanel: React.FC<{
  quote?: QuoteResponseModel;
  onPromoCodeApplied: (promoCode: string) => void;
  showPromoCodeResolvingSpinner: boolean;
  promoCode?: string;
  promoCodeValidationResult: PromoCodeValidationResult;
  locale: Locale;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {noPromoOrLoyaltyConfigured(props) && (
        <PromoButton
          onPromoCodeApplied={props.onPromoCodeApplied}
          showSpinner={props.showPromoCodeResolvingSpinner}
          promoCode={props.promoCode}
          promoCodeValidationResult={props.promoCodeValidationResult}
        />
      )}
      {isPromoAndNotRemoteStartOrVoucher(props) && <PromoAlert promoCode={props.promoCode ?? ""} discount={props.locale.formatCurrency(props.quote!.amounts.promoDiscount)} />}
      {isRemoteStart(props) && <RemoteStartAlert promoCode={props.promoCode ?? ""} />}
      {isVoucher(props) && <PrepaidVoucherAlert description={props.quote?.promotion.name ?? t("Prepaid-Voucher-Registered")} />}
      {isLoyaltyRedeemed(props) && <LoyaltyRedeemedAlert discount={props.locale.formatCurrency(props.quote!.amounts.loyaltyDiscount)} />}
      {isLoyaltyButNotRedeemed(props) && (
        <LoyaltyTrackingAlert points={props.quote?.loyalty!.currentBalance ?? 0} pointsThreshold={props.quote!.loyalty?.minimumPointsBeforeAvailable ?? 0} />
      )}
    </>
  );
};
