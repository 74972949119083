import { FormControl, FormHelperText, OutlinedInput, Typography } from "@mui/material";
import React, { CSSProperties, FormEventHandler } from "react";

type TillUnmanagedTextBoxPropTypes = {
  label: string;
  value: string;
  autoComplete?: string;
  placeholder?: string;
  errorText?: string;
  helperText?: string;
  onInput?: FormEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  innerRef?: React.RefObject<unknown>;
  style?: CSSProperties | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
};

const TillUnmanagedTextBox = ({ label, value, autoComplete, errorText, helperText, placeholder, onInput, innerRef, style, onBlur, inputMode }: TillUnmanagedTextBoxPropTypes) => {
  return (
    <FormControl margin={"normal"} required>
      <label>
        <Typography variant={"body2"}>{label}</Typography>
      </label>
      <OutlinedInput
        size={"small"}
        autoComplete={autoComplete}
        placeholder={placeholder}
        inputProps={{ onInput: onInput, style: style, inputMode: inputMode }}
        value={value}
        inputRef={innerRef}
        onBlur={onBlur}
      />
      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
      {helperText && !errorText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default TillUnmanagedTextBox;
