import GooglePayButton from "@google-pay/button-react";
import React from "react";
import { Locale } from "../../../../models/locale";
import { GooglePaySettings } from "../../../../api/gen";
import { useTranslation } from "react-i18next";
import "../../../../i18n";

const GooglePayButtonWrapper = ({ locale, isProduction, googlePaySettings, amount, onPaymentDataReceived, supportsAmex }: GooglePayButtonWrapperPropTypes) => {
  const { t } = useTranslation();
  let disabled = false;

  // https://github.com/google-pay/google-pay-button/tree/main/src/button-react
  return (
    <GooglePayButton
      environment={isProduction ? "PRODUCTION" : "TEST"}
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
              allowedCardNetworks: supportsAmex ? ["AMEX", "MASTERCARD", "VISA", "DISCOVER"] : ["MASTERCARD", "VISA", "DISCOVER"],
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: googlePaySettings.gatewayId,
                gatewayMerchantId: googlePaySettings.merchantId, // not used by Stripe but used by others.
                "stripe:version": "2020-08-27", // stripe specific fields are ignored by other gateways
                "stripe:publishableKey": googlePaySettings.merchantId,
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: "BCR2DN6T6P5KJFTM", // FIXME remove hard coding
          merchantName: googlePaySettings.merchantName,
        },
        transactionInfo: {
          totalPriceStatus: "FINAL",
          totalPriceLabel: t("Total"),
          totalPrice: amount.toString(),
          currencyCode: locale.currencyCode,
          countryCode: locale.countryCode,
        },
        emailRequired: true,
      }}
      buttonSizeMode={"fill"}
      //buttonType={"pay"}
      onLoadPaymentData={(pr) => {
        console.debug("Google payment request data", pr);
        onPaymentDataReceived(pr);
        disabled = false;
      }}
      style={{ width: "100%" }}
      onClick={(e) => {
        // Don't allow double-clicks!
        if (disabled) {
          e.preventDefault();
          console.debug("Google Pay double click detected and suppressed");
        }
        disabled = true;
      }}
      onError={() => (disabled = false)}
      onCancel={() => (disabled = false)}
    />
  );
};

type GooglePayButtonWrapperPropTypes = {
  isProduction: boolean;
  amount: number;
  locale: Locale;
  onPaymentDataReceived: (paymentData: google.payments.api.PaymentData) => void;
  googlePaySettings: GooglePaySettings;
  supportsAmex: boolean;
};

export default GooglePayButtonWrapper;
