/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FaqModel,
  MachineStatusResponse,
  SiteConfig,
} from '../models/index';
import {
    FaqModelFromJSON,
    FaqModelToJSON,
    MachineStatusResponseFromJSON,
    MachineStatusResponseToJSON,
    SiteConfigFromJSON,
    SiteConfigToJSON,
} from '../models/index';

export interface SitesGetFaqsRequest {
    siteId: string;
}

export interface SitesGetMachineStatusRequest {
    siteId: string;
    machineId: string;
}

export interface SitesGetSiteConfigRequest {
    siteId: string;
}

/**
 * 
 */
export class SitesApi extends runtime.BaseAPI {

    /**
     */
    async sitesGetFaqsRaw(requestParameters: SitesGetFaqsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FaqModel>>> {
        if (requestParameters['siteId'] == null) {
            throw new runtime.RequiredError(
                'siteId',
                'Required parameter "siteId" was null or undefined when calling sitesGetFaqs().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Sites/{siteId}/faqs`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters['siteId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FaqModelFromJSON));
    }

    /**
     */
    async sitesGetFaqs(requestParameters: SitesGetFaqsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FaqModel>> {
        const response = await this.sitesGetFaqsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sitesGetMachineStatusRaw(requestParameters: SitesGetMachineStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MachineStatusResponse>> {
        if (requestParameters['siteId'] == null) {
            throw new runtime.RequiredError(
                'siteId',
                'Required parameter "siteId" was null or undefined when calling sitesGetMachineStatus().'
            );
        }

        if (requestParameters['machineId'] == null) {
            throw new runtime.RequiredError(
                'machineId',
                'Required parameter "machineId" was null or undefined when calling sitesGetMachineStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Sites/{siteId}/machines/{machineId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters['siteId']))).replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters['machineId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async sitesGetMachineStatus(requestParameters: SitesGetMachineStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MachineStatusResponse> {
        const response = await this.sitesGetMachineStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sitesGetSiteConfigRaw(requestParameters: SitesGetSiteConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiteConfig>> {
        if (requestParameters['siteId'] == null) {
            throw new runtime.RequiredError(
                'siteId',
                'Required parameter "siteId" was null or undefined when calling sitesGetSiteConfig().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Sites/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters['siteId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteConfigFromJSON(jsonValue));
    }

    /**
     */
    async sitesGetSiteConfig(requestParameters: SitesGetSiteConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiteConfig> {
        const response = await this.sitesGetSiteConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
