/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  QuoteRequestModel,
  QuoteResponseModel,
} from '../models/index';
import {
    QuoteRequestModelFromJSON,
    QuoteRequestModelToJSON,
    QuoteResponseModelFromJSON,
    QuoteResponseModelToJSON,
} from '../models/index';

export interface QuotesCalculateQuoteRequest {
    quoteRequestModel: QuoteRequestModel;
}

/**
 * 
 */
export class QuotesApi extends runtime.BaseAPI {

    /**
     */
    async quotesCalculateQuoteRaw(requestParameters: QuotesCalculateQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuoteResponseModel>> {
        if (requestParameters['quoteRequestModel'] == null) {
            throw new runtime.RequiredError(
                'quoteRequestModel',
                'Required parameter "quoteRequestModel" was null or undefined when calling quotesCalculateQuote().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Quotes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuoteRequestModelToJSON(requestParameters['quoteRequestModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuoteResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async quotesCalculateQuote(requestParameters: QuotesCalculateQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuoteResponseModel> {
        const response = await this.quotesCalculateQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
