import React from "react";
import { Box, Typography } from "@mui/material";
import logo_brand from "../assets/tangerpay-brand.svg";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { useTranslation } from "react-i18next";
import "../../src/i18n";

const StoreNotFound = () => {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} flexDirection={"column"} p={6} className={"gradient-background"} height={"100vh"}>
      <Box mt={8} width={"100%"} textAlign={"center"}>
        <img src={logo_brand} alt="Tangerpay" height="52px" />
      </Box>
      <Typography variant={"h4"} color={"white"} fontFamily={"Quicksand"} sx={{ my: 4, textAlign: "center" }}>
        {t("Not-Found")} <SearchOffIcon fontSize={"large"} />
      </Typography>
      <Typography variant={"body1"} color={"white"} sx={{ my: 4 }}>
        {t("Not-Found-Message-1")}
      </Typography>
      <Typography variant={"body2"} color={"white"} sx={{ my: 1 }}>
        {t("Contact")}
        <a href="mailto:service@tangerpay.com">service@tangerpay.com</a>
        {t("Not-Found-Message-2")}
      </Typography>
    </Box>
  );
};
export default StoreNotFound;
