import { Box, Collapse, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { ItemWithPrice } from "../../common/itemised/itemWithPrice";
import ItemWithPriceLine from "../../common/itemised/ItemWithPriceLine";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const PaymentDetailsPart = ({
  transactionFeeLabel,
  subTotal,
  adjustments,
  grandTotal,
  promotionDiscount,
  loyaltyDiscount,
  otherDiscount,
  donation,
  paymentServiceFee,
  statementDescription,
}: PaymentDetailsPartPropTypes) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const showHide = show ? t("Hide") : t("Show");

  const translatedTransactionFeeLabel = transactionFeeLabel === "Platform Fee" ? t("Platform-Fee") : transactionFeeLabel === "Service Fee" ? t("Service-Fee") : transactionFeeLabel;

  return (
    <>
      <Typography pt={2} variant={"h6"}>
        {t("You-Paid")}
        {grandTotal}
      </Typography>

      {statementDescription && (
        <Typography variant={"caption"} py={1} component="div">
          {t("Bank-Statement-Message")} <br />
          {statementDescription}
        </Typography>
      )}

      <Divider variant={"fullWidth"} />
      <Box onClick={() => setShow(!show)} py={1}>
        <Typography variant={"caption"} color={"text.secondary"}>
          {showHide} {t("Details")}
        </Typography>
      </Box>
      <Collapse in={show}>
        <Box display={"flex"} flexDirection={"column"} py={2} sx={{ "& .MuiBox-root": { py: 0.25 } }}>
          <ItemWithPriceLine label={t("Sub-Total")} amount={subTotal} />
          {adjustments && <ItemWithPriceLine label={adjustments.name} amount={adjustments.amount} textColor={"text.secondary"} textVariant={"body2"} />}
          {otherDiscount && <ItemWithPriceLine label={t("Store-Discount")} amount={otherDiscount} textColor={"text.secondary"} textVariant={"body2"} />}
          {promotionDiscount && <ItemWithPriceLine label={promotionDiscount.name} amount={promotionDiscount.amount} textColor={"text.secondary"} textVariant={"body2"} />}
          {loyaltyDiscount && <ItemWithPriceLine label={loyaltyDiscount.name} amount={loyaltyDiscount.amount} textColor={"text.secondary"} textVariant={"body2"} />}
          {paymentServiceFee && <ItemWithPriceLine label={translatedTransactionFeeLabel} amount={paymentServiceFee} textColor={"text.secondary"} textVariant={"body2"} />}
          {donation && <ItemWithPriceLine label={donation.name} amount={donation.amount} textColor={"text.secondary"} textVariant={"body2"} />}
          <ItemWithPriceLine label={t("Total-Paid")} amount={grandTotal} labelWeight={"bold"} amountWeight={"bold"} />
        </Box>
      </Collapse>
    </>
  );
};

type PaymentDetailsPartPropTypes = {
  transactionFeeLabel: string;
  subTotal: string;
  grandTotal: string;
  paymentServiceFee?: string;
  otherDiscount?: string;
  statementDescription?: string;
  promotionDiscount?: ItemWithPrice;
  loyaltyDiscount?: ItemWithPrice;
  donation?: ItemWithPrice;
  adjustments?: ItemWithPrice;
};

export default PaymentDetailsPart;
