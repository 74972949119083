import React, { useState } from "react";
import { Alert, Box, Collapse, Divider, Typography } from "@mui/material";
import ItemWithPriceLine from "../../common/itemised/ItemWithPriceLine";
import { useAppSelector } from "../../../redux/hooks";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const LoyaltyPart = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const showHide = show ? t("Hide") : t("Show");

  const loyalty = useAppSelector((state) => state.order.order?.loyalty);

  return (
    <>
      <Typography pt={2} variant={"h6"}>
        {loyalty?.name}
      </Typography>

      <Typography variant={"caption"} py={1} component="div">
        {/*{(loyalty?.pointsEarnedThisOrder || 0) > 0 && <>You earned {loyalty?.pointsEarnedThisOrder} points for this order.</>}{" "}*/}
        {(loyalty?.pointsEarnedThisOrder || 0) > 0 && (
          <Alert icon={<FavoriteIcon fontSize={"small"} sx={{ color: "white" }} />} sx={{ backgroundColor: "#e85fbf", color: "white" }}>
            {t("Loyalty-Alert", { pointsEarned: loyalty?.pointsEarnedThisOrder })}
          </Alert>
        )}{" "}
      </Typography>

      <Divider variant={"fullWidth"} />
      <Box onClick={() => setShow(!show)} py={1}>
        <Typography variant={"caption"} color={"text.secondary"}>
          {showHide} {t("Details")}
        </Typography>
      </Box>

      <Collapse in={show}>
        <Box display={"flex"} flexDirection={"column"} py={2} sx={{ "& .MuiBox-root": { py: 0.25 } }}>
          <ItemWithPriceLine label={t("Loyalty-Current-Points")} amount={loyalty?.currentBalance.toString()} textColor={"text.secondary"} textVariant={"body2"} />
          <ItemWithPriceLine label={t("Loyalty-Earned")} amount={loyalty?.pointsEarnedThisOrder?.toString()} textColor={"text.secondary"} textVariant={"body2"} />
          {(loyalty?.pointsRedeemedThisOrder || 0) > 0 && (
            <ItemWithPriceLine label={t("Loyalty-Redeemed")} amount={loyalty?.pointsRedeemedThisOrder?.toString()} textColor={"text.secondary"} textVariant={"body2"} />
          )}
          <ItemWithPriceLine label={t("Loyalty-Minimum-Required")} amount={loyalty?.minimumPointsBeforeAvailable.toString()} textColor={"text.secondary"} textVariant={"body2"} />
          <ItemWithPriceLine label={t("Loyalty-Available-To-Use")} amount={loyalty?.availableBalance.toString()} textColor={"text.secondary"} textVariant={"body2"} />
        </Box>
      </Collapse>
    </>
  );
};

export default LoyaltyPart;
