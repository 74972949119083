import React from "react";
import TillManagedTextBox from "./TillManagedTextBox";
import CardIconSelector from "../../../assets/cardicons/CardIconSelector";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";

/**
 * This component works with the Till payments.js and assumes a parent component is managing styles (and so on). There are no events raised by this component
 * @constructor
 */

type TillCardTextBoxPropTypes = {
  id: string;
  cardType?: CardType;
  errorMessage?: string | null;
};

const TillCardTextBox = ({ id, cardType, errorMessage }: TillCardTextBoxPropTypes) => {
  const { t } = useTranslation();
  return <TillManagedTextBox label={t("Card-Number")} divPlaceholderId={id} helperImage={<CardIconSelector cardType={cardType} />} errorText={errorMessage} />;
};

export default TillCardTextBox;
