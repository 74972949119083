import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import DialogWithTitle from "../common/dialog/DialogWithTitle";
import ReactGA from "react-ga4";
import "../../App.css";
import { ExpandMore } from "@mui/icons-material";
import { getFaqs } from "../../api/siteApi";
import { PageSpinner } from "../common/PageSpinner";
import { FaqModel } from "../../api/gen";
import { useTranslation } from "react-i18next";
import "../../../src/i18n";

const FaqDialog = ({ open, onClose, siteId }: FaqDialogPropTypes) => {
  const [faqs, setFaqs] = useState([] as Array<FaqModel>);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  if (open) {
    ReactGA.event({
      category: "Hamburger",
      action: "Show FAQ page",
    });
  }

  useEffect(() => {
    setLoading(true);
    getFaqs(siteId).then((r) => {
      setFaqs(r);
      setLoading(false);
    });
  }, [siteId]);

  return (
    <DialogWithTitle open={open} onClose={onClose} title={t("FAQ-1")}>
      <Box className={"gradient-background"} flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"stretch"}>
        <Box mt={8} component={"div"} textAlign={"center"}>
          <Typography variant={"h5"} fontFamily={"Quicksand"} color={"white"}>
            {t("FAQ-2")}
          </Typography>
        </Box>

        <PageSpinner message={t("FAQ-Loading")} open={loading} />

        <Box mx={2} display={"flex"} flexDirection={"column"} alignItems={"stretch"} pt={3}>
          {faqs.map((faq, i) => (
            <Accordion key={i}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={"body1"}>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant={"body2"} color={"text.secondary"}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        {/*Empty space to allow scroll up*/}
        <Box p={8} />
      </Box>
    </DialogWithTitle>
  );
};

type FaqDialogPropTypes = {
  siteId: string;
  onClose: () => void;
  open: boolean;
};

export default FaqDialog;
