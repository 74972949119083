import DialogWithTitle from "../../../../common/dialog/DialogWithTitle";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { Lock } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";

export type OnSuccessCallback = (token: string) => void;
export type OnCancelCallback = () => void;
export type OnErrorCallback = () => void;

const handleRedirectFromIssuer = (url: Location, onSuccess: OnSuccessCallback, onError: OnErrorCallback, onCancel: OnCancelCallback) => {
  const regex = /3ds_success\?token=(.*)$/;
  const matches = regex.exec(url.href);

  if (matches && matches.length === 2) {
    // first match is the whole string, second match is the group
    onSuccess(matches[1]);
  } else if (url.href.endsWith("/3ds_error")) {
    onError();
  } else if (url.href.endsWith("/3ds_cancel")) {
    onCancel();
  }
};

const ThreeDSecureChallenge = ({ challengeUrl, open, onSuccess, onError, onCancel }: ThreeDSecureChallengePropTypes) => {
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();

  if (!challengeUrl) {
    return <></>;
  }

  return (
    <DialogWithTitle open={open} onClose={onCancel} title={t("3ds-Title-1")}>
      <Box className={"gradient-background"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"}>
        <Box margin={2}>
          {isLoading && (
            <Typography variant={"body2"} color={"white"}>
              {t("3ds-Loading")}
            </Typography>
          )}
          {!isLoading && (
            // <Typography variant={"body2"} color={"white"}>
            //   Please enter the details requested by your bank
            // </Typography>
            <Alert severity="info" icon={<Lock fontSize="inherit" />}>
              <AlertTitle>{t("3ds-Alert")}</AlertTitle>
              <p>{t("3ds-Alert-Message")}</p>
            </Alert>
          )}
        </Box>
        <iframe
          title={t("3ds-Title-2")}
          style={{ borderStyle: "none", display: isLoading ? "none" : "block" }}
          src={challengeUrl}
          width={250}
          height={400}
          onLoad={(e) => {
            console.info("LOAD EVENT! ", e);
            setLoading(false);
            try {
              const url = e.currentTarget.contentWindow?.location;
              if (url) {
                handleRedirectFromIssuer(url, onSuccess, onError, onCancel);
              }
            } catch (err) {
              // will throw an error initially due to cross-origin violation. When we get the redirect back, then it can be captured.
            }
          }}
        />
        {!isLoading && (
          <>
            <Box color="text.secondary" pt={2} alignItems="center" textAlign="center">
              <div>
                <Lock />
              </div>
              <div>
                <Typography variant="caption" margin={2}>
                  {t("3ds-Loaded-Message-1")}
                  <p />
                  {t("3ds-Loaded-Message-2")}
                </Typography>
              </div>
            </Box>
          </>
        )}
      </Box>
    </DialogWithTitle>
  );
};

type ThreeDSecureChallengePropTypes = {
  challengeUrl: string;
  open: boolean;
  onSuccess: OnSuccessCallback;
  onCancel: OnCancelCallback;
  onError: OnErrorCallback;
};

export default ThreeDSecureChallenge;
