import Payment from "payment";

export interface ValidatedCardExpiry {
  expiry: string;
  valid: boolean;
  month?: number;
  year?: number;
}

export const parseExpiryDate = (expiry: string): ValidatedCardExpiry => {
  const isValid = Payment.fns.validateCardExpiry(expiry);

  if (isValid) {
    const result = Payment.fns.cardExpiryVal(expiry);

    return {
      valid: true,
      expiry: expiry,
      month: result.month,
      year: result.year,
    };
  }
  return {
    expiry: expiry,
    valid: false,
  };
};
