import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserProfile, newUserProfile, patchProfile } from "../../api/userProfileApi";
import { CardOnFileRequest, UserProfileResponse } from "../../api/gen";

export type UpdateProfileRequest = {
  profileId: string;
  emailAddress?: string;
  cardOnFile?: CardOnFileRequest;
};

export type AddNewProfileRequest = {
  profileId: string;
  emailAddress?: string;
  cardOnFile?: CardOnFileRequest;
};

export const loadUserProfile = createAsyncThunk("userprofile/get", async (id: string) => {
  return await getUserProfile(id);
});

export const updateProfile = createAsyncThunk("userprofile/update", async (request: UpdateProfileRequest) => {
  return await patchProfile(request.profileId, request.cardOnFile, request.emailAddress);
});

export const createNewProfile = createAsyncThunk("userprofile/create", async (request: AddNewProfileRequest) => {
  return await newUserProfile(request.cardOnFile || null, request.emailAddress || null, request.profileId);
});

export const setUserProfile = createAction<UserProfileResponse>("userprofile/replaceProfile");
