import React from "react";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { MachineCreditResultModel, OrderStatus, VendAttemptResult } from "../../../api/gen";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const ReceiptHeadline = ({ customerReference, orderStatus, machineCreditResults }: ReceiptHeadlinePropTypes) => {
  const { t } = useTranslation();
  const failedMachines = machineCreditResults
    .filter((mc) => mc.vendAttemptResult === VendAttemptResult.Fail)
    .map((mc) => mc.machineName)
    .join(", ");

  return (
    <Box bgcolor="white">
      <Box p={2}>
        <Typography variant={"h4"} component={"h4"} align={"center"} style={{ fontWeight: 300 }}>
          {orderStatus !== OrderStatus.Failed && <>{t("Thank-You")}</>}
          {orderStatus === OrderStatus.Failed && <>{t("Sorry")}</>}
        </Typography>
      </Box>
      <Box p={1}>
        {/*{orderStatus === OrderStatus.Completed && (
          <Typography variant={"subtitle1"} align={"center"}>
            Start your laundry and relax for a bit.
          </Typography>
        )}*/}
        {orderStatus === OrderStatus.Partial && (
          <>
            <Alert severity={"info"}>
              <AlertTitle>{t("Order-Partial-Alert-Title")}</AlertTitle>
              {t("Order-Partial-Alert-Title", { failedMachines: failedMachines })}.
            </Alert>
          </>
        )}
        {orderStatus === OrderStatus.Failed && (
          <>
            <Alert severity={"error"} variant={"filled"}>
              <AlertTitle>{t("Order-Failed-Alert-Title")}</AlertTitle>
              {t("Order-Failed-Alert-Message")}
            </Alert>
          </>
        )}
        {/*TODO this is if a customer looks up a previous order that has been refunded*/}
        {orderStatus === OrderStatus.Refunded && <>{t("Order-Refunded")}</>}
      </Box>
      <Box p={1}>
        <Typography variant={"body2"} align={"center"}>
          {t("Order-Reference")} <strong>{customerReference}.</strong>
        </Typography>
      </Box>
    </Box>
  );
};

type ReceiptHeadlinePropTypes = {
  customerReference: string;
  orderStatus: OrderStatus;
  machineCreditResults: Array<MachineCreditResultModel>;
};

export default ReceiptHeadline;
