import React from "react";
import { CardScheme } from "../../../api/gen";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PaymentTypeLabelFactory from "../paymentTypeSelection/PaymentTypeLabelFactory";
import { PaymentOption } from "../../../models";

type RememberedCardLabelPropTypes = {
  scheme: CardScheme;
  last4: string;
  expireMonth: number;
  expireYear: number;
  onDelete: () => void;
  busy: boolean;
};

const RememberedCardLabel = ({ scheme, last4, expireMonth, expireYear, onDelete, busy }: RememberedCardLabelPropTypes) => (
  <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
    <Box display={"flex"} alignItems={"center"} columnGap={2}>
      <PaymentTypeLabelFactory paymentOption={PaymentOption.CARD_ON_FILE} cardLast4={last4} cardScheme={scheme} />
      <Typography flex={"2"}>
        exp {expireMonth}/{expireYear}
      </Typography>
    </Box>
    <Box>
      {!busy && (
        <IconButton onClick={onDelete} aria-label="delete saved card">
          <DeleteOutlineIcon color={"error"} />
        </IconButton>
      )}
      {busy && <CircularProgress color={"error"} size={22} />}
    </Box>
  </Box>
);

export default RememberedCardLabel;
