import { Backdrop } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import "./App.css";
import CheckoutPage from "./components/checkout/CheckoutPage";
import LoadingSplash from "./components/common/LoadingSplash";
import StartPageResolver from "./components/StartPageResolver";
import MachineSelectionPage from "./components/machineselect/MachineSelectionPage";
import MachineTypeSelectPage from "./components/machinetypeselect/MachineTypeSelectPage";
import ReceiptPage from "./components/receipt/ReceiptPage";
import ZoneSelectPage from "./components/zone/ZoneSelectPage";
import { loadSiteConfig } from "./redux/actions/siteConfigActions";
import initialisePaymentLibraries from "./utils/paymentGatewayScriptLoader";
import { SessionContext } from "./models";
import Footer from "./components/common/Footer";
import { LocalSettingsService } from "./service/localSettingsService";
import { setSessionContext } from "./redux/actions/sessionContextActions";
import { SiteStatus } from "./api/gen";
import { usePaymentMethods, useSiteConfig } from "./hooks";
import { Theme } from "@mui/material/styles";
import { loadUserProfile } from "./redux/actions/userProfileActions";
import { Auth0Provider } from "@auth0/auth0-react";
import ProfilePage from "./components/profile/ProfilePage";
import ProfileEditPage from "./components/profile/AccountEditPage";
import LayoutWithHeader from "./components/common/layouts/LayoutWithHeader";
import MachineNotFoundPage from "./components/MachineNotFoundPage";
import SiteConfigError from "./components/SiteConfigError";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {
  }
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    flexDirection: "column",
    minHeight: "100vh"
  }
}));

let paymentLibrariesInitialised = false;

export function App() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { digitalWalletChannel, cardChannel } = usePaymentMethods();
  const { siteConfig, isError: isSiteConfigLoadError } = useSiteConfig();
  const dispatch = useDispatch();
  const { siteId } = useParams();

  if (!siteId || isSiteConfigLoadError) {
    navigate("/notfound");
  }

  console.info(`Rendering main page - SID is ${siteId} and store name is ${siteConfig?.name}`);

  // this is a thunk - it loads the site config which updates redux, which updates the site config which re-renders the page
  useEffect(() => {
    dispatch(loadSiteConfig(siteId!));
  }, [siteId, dispatch]);

  // load the user profile if a profile ID exists
  useEffect(() => {
    const userProfileId = LocalSettingsService.getId();
    if (userProfileId) {
      dispatch(loadUserProfile(userProfileId));
    }
  }, [siteId, dispatch]);

  useEffect(() => {
    if (siteConfig && !paymentLibrariesInitialised) {
      initialisePaymentLibraries(siteConfig?.isProduction, digitalWalletChannel, cardChannel);
      paymentLibrariesInitialised = true;
    }
  }, [cardChannel, digitalWalletChannel, siteConfig]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    let referrer: SessionContext["referrer"];

    switch (urlParams.get("r")?.toUpperCase()) {
      case "KIOSK": {
        referrer = "Kiosk";
        break;
      }
      case "QR": {
        referrer = "QR";
        break;
      }
      case "NFC": {
        referrer = "NFC";
        break;
      }
      case "MANUAL": {
        referrer = "Code";
        break;
      }
      default:
        break;
    }

    dispatch(
      setSessionContext({
        referrer: referrer,
        phoneNumber: urlParams.get("phoneNumber")
      } as SessionContext)
    );
  }, [dispatch]);

  if (siteConfig?.status === SiteStatus.Inactive || siteConfig?.status === SiteStatus.Closed) {
    navigate("/closed");
  }

  return (
    <div className={classes.root}>
      <Backdrop open={!siteConfig?.siteId}>
        <LoadingSplash />
      </Backdrop>

      {siteConfig?.siteId && (
        <Auth0Provider domain="paypont.au.auth0.com" clientId="ZymgZVWloOduuVB1Gga1UkGyYgrBDwyu" authorizationParams={{
          redirect_uri: window.location.origin
        }}>
          <Routes>
            <Route path="/" element={<StartPageResolver siteConfig={siteConfig} />} />
            <Route path="/profile" element={<ProfilePage siteId={siteId!} />} />
            <Route path="/profile/edit" element={<ProfileEditPage siteId={siteId!} />} />
            <Route element={<LayoutWithHeader />}>
              <Route path="/configError" element={<SiteConfigError />} />
              <Route path="/machineNotFound" element={<MachineNotFoundPage />} />
              <Route path="/zones" element={<ZoneSelectPage />} />
              <Route path="/zones/:zoneId" element={<MachineTypeSelectPage />} />
              <Route path="/zones/:zoneId/machines" element={<MachineSelectionPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/:orderId/receipt" element={<ReceiptPage />} />
            </Route>
          </Routes>
          <Footer showTrialMode={siteConfig.status === SiteStatus.Trial} />
        </Auth0Provider>
      )}
    </div>
  );
}

export default App;
