import { TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import Payment from "payment";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";

type CvcTextBoxPropTypes = {
  cvc: string;
  cardType: string;
  onChange: (event: string) => void;
};

const CvcTextBox = ({ cvc, cardType, onChange }: CvcTextBoxPropTypes) => {
  const [error, setError] = useState("");
  const cvcRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  if (cvcRef.current) {
    Payment.formatCardCVC(cvcRef.current);
  }

  function handleChangeEvent(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setError("");
    onChange(evt.target.value);
  }

  // This could be reusable in the future with other gateways that use a similar integration style as Datacap.
  return (
    <TextField
      inputRef={cvcRef}
      label="CVC"
      margin="normal"
      variant="outlined"
      required
      size={"small"}
      inputProps={{ "data-token": "cvv", id: undefined }}
      helperText={error}
      error={!!error}
      autoComplete="cc-csc"
      onBlur={() => {
        if (!Payment.fns.validateCardCVC(cvc, cardType)) {
          setError(t("Cvv-Invalid-2"));
        }
      }}
      onChange={(evt) => handleChangeEvent(evt)}
      value={cvc}
    />
  );
};

export default CvcTextBox;
