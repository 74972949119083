import FavoriteIcon from "@mui/icons-material/Favorite";
import { Alert } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../../i18n";

export const LoyaltyTrackingAlert: React.FC<{ points: number; pointsThreshold: number }> = (props) => {
  const { t } = useTranslation();
  return (
    <Alert severity={"success"} icon={<FavoriteIcon fontSize={"small"} />}>
      {t("Loyalty-Tracking-Alert", { points: props.points, pointsThreshold: props.pointsThreshold })}
    </Alert>
  );
};
