import { Send } from "@mui/icons-material";
import { Alert, Box, Fade, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import validator from "validator";
import { UnprocessableEntity } from "../../../api/apiUtils";
import { setReminder } from "../../../api/orderApi";
import InlineTextFieldWithSubmit from "../../common/controls/InlineTextFieldWithSubmit";
import ReactGA from "react-ga4";
import { Locale } from "../../../models/locale";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const SmsReminderPart = ({ locale, orderId, siteId }: SmsReminderPartPropTypes) => {
  const [validationError, setValidationError] = useState("");
  const [busy, setBusy] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // using session, not local storage. Customers like SMS, but we don't want too many to use it due to cost.
  const [phoneNumberInControl, setPhoneNumberInControl] = useState(sessionStorage.getItem("phone_number") || "");
  const [reminderSent, setReminderSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const onPhoneNumberChanged = (value: string) => {
    if (!value) {
      setValidationError("");
      setErrorMessage("");
    }
    setPhoneNumberInControl(value);
  };

  const onReminderButtonClicked = (value: string) => {
    setErrorMessage("");
    setValidationError("");
    if (value) {
      if (!validator.isMobilePhone(value, locale.languageTag as validator.MobilePhoneLocale)) {
        setValidationError(t("SMS-Error-1"));
      } else {
        ReactGA.event({
          category: "Receipt",
          action: "SMS reminder requested",
        });
        setBusy(true);
        setReminder(siteId, orderId, value)
          .then((result) => {
            setBusy(false);
            if (result.success) {
              setReminderSent(true);
              setDisabled(true);
              sessionStorage.setItem("phone_number", value);
            } else {
              setErrorMessage(result.message || "");
            }
          })
          .catch((e) => {
            console.warn(e);
            setBusy(false);
            if (e instanceof UnprocessableEntity) {
              e.response.json().then((o: any) => setErrorMessage(o.message));
            }
            setErrorMessage(t("SMS-Error-2"));
          });
      }
    }
  };

  return (
    <Paper elevation={1}>
      <Box p={2}>
        <Typography variant={"h6"} pb={0}>
          {t("SMS-Reminder")}
        </Typography>

        <Typography variant={"caption"} component={"div"} pb={1}>
          {t("SMS-Message")}.
        </Typography>

        {reminderSent && (
          <Fade in={reminderSent}>
            <Alert severity={"success"} sx={{ mb: 2 }}>
              {t("SMS-Alert")}
            </Alert>
          </Fade>
        )}
        {errorMessage && (
          <Fade in={!!errorMessage}>
            <Alert severity={"error"} sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          </Fade>
        )}

        <Box my={1}>
          <InlineTextFieldWithSubmit
            label={t("Phone-Number")}
            type={"tel"}
            onSubmit={onReminderButtonClicked}
            onChange={onPhoneNumberChanged}
            busy={busy}
            disabled={disabled}
            value={phoneNumberInControl}
            validationErrorMessage={validationError}
            buttonIcon={<Send />}
          />
        </Box>
      </Box>
    </Paper>
  );
};

type SmsReminderPartPropTypes = {
  locale: Locale;
  orderId: string;
  siteId: string;
};

export default SmsReminderPart;
