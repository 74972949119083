import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import logo_brand from "../../assets/tangerpay-brand.svg";
import Footer from "../common/Footer";
import RICIBs from "react-individual-character-input-boxes";
import { useTranslation } from "react-i18next";
import "../../i18n";

const inputStyle = {
  textTransform: "uppercase",
  border: "2px solid gray",
  // font: "fixed",
  // fontSize: "1.5rem",
  width: "32px",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: "2px",
  paddingRight: "2px"
};

const HomePage = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");

  const handleOutput = (text: string) => {
    setCode(text);
  };

  const handlePayButtonClick = () => {
    // TODO this should be validated first, before redirecting
    window.location.href = `https://tap.tangerpay.com/${code}?r=code`;
  };

  return (
    <Box display={"flex"} flexDirection={"column"} className={"gradient-background"} height={"100vh"}>
      <Box p={2} mt={6}>
        <Box mt={8} width={"100%"} textAlign={"center"}>
          <img src={logo_brand} alt="Tangerpay" height="52px" />
        </Box>
        <Typography variant={"h4"} color={"white"} fontFamily={"Quicksand"} sx={{ my: 4, textAlign: "center" }}>{t("Begin-Message")}</Typography>
      </Box>
      <Box display={"flex"} p={2} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
        <Typography variant={"h6"} color={"white"} sx={{ my: 4, textAlign: "center" }}>
          {t("Enter-Machine-Code")}
        </Typography>

        <Box display={"flex"} flexDirection={"row"} alignContent={"center"} mt={4}>
          <RICIBs amount={6} autoFocus={true} handleOutputString={handleOutput} inputRegExp={/^[0-9A-Za-z]$/} inputProps={[
            { style: inputStyle },
            { style: inputStyle },
            { style: { ...inputStyle, marginRight: "12px" } },
            { style: { ...inputStyle, marginLeft: "12px" } },
            { style: inputStyle },
            { style: inputStyle }
          ]} />

        </Box>
      </Box>

      <Box mx={2}>
        <Button variant={"contained"} color={"secondary"} fullWidth sx={{ color: "white" }} disabled={code.length < 6} onClick={handlePayButtonClick}>{t("Checkout-Now")}</Button>
      </Box>

      <Footer />
    </Box>

  );
};

export default HomePage;
