import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

export const SiteNameHeader = ({ backgroundImage, storeName }: SiteBrandingPropTypes) => {
  const headerBackground = {
    backgroundColor: "#185a9d",
    backgroundImage: "url(" + backgroundImage + " )",
    backgroundSize: "cover",
    backgroundPosition: "center",
    maxWidth: "100%",
    height: "120px",
    borderBottomLeftRadius: "0.75rem",
    borderBottomRightRadius: "0.75rem"
  };

  const { pathname } = useLocation();
  const showBanner = pathname.includes("zones") && !pathname.includes("machines");

  const headerSiteName = {

  };

  const fontSize = storeName?.length < 20 ? "1.5rem" : "1.3rem";

  return (
    <Box textAlign="center" display="flex" flexDirection={"column"} position="static" bgcolor={"white"}>
      <Box style={headerSiteName} bgcolor="secondary.main" color="white" fontSize={fontSize} fontFamily={"Quicksand"} p={1.5} zIndex={10}>
        {storeName ?? "Tangerpay"}
      </Box>
      <Box style={{...headerBackground, display: showBanner ? "block" : "none"}} height={"100%"} zIndex={1} />
    </Box>
  );
};

type SiteBrandingPropTypes = {
  backgroundImage: string,
  storeName: string,
};
