import React from "react";
import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { CheckCircle, DoNotDisturb, Error } from "@mui/icons-material";
import { CycleInfo, CycleStatus, Machine, MachineReadiness } from "../../../api/gen";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { TFunction } from "i18next";

export class MachineStatus {
  machine;
  status;
  cycleInfo;

  constructor(machine: Machine, status: MachineReadiness, cycleInfo?: CycleInfo) {
    this.machine = machine;
    this.status = status;
    this.cycleInfo = cycleInfo;
  }
}

const machineStatusMessage = (t: TFunction, readiness: MachineReadiness, cycleInfo?: CycleInfo) => {
  if (readiness === MachineReadiness.NotChecked) {
    return t("Checking");
  } else if (readiness === MachineReadiness.Unavailable) {
    return t("Not-Ready");
  } else if (readiness === MachineReadiness.Available && cycleInfo?.cycleStatus === CycleStatus.Running) {
    return t("Running") + cycleInfo.cycleMinutesRemaining + t("Mins-Remaining");
  } else if (readiness === MachineReadiness.Available) {
    return t("Ready");
  } else {
    return t("Error");
  }
};

const machineStatusIcon = (readiness: MachineReadiness, cycleInfo?: CycleInfo) => {
  if (readiness === MachineReadiness.NotChecked) {
    return <CircularProgress size={"1.25rem"} />;
  } else if (readiness === MachineReadiness.Unavailable || readiness === MachineReadiness.EndOfCycle) {
    return <DoNotDisturb color={"warning"} />;
  } else if (readiness === MachineReadiness.Available && cycleInfo?.cycleStatus === CycleStatus.Running) {
    return <DirectionsRunIcon color={"warning"} />;
  } else if (readiness === MachineReadiness.Available) {
    return <CheckCircle color={"success"} />;
  } else {
    return <Error color={"error"} />;
  }
};

const MachineStatusLine = ({ ms }: { ms: MachineStatus }) => {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} m={1}>
      <Box>
        <Typography variant={"body1"}>{ms.machine.name}</Typography>
        <Typography variant={"caption"} color={"text.secondary"}>
          {machineStatusMessage(t, ms.status, ms.cycleInfo)}
        </Typography>
      </Box>
      <div>{machineStatusIcon(ms.status, ms.cycleInfo)}</div>
    </Box>
  );
};

const MachineStatusList = ({ machineStatuses }: { machineStatuses: Array<MachineStatus> }) => (
  <Box display={"flex"} flexDirection={"column"} width={"100%"} my={2}>
    {[...machineStatuses]
      .sort((ms1, ms2) => ms1.machine.name.localeCompare(ms2.machine.name))
      .map((ms) => (
        <MachineStatusLine ms={ms} key={ms.machine.id} />
      ))}
  </Box>
);

const MachineStatusView = ({ machineStatuses, showDialog, onBackToSelection, message, showButtons, allowContinueButton, onContinue }: MachineStatusViewPropTypes) => {
  const { t } = useTranslation();

  return (
    <Dialog open={showDialog} fullWidth>
      <DialogTitle>{t("Checking-Machines")}</DialogTitle>
      <DialogContent>
        <Box bgcolor={"background.paper"} display={"flex"} flexDirection={"column"} justifyContent={"space-around"} alignItems={"center"} mx={1} borderRadius={2} height={"60%"}>
          <MachineStatusList machineStatuses={machineStatuses} />
        </Box>
        <Typography variant={"body2"} hidden={!message}>
          {message}
        </Typography>
      </DialogContent>
      <Collapse in={showButtons}>
        <DialogActions>
          <Button onClick={onBackToSelection}>Back</Button>
          {allowContinueButton && <Button onClick={onContinue}>Continue</Button>}
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};

type MachineStatusViewPropTypes = {
  machineStatuses: Array<MachineStatus>;
  showDialog: boolean;
  showButtons: boolean;
  message: string;
  onBackToSelection: () => void;
  onContinue: () => void;
  allowContinueButton: boolean;
};

export default MachineStatusView;
