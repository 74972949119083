/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Audience } from './Audience';
import {
    AudienceFromJSON,
    AudienceFromJSONTyped,
    AudienceToJSON,
} from './Audience';
import type { Priority } from './Priority';
import {
    PriorityFromJSON,
    PriorityFromJSONTyped,
    PriorityToJSON,
} from './Priority';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface SplashMessage
 */
export interface SplashMessage {
    /**
     * 
     * @type {number}
     * @memberof SplashMessage
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SplashMessage
     */
    siteId: string;
    /**
     * 
     * @type {string}
     * @memberof SplashMessage
     */
    message: string;
    /**
     * 
     * @type {Priority}
     * @memberof SplashMessage
     */
    priority: Priority;
    /**
     * 
     * @type {Audience}
     * @memberof SplashMessage
     */
    audience: Audience;
    /**
     * 
     * @type {Location}
     * @memberof SplashMessage
     */
    location: Location;
    /**
     * 
     * @type {boolean}
     * @memberof SplashMessage
     */
    triggerRestart?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SplashMessage
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the SplashMessage interface.
 */
export function instanceOfSplashMessage(value: object): value is SplashMessage {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('siteId' in value) || value['siteId'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('priority' in value) || value['priority'] === undefined) return false;
    if (!('audience' in value) || value['audience'] === undefined) return false;
    if (!('location' in value) || value['location'] === undefined) return false;
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    return true;
}

export function SplashMessageFromJSON(json: any): SplashMessage {
    return SplashMessageFromJSONTyped(json, false);
}

export function SplashMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SplashMessage {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'siteId': json['siteId'],
        'message': json['message'],
        'priority': PriorityFromJSON(json['priority']),
        'audience': AudienceFromJSON(json['audience']),
        'location': LocationFromJSON(json['location']),
        'triggerRestart': json['triggerRestart'] == null ? undefined : json['triggerRestart'],
        'enabled': json['enabled'],
    };
}

export function SplashMessageToJSON(value?: SplashMessage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'siteId': value['siteId'],
        'message': value['message'],
        'priority': PriorityToJSON(value['priority']),
        'audience': AudienceToJSON(value['audience']),
        'location': LocationToJSON(value['location']),
        'triggerRestart': value['triggerRestart'],
        'enabled': value['enabled'],
    };
}

