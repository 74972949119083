/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VendAttemptResult = {
    Pending: 'Pending',
    Success: 'Success',
    Fail: 'Fail',
    NotAttempted: 'NotAttempted'
} as const;
export type VendAttemptResult = typeof VendAttemptResult[keyof typeof VendAttemptResult];


export function instanceOfVendAttemptResult(value: any): boolean {
    for (const key in VendAttemptResult) {
        if (Object.prototype.hasOwnProperty.call(VendAttemptResult, key)) {
            if ((VendAttemptResult as Record<string, VendAttemptResult>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function VendAttemptResultFromJSON(json: any): VendAttemptResult {
    return VendAttemptResultFromJSONTyped(json, false);
}

export function VendAttemptResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendAttemptResult {
    return json as VendAttemptResult;
}

export function VendAttemptResultToJSON(value?: VendAttemptResult | null): any {
    return value as any;
}

