import { Box, Collapse, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import { getMessages } from "../../../redux/actions/splashMessageActions";
import sanitize from "sanitize-html";
import { AUDIENCE_ALL, AUDIENCE_WEB, PAYMENT_CONFIRMATION_SCREEN } from "../../common/Constants";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const StartMachineInstructionsPart = () => {
  const dispatch = useDispatch();
  const { siteConfig } = useAppSelector((s) => s.siteConfig);
  const splashMessageList = useAppSelector((state) => state.splashMessage);
  const { t } = useTranslation();

  useEffect(() => {
    if (siteConfig?.siteId) {
      dispatch(getMessages(siteConfig.siteId));
    }
  }, [siteConfig, dispatch]);

  const splashMessage = splashMessageList?.messages?.find(
    (message) => message.enabled && (message.audience === AUDIENCE_ALL || message.audience === AUDIENCE_WEB) && message.location === PAYMENT_CONFIRMATION_SCREEN
  );
  const hasInstructions = splashMessage?.id && splashMessage?.id !== 0;

  return (
    <Box display={hasInstructions ? "flex" : "none"} flexDirection="column" mx={2} mt={3}>
      <Paper elevation={1}>
        <Box p={2}>
          <Typography variant={"h6"}>{t("Start-Machine-Instructions")}</Typography>

          <Divider variant={"fullWidth"} />
          <Collapse in={true}>
            <Box display={"flex"} flexDirection={"column"} sx={{ "& .MuiBox-root": { py: 0.25 } }}>
              <Typography variant={"caption"} component={"div"}>
                <div dangerouslySetInnerHTML={{ __html: sanitize(splashMessage?.message || "") }} />
              </Typography>
            </Box>
          </Collapse>
        </Box>
      </Paper>
    </Box>
  );
};

export default StartMachineInstructionsPart;
