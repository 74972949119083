import TillUnmanagedTextBox from "./TillUnmanagedTextBox";
import React, { FocusEvent, FormEvent, useEffect, useRef, useState } from "react";
import Payment from "payment";
import { parseExpiryDate, ValidatedCardExpiry } from "./creditCardExpiry";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";

type TillCardExpiryTextBoxPropTypes = {
  expiry: string;
  onChange: (event: ValidatedCardExpiry) => void;
};

const TillCardExpiryTextBox = ({ expiry, onChange }: TillCardExpiryTextBoxPropTypes) => {
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const cardExpiryRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (expiry && !Payment.fns.validateCardExpiry(expiry)) {
      setError(t("Card-Expiry-Invalid"));
    }
  }, [expiry, t]);

  useEffect(() => {
    if (cardExpiryRef.current) {
      Payment.formatCardExpiry(cardExpiryRef.current);
    }
  }, []);

  const handleInput = (evt: FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setError("");
    const result = parseExpiryDate(evt.currentTarget.value);
    onChange(result);
  };

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.currentTarget.value.length === 0) {
      setError(t("Card-Expiry-Required"));
    } else {
      setError("");
    }
  };

  return (
    <TillUnmanagedTextBox
      inputMode={"numeric"}
      label={t("Expiry")}
      autoComplete={"cc-exp"}
      placeholder={t("MM-YY")}
      value={expiry}
      onInput={handleInput}
      errorText={error}
      innerRef={cardExpiryRef}
      onBlur={handleBlur}
    />
  );
};

export default TillCardExpiryTextBox;
