import React from "react";
import { CreditCardRounded } from "@mui/icons-material";
import { ReactComponent as VisaIcon } from "./Visa-Logo.wine.svg";
import { ReactComponent as MastercardIcon } from "./Mastercard-Logo.wine.svg";
import { ReactComponent as AmexIcon } from "./American_Express-Logo.wine.svg";
import { CardScheme } from "../../../../api/gen";

const cardIcons = new Map([
  [
    "mastercard", <MastercardIcon width={32} display={"flex"} />
  ],
  [
    "visa", <VisaIcon width={32} display={"flex"} />
  ],
  [
    "amex", <AmexIcon width={32} display={"flex"} />
  ]
]);

type CardIconSelectorPropTypes = {
  cardType: string | CardScheme | undefined;
};

const CardIconSelector = ({ cardType }: CardIconSelectorPropTypes) => {
  if (cardType && cardIcons.has(cardType.toLowerCase())) {
    return cardIcons.get(cardType.toLowerCase())!;
  }

  return <CreditCardRounded />;
};

export default CardIconSelector;
