import { TextField } from "@mui/material";
import React, { useState } from "react";
import validator from "validator";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";

type EmailTextBoxPropTypes = {
  email: string;
  onChange: (event: string) => void;
};

const EmailTextBox = ({ email, onChange }: EmailTextBoxPropTypes) => {
  const [error, setError] = useState("");
  const { t } = useTranslation();

  function handleChangeEvent(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setError("");
    onChange(evt.target.value);
  }

  return (
    <TextField
      label={t("Email-Address")}
      type={"email"}
      margin="normal"
      variant="outlined"
      required
      fullWidth
      size={"small"}
      helperText={error}
      error={!!error}
      autoComplete="email"
      onBlur={() => {
        if (!validator.isEmail(email)) {
          setError(t("Email-Address-Error-2"));
        }
      }}
      onChange={(evt) => handleChangeEvent(evt)}
      value={email}
    />
  );
};

export default EmailTextBox;
