import { MachineTypeDescriptions } from "../models";
import { Machine, MachineType } from "../api/gen";

const machineTypes = new Map<MachineType, MachineTypeDescriptions>();

machineTypes.set(MachineType.Washer, { type: MachineType.Washer, noun: "Washer", verb: "Wash", pluralisedNoun: "Washers", priority: 1 });
machineTypes.set(MachineType.Dryer, { type: MachineType.Dryer, noun: "Dryer", verb: "Dry", pluralisedNoun: "Dryers", priority: 2 });
machineTypes.set(MachineType.ServicePay, { type: MachineType.ServicePay, noun: "Services", verb: "Services", pluralisedNoun: "Services", priority: 3 });
machineTypes.set(MachineType.TokenMachine, { type: MachineType.TokenMachine, noun: "Token", verb: "Token", pluralisedNoun: "Tokens", priority: 4 });
machineTypes.set(MachineType.SoapDispenser, { type: MachineType.SoapDispenser, noun: "Soap", verb: "Soap", pluralisedNoun: "Soap", priority: 5 });
machineTypes.set(MachineType.MassageChair, { type: MachineType.MassageChair, noun: "Massage-Chair", verb: "Massage-Chair", pluralisedNoun: "Massage-Chairs", priority: 6 });
machineTypes.set(MachineType.Shower, { type: MachineType.Shower, noun: "Shower", verb: "Shower", pluralisedNoun: "Showers", priority: 7 });
machineTypes.set(MachineType.ArcadeMachine, { type: MachineType.ArcadeMachine, noun: "Arcade-Machine", verb: "Arcade-Machine", pluralisedNoun: "Arcade-Machines", priority: 8 });
machineTypes.set(MachineType.GameMachine, { type: MachineType.GameMachine, noun: "Game-Machine", verb: "Game-Machine", pluralisedNoun: "Game-Machines", priority: 9 });
machineTypes.set(MachineType.MilkDispenser, { type: MachineType.MilkDispenser, noun: "Milk-Dispenser", verb: "Milk-Dispenser", pluralisedNoun: "Milk-Dispensers", priority: 10 });
machineTypes.set(MachineType.DogWash, { type: MachineType.DogWash, noun: "Dog Washer", verb: "Dog Wash", pluralisedNoun: "Dog Washers", priority: 11 });
const defaultDescription = { noun: "Other", verb: "Something-Else", pluralisedNoun: "Others", priority: 10 } as MachineTypeDescriptions;

const extractMachineTypes = (machines: Array<Machine>) => {
  const machineTypeSet = new Set<MachineTypeDescriptions>();
  machines.forEach((m) => {
    machineTypeSet.add(machineTypes.get(m.type) || defaultDescription);
  });
  return [...Array.from(machineTypeSet.values())].sort((mt1, mt2) => mt1.priority - mt2.priority);
};

export default extractMachineTypes;
