import { ChevronRight } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

const ChevronButton = ({ onClick, label, secondaryLabel, icon = <ChevronRight />, labelStyle }: ChevronButtonPropTypes) => {
  const shouldReduceTextSize = label && typeof label === "string" && label.length > 10;

  return (
    <Box onClick={onClick} display="flex" justifyContent="space-between" alignItems="center">
      {typeof label === "string" && (
        <Typography variant={shouldReduceTextSize ? "body2" : "body1"} component="span">
          {label}
        </Typography>
      )}
      {typeof label !== "string" && label}
      {secondaryLabel && (
        <Box
          display="flex"
          flexGrow={shouldReduceTextSize ? 0 : 2}
          justifyContent={shouldReduceTextSize ? "flex-end" : "flex-start"}
          alignItems="center"
          marginLeft={shouldReduceTextSize ? "-80px" : "16px"}
        >
          {typeof secondaryLabel === "string" && (
            <Typography variant="body2" component="span">
              {secondaryLabel}
            </Typography>
          )}
          {typeof secondaryLabel !== "string" && secondaryLabel}
        </Box>
      )}
      {icon}
    </Box>
  );
};

type ChevronButtonPropTypes = {
  onClick: () => void;
  label: string | JSX.Element;
  labelStyle?: React.CSSProperties;
  secondaryLabel?: string | JSX.Element;
  icon?: JSX.Element;
};

export default ChevronButton;
