import React from "react";
import { MachineOption, MachineOptionGroup, MachineOptionsMode } from "../../api/gen";
import MachineOptionsSelector from "./MachineOptionsSelector";
import { useTranslation } from "react-i18next";

const determineSuffix = (mode: MachineOptionsMode, selectionRequired: boolean) => {

  if (mode === MachineOptionsMode.One && !selectionRequired) {
    return "May-Select-One";
  }
  if (mode === MachineOptionsMode.One && selectionRequired) {
    return "Must-Select-One";
  }
  if (mode === MachineOptionsMode.Multiple && !selectionRequired) {
    return "May-Select-Many";
  }
  if (mode === MachineOptionsMode.Multiple && selectionRequired) {
    return "Must-Select-At-Least-One";
  }
  return "May-Select-One";
};

const MachineOptionsGroup: React.FC<{
  machineOptionGroups: Array<MachineOptionGroup>,
  selectedOptions: Array<MachineOption>,
  onOptionClicked: (option: MachineOption) => void
}> = (props) => {
  const { t } = useTranslation();

  const determineGroupName = (group: MachineOptionGroup) => {
    const suffix = determineSuffix(group.machineOptionsMode, group.machineOptionSelectionRequired);
    const suffixText = t(suffix);

    if (suffixText) {
      return (group.name || t("Options")) + " (" + suffixText + ")";
    }

    return (group.name || t("Options"));
  };


  return (<>
    {props.machineOptionGroups.map((group) => (
      <MachineOptionsSelector availableOptions={group.machineOptions}
                              selectedOptions={props.selectedOptions}
                              mode={group.machineOptionsMode}
                              onOptionClicked={props.onOptionClicked}
                              name={determineGroupName(group)} />
    ))}
  </>);

};

export default MachineOptionsGroup;