/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PromotionCodeResolutionResult } from './PromotionCodeResolutionResult';
import {
    PromotionCodeResolutionResultFromJSON,
    PromotionCodeResolutionResultFromJSONTyped,
    PromotionCodeResolutionResultToJSON,
} from './PromotionCodeResolutionResult';
import type { PromotionType } from './PromotionType';
import {
    PromotionTypeFromJSON,
    PromotionTypeFromJSONTyped,
    PromotionTypeToJSON,
} from './PromotionType';

/**
 * 
 * @export
 * @interface PromotionModel
 */
export interface PromotionModel {
    /**
     * 
     * @type {string}
     * @memberof PromotionModel
     */
    name: string;
    /**
     * 
     * @type {PromotionCodeResolutionResult}
     * @memberof PromotionModel
     */
    promotionCodeResult: PromotionCodeResolutionResult;
    /**
     * 
     * @type {PromotionType}
     * @memberof PromotionModel
     */
    type: PromotionType;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionModel
     */
    canRemember: boolean;
}

/**
 * Check if a given object implements the PromotionModel interface.
 */
export function instanceOfPromotionModel(value: object): value is PromotionModel {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('promotionCodeResult' in value) || value['promotionCodeResult'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('canRemember' in value) || value['canRemember'] === undefined) return false;
    return true;
}

export function PromotionModelFromJSON(json: any): PromotionModel {
    return PromotionModelFromJSONTyped(json, false);
}

export function PromotionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromotionModel {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'promotionCodeResult': PromotionCodeResolutionResultFromJSON(json['promotionCodeResult']),
        'type': PromotionTypeFromJSON(json['type']),
        'canRemember': json['canRemember'],
    };
}

export function PromotionModelToJSON(value?: PromotionModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'promotionCodeResult': PromotionCodeResolutionResultToJSON(value['promotionCodeResult']),
        'type': PromotionTypeToJSON(value['type']),
        'canRemember': value['canRemember'],
    };
}

