/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentStatus = {
    New: 'New',
    Declined: 'Declined',
    Fraud: 'Fraud',
    Authorised: 'Authorised',
    Captured: 'Captured',
    Voided: 'Voided',
    Error: 'Error',
    NotRequired: 'NotRequired',
    AuthenticationRequired: 'AuthenticationRequired'
} as const;
export type PaymentStatus = typeof PaymentStatus[keyof typeof PaymentStatus];


export function instanceOfPaymentStatus(value: any): boolean {
    for (const key in PaymentStatus) {
        if (Object.prototype.hasOwnProperty.call(PaymentStatus, key)) {
            if ((PaymentStatus as Record<string, PaymentStatus>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PaymentStatusFromJSON(json: any): PaymentStatus {
    return PaymentStatusFromJSONTyped(json, false);
}

export function PaymentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatus {
    return json as PaymentStatus;
}

export function PaymentStatusToJSON(value?: PaymentStatus | null): any {
    return value as any;
}

