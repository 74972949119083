/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AmountsModel } from './AmountsModel';
import {
    AmountsModelFromJSON,
    AmountsModelFromJSONTyped,
    AmountsModelToJSON,
} from './AmountsModel';
import type { MachineCreditResponse } from './MachineCreditResponse';
import {
    MachineCreditResponseFromJSON,
    MachineCreditResponseFromJSONTyped,
    MachineCreditResponseToJSON,
} from './MachineCreditResponse';
import type { PromotionModel } from './PromotionModel';
import {
    PromotionModelFromJSON,
    PromotionModelFromJSONTyped,
    PromotionModelToJSON,
} from './PromotionModel';
import type { LoyaltyModel } from './LoyaltyModel';
import {
    LoyaltyModelFromJSON,
    LoyaltyModelFromJSONTyped,
    LoyaltyModelToJSON,
} from './LoyaltyModel';

/**
 * 
 * @export
 * @interface QuoteResponseModel
 */
export interface QuoteResponseModel {
    /**
     * 
     * @type {AmountsModel}
     * @memberof QuoteResponseModel
     */
    amounts: AmountsModel;
    /**
     * 
     * @type {PromotionModel}
     * @memberof QuoteResponseModel
     */
    promotion: PromotionModel;
    /**
     * 
     * @type {LoyaltyModel}
     * @memberof QuoteResponseModel
     */
    loyalty?: LoyaltyModel;
    /**
     * 
     * @type {Array<MachineCreditResponse>}
     * @memberof QuoteResponseModel
     */
    machineCredits: Array<MachineCreditResponse>;
    /**
     * 
     * @type {string}
     * @memberof QuoteResponseModel
     */
    userProfileId: string;
}

/**
 * Check if a given object implements the QuoteResponseModel interface.
 */
export function instanceOfQuoteResponseModel(value: object): value is QuoteResponseModel {
    if (!('amounts' in value) || value['amounts'] === undefined) return false;
    if (!('promotion' in value) || value['promotion'] === undefined) return false;
    if (!('machineCredits' in value) || value['machineCredits'] === undefined) return false;
    if (!('userProfileId' in value) || value['userProfileId'] === undefined) return false;
    return true;
}

export function QuoteResponseModelFromJSON(json: any): QuoteResponseModel {
    return QuoteResponseModelFromJSONTyped(json, false);
}

export function QuoteResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteResponseModel {
    if (json == null) {
        return json;
    }
    return {
        
        'amounts': AmountsModelFromJSON(json['amounts']),
        'promotion': PromotionModelFromJSON(json['promotion']),
        'loyalty': json['loyalty'] == null ? undefined : LoyaltyModelFromJSON(json['loyalty']),
        'machineCredits': ((json['machineCredits'] as Array<any>).map(MachineCreditResponseFromJSON)),
        'userProfileId': json['userProfileId'],
    };
}

export function QuoteResponseModelToJSON(value?: QuoteResponseModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amounts': AmountsModelToJSON(value['amounts']),
        'promotion': PromotionModelToJSON(value['promotion']),
        'loyalty': LoyaltyModelToJSON(value['loyalty']),
        'machineCredits': ((value['machineCredits'] as Array<any>).map(MachineCreditResponseToJSON)),
        'userProfileId': value['userProfileId'],
    };
}

