import { Typography, useTheme } from "@mui/material";
import React from "react";
import logo from "../../assets/tangerpay-brand.svg";
import { useTranslation } from "react-i18next";
import "../../i18n";

const Footer = ({ showTrialMode }: FooterPropTypes) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <footer
      className={"App-footer"}
      style={{
        backgroundColor: showTrialMode ? theme.palette.error.main : theme.palette.common.white,
        borderColor: showTrialMode ? theme.palette.error.dark : theme.palette.secondary.main,
        borderTop: "1px solid",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
      }}
    >
      {showTrialMode && (
        <Typography variant={"subtitle1"} color={"white"}>
          {t("Trial-Mode")}
        </Typography>
      )}
      {!showTrialMode && <img src={logo} alt="Logo" height="20" />}
    </footer>
  );
};

type FooterPropTypes = {
  showTrialMode?: boolean;
};

export default Footer;
