import { CreditCard, KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Button, Fab, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addMachineToCart, addOptionToMachine, deleteMachineCredits, deleteMachineFromCart, deleteOptionFromMachine, updateMachinePrice } from "../../redux/actions/cartActions";
import Filters, { Filter } from "./Filters";
import MachineCard from "./MachineCard";
import "../../App.css";
import { Machine, MachineOption, MachineType } from "../../api/gen";
import { useLocale, useSiteConfig } from "../../hooks";
import { useAppSelector } from "../../redux/hooks";
import { CartUrlParamsSerialiser } from "../../utils/cartUrlParamsSerialiser";
import { deleteOrder } from "../../redux/actions/orderActions";
import { deleteQuote } from "../../redux/actions/quoteActions";
import extractMachineTypes from "../../utils/machineTypeExtractor";
import { loadSiteConfig } from "../../redux/actions/siteConfigActions";
import { useTranslation } from "react-i18next";
import "../../i18n";

const getCurrentFilterValue = () => {
  const filter = new URLSearchParams(window.location.search).get("t");

  if (filter) {
    return MachineType[filter as keyof typeof MachineType];
  }

  return undefined;
};

const getMachineToHighlight = () => {
  return new URLSearchParams(window.location.search).get("m");
};

const isAwaitingOptionSelection = (machine: Machine, selectedOptions: Array<MachineOption>) => {
  // make sure all required option groups have at least one selection
  return !machine.machineOptionGroups
    .filter(mog => mog.machineOptionSelectionRequired)
    .every(mog => selectedOptions.some(so => mog.machineOptions.includes(so)));
};


const MachineSelectionPage = () => {
  const { zoneId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const locale = useLocale();
  const dispatch = useDispatch();
  const cart = useAppSelector((store) => store.cart);
  const order = useAppSelector((store) => store.order);
  const { siteConfig } = useSiteConfig();
  const currentFilter = getCurrentFilterValue();
  const { t } = useTranslation();

  // if the state has an order, and we're here, then it can only mean the back button has been pressed to this point.
  // If that's the case, clear out everything as if the "start again" button was pressed!
  useEffect(() => {
    if (!!order?.order) {
      const siteId = siteConfig?.siteId ?? "";
      dispatch(deleteMachineCredits());
      dispatch(deleteOrder());
      dispatch(deleteQuote());
      dispatch(loadSiteConfig(siteId)); // reload the site config in case machine status has changed.
      navigate(`/${siteId}`);
    }
  }, [dispatch, navigate, order, siteConfig]);

  const handleFilterChange = (machineType: MachineType) => {
    const typeParams = new URLSearchParams(window.location.search);
    typeParams.set("t", machineType);

    navigate(window.location.pathname + "?" + typeParams.toString(), { replace: true });
  };

  const handleMachineSelection = (machine: Machine, selected: boolean, amount: number) => {
    if (selected && cart.machineCredits.length < (siteConfig?.maxNumberOfSelectableMachines ?? 0)) {
      console.log(`Add machine ${machine.name} to cart with amount ${amount}`);
      dispatch(addMachineToCart({ machine, amount, selectedOptions: machine.machineOptionGroups.flatMap(mog => mog.machineOptions).filter(m => m.isDefault) }));
    } else {
      console.log(`Remove machine ${machine.name} from cart`);
      dispatch(deleteMachineFromCart(machine.id));
    }
  };

  const handleAmountChange = (machine: Machine, amount: number) => {
    console.log(`Amount changed for machine ${machine.name} to ${amount}`);
    dispatch(updateMachinePrice({ machine, amount }));
  };

  const handleOptionClicked = (machine: Machine, option: MachineOption) => {
    if (cart.machineCredits.find((mc) => mc.machine.id === machine.id)?.selectedOptions.some((o) => o === option)) {
      dispatch(deleteOptionFromMachine({ machine, option }));
    } else {
      dispatch(addOptionToMachine({ machine, option }));
    }
  };

  const onBackToStartClicked = () => {
    dispatch(deleteMachineCredits());
    navigate(`/${siteConfig?.siteId}`);
  };

  const handleCheckoutClicked = () => {
    const params = CartUrlParamsSerialiser.toUrlParams(cart);

    navigate(`/${siteConfig?.siteId}/checkout?${params.toString()}`);
  };

  const filters = siteConfig?.showCategorySelector
    ? extractMachineTypes(siteConfig?.machines.filter((m) => m.zoneId === zoneId) ?? []).map(
      (m) =>
        ({
          id: m.type,
          label: m.noun
        } as Filter)
    )
    : [];

  const filteredMachines = siteConfig?.machines.filter((m) => m.zoneId === zoneId).filter((m) => (filters && filters.length < 2) || currentFilter === m.type) ?? [];

  const fabStyle = {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(2),
    zIndex: "2",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  };

  const machineToHighlight = getMachineToHighlight();

  return (
    <>
      {filters?.length > 1 && <Filters filters={filters} selectedId={currentFilter} onSelect={(filter) => handleFilterChange(filter.id)} />}
      <Box py={1} pt={filters?.length > 1 ? undefined : 3} mt={filters?.length > 1 ? undefined : -2} textAlign={"center"} bgcolor={"inherit"}>
        <Typography variant={"body2"} color={"text.secondary"}>
          {cart.machineCredits.length === 0 && (
            <>
              {t("Selection-Message")} {siteConfig?.maxNumberOfSelectableMachines} {t("Machines")}
            </>
          )}
          {cart.machineCredits.length > 0 && <>{t("Machines-Selected", { count: cart.machineCredits.length, max: siteConfig?.maxNumberOfSelectableMachines })}</>}
        </Typography>
      </Box>
      <Box p={4} component="div" flexGrow={1} className={"gradient-background"}>
        {filteredMachines.map((m) => {
          const cartItem = cart.machineCredits.find((mc) => mc.machine.id === m.id);
          return (
            <Box mb={2} key={m.id}>
              <MachineCard
                machine={m}
                locale={locale}
                vendAmount={cartItem?.amount || m.defaultVendAmount}
                onSelectMachine={(amount, selected) => {
                  handleMachineSelection(m, selected, amount);
                }}
                onVendAmountChange={(amount) => handleAmountChange(m, amount)}
                onMachineOptionClicked={(option) => handleOptionClicked(m, option)}
                selected={!!cartItem}
                scrollTo={m.id === machineToHighlight}
                selectedOptions={cartItem?.selectedOptions || m.machineOptionGroups.flatMap(mog => mog.machineOptions).filter(m => m.isDefault)}
                isValid={isAwaitingOptionSelection(m, cartItem?.selectedOptions || [])}
              />
            </Box>
          );
        })}

        {siteConfig?.showZoneSelector && (
          <Box mt={4}>
            <Button variant={"text"} sx={{ color: "white" }} color={"primary"} size={"small"} fullWidth onClick={onBackToStartClicked} startIcon={<KeyboardArrowLeft />}>
              {t("Start-Again")}
            </Button>
          </Box>
        )}

        {/*Empty space to allow user to scroll right up and not have the FAB in the way*/}
        <Box p={8} />
        <Fab variant="extended" size={"large"}
             disabled={cart.machineCredits.length === 0 || cart.machineCredits.some(mc => isAwaitingOptionSelection(mc.machine, mc.selectedOptions))}
             sx={fabStyle}
             onClick={handleCheckoutClicked}>
          <CreditCard style={{ paddingRight: "0.5rem" }} />
          {t("Checkout")}
        </Fab>
      </Box>
    </>
  );
};

export default MachineSelectionPage;
