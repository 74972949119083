import { TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import Payment from "payment";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";

type ExpiryDateTextBoxPropTypes = {
  expiry: string;
  onChange: (expiry: string) => void;
};

// payment API https://github.com/jessepollak/payment#readme
const CardExpiryDateTextBox = ({ expiry, onChange }: ExpiryDateTextBoxPropTypes) => {
  const cardExpiryRef = useRef<HTMLInputElement>(null);
  const expMonthRef = useRef<HTMLInputElement>(null);
  const expYearRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  function handleChangeEvent(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setError("");
    if (cardExpiryRef.current && expMonthRef.current && expYearRef.current) {
      Payment.formatCardExpiry(cardExpiryRef.current);

      const expiry = cardExpiryRef.current.value;
      if (Payment.fns.validateCardExpiry(expiry)) {
        const monthYear = Payment.fns.cardExpiryVal(expiry);
        expMonthRef.current.value = monthYear.month.toString();
        expYearRef.current.value = monthYear.year.toString();
      }
      onChange(expiry);
    }
  }

  // This could be reusable in the future with other gateways that use a similar integration style as Datacap.
  return (
    <>
      <TextField
        inputRef={cardExpiryRef}
        label={t("Expiry")}
        placeholder={t("MM-YY")}
        variant="outlined"
        size={"small"}
        onChange={(evt) => handleChangeEvent(evt)}
        value={expiry}
        required
        autoComplete="cc-exp"
        onBlur={() => {
          if (!Payment.fns.validateCardExpiry(expiry)) {
            setError(t("Expiry-Invalid"));
          }
        }}
        error={!!error}
        helperText={error}
        margin="normal"
      />
      <input type={"hidden"} data-token={"exp_month"} ref={expMonthRef} />
      <input type={"hidden"} data-token={"exp_year"} ref={expYearRef} />
    </>
  );
};

export default CardExpiryDateTextBox;
