import { Configuration } from "./gen";

export async function handleResponse(response) {
  if (response.status === 204) return;
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new BadRequest("HTTP 400 - " + error, response);
  }
  if (response.status === 404) {
    throw new NotFound("HTTP 404");
  }
  if (response.status >= 500) {
    throw new ServerError("HTTP " + response.status);
  }
  if (response.status === 422) {
    const json = await response.json();
    throw new UnprocessableEntity("HTTP 422", json);
  }
  throw new Error("Network response was not ok.");
}

export function handleError(error) {
  console.warn("API call failed", error);
  throw error;
}

export function logResponse(o) {
  console.info("Response from API", o);
  return o;
}

export class UnprocessableEntity extends Error {
  response;

  constructor(error, response) {
    super(error);
    this.response = response;
  }
}

export class BadRequest extends Error {
  response;

  constructor(error, response) {
    super(error);
    this.response = response;
  }
}

export class NotFound extends Error {
  response;

  constructor(error, response) {
    super(error);
    this.response = response;
  }
}

export class ServerError extends Error {
  response;

  constructor(error, response) {
    super(error);
    this.response = response;
  }
}

export const defaultConfiguration = new Configuration({
  basePath: "",
  middleware: [
    {
      pre(context) {
        if (process.env.NODE_ENV === "development") {
          // don't bother in production
          console.info(`API request to ${context.init.method} ${context.url}`);
        }
      },

      post: async (context) => {
        if (process.env.NODE_ENV === "development") {
          // don't bother in production
          if (context.response.headers.get("content-type")?.indexOf("json") > 0) {
            const response = await context.response.json();
            console.info(`${context.url} API response`, response);
          } else {
            const response = await context.response.text();
            console.info(`${context.url} API response`, response);
          }
        }
      },
    },
  ],
});
