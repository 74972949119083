import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import MachineTypeCard from "./MachineTypeCard";
import SplashMessageWithTitle from "../common/splashMessage/SplashMessageWithTitle";
import { useAppSelector } from "../../redux/hooks";
import { getMessages } from "../../redux/actions/splashMessageActions";
import extractMachineTypes from "../../utils/machineTypeExtractor";
import { useSiteConfig } from "../../hooks";
import { useTranslation } from "react-i18next";
import { PRE_HOME_SCREEN, AUDIENCE_ALL, AUDIENCE_WEB, HIGH_PRIORITY } from "../common/Constants";
import "../../App.css";
import "../../i18n";

const MachineTypeSelectPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { zoneId } = useParams();
  const { siteConfig } = useSiteConfig();
  const machinePath = `/${siteConfig?.siteId ?? ""}/zones/${zoneId}/machines?t=`;
  const machineTypes = extractMachineTypes(siteConfig?.machines.filter((m) => m.zoneId === zoneId) ?? []);
  const splashMessage = useAppSelector((state) => state.splashMessage);
  const [showDialog, setShowDialog] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (siteConfig?.siteId) {
      dispatch(getMessages(siteConfig.siteId));
    }
  }, [siteConfig, dispatch]);

  const onClose = () => {
    setShowDialog(false);
  };

  const preHomeScreenMessage = splashMessage?.messages?.find((message) => message.location === PRE_HOME_SCREEN);
  const showPreHomeScreenMessage = preHomeScreenMessage?.enabled && (preHomeScreenMessage.audience === AUDIENCE_ALL || preHomeScreenMessage.audience === AUDIENCE_WEB);
  const splashMessageText = preHomeScreenMessage?.message || "";
  const isHighPriority = preHomeScreenMessage?.priority === HIGH_PRIORITY;

  return (
    <>
      {showPreHomeScreenMessage && (
        <SplashMessageWithTitle open={showDialog} title={t("Important-Information")} message={splashMessageText} hideOkButton={isHighPriority} onClose={onClose} />
      )}
      <Box component="div" flexGrow={1} mt={-2} className={"gradient-background"}>
        <Box pt={8} textAlign={"center"}>
          <Typography variant="h5" gutterBottom color="common.white">
            {t("Welcome-Message")}
          </Typography>
        </Box>
        <Box px={4} py={2}>
          {machineTypes.map((mt) => {
            return (
              <Box mt={4} key={mt.verb}>
                <MachineTypeCard
                  name={mt.verb}
                  machineType={mt.type}
                  onClick={() => {
                    navigate(machinePath + mt.type);
                  }}
                />
              </Box>
            );
          })}
        </Box>
        {/*Empty space to allow scroll up*/}
        <Box p={8} />
      </Box>
    </>
  );
};

export default MachineTypeSelectPage;
